import React from "react";
import TickIcon from "../../Common/Svgs/TickIcon";
import { PAYMENT_STATUS } from "./data";

const payment = [
  {
    id: PAYMENT_STATUS.COMPLETED,
    value: "Paid",
  },
  {
    id: PAYMENT_STATUS.PENDING,
    value: "Due",
  },
];

const collection = [
  {
    id: PAYMENT_STATUS.COMPLETED,
    value: "Collected",
  },
  {
    id: PAYMENT_STATUS.PENDING,
    value: "Due",
  },
];

const UpdateStatus = ({ title, current, onClick }) => {
  const options = title.toLowerCase().includes("payment")
    ? payment
    : collection;

  return (
    <div
      className="bg-background-white rounded-md px-3 py-1 w-[180px]"
      style={{
        boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
      }}
    >
      <p className="font-poppins text-xs font-medium capitalize py-2">
        {title}
      </p>

      {options.map((item) => {
        const selected = current === item.id;

        return (
          <div
            key={item.value}
            className="flex items-center justify-between py-2 cursor-pointer group"
            onClick={() => onClick(item.id)}
          >
            <p
              className={`font-aileron text-xs font-semibold group-hover:text-black ${
                selected ? "text-black" : "text-dark-gray"
              }`}
            >
              {item.value}
            </p>

            {selected && <TickIcon size={16} className="text-primary" />}
          </div>
        );
      })}
    </div>
  );
};

export default UpdateStatus;
