import React from "react";

const BatteryInTransitIcon = ({ size = 16 }) => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="#F18725"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M11.52 6.967 14 4.487l-2.48-2.48M2 4.487h12M4.48 9.033 2 11.513l2.48 2.48M14 11.513H2"
        />
      </svg>
    </span>
  );
};

export default BatteryInTransitIcon;
