/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import Label from "../Label";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import { getSystemUsers } from "../../../Redux/APIs/systemUsersAPI";
import BlockedEyeIcon from "../../../Common/Svgs/BlockedEyeIcon";
import {
  validationForEmail,
  validationForPassword,
} from "./Data/generateUserData";
import { AssignHubWhileCreation } from "./AssignHubStationDropdown";
import { client } from "../../../Utils/axiosClient";
import { SEED_ROLES } from "../../Login/constants";

const GenerateUser = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedUser, systemRoles } = useSelector(
    (state) => state.loggedInUser,
  );

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      firstName: id ? selectedUser?.firstName : "",
      lastName: id ? selectedUser?.lastName : "",
      email: id ? selectedUser?.email : "",
      password: "",
      roleId: id ? selectedUser?.roleId : 0,
      roleName: id ? selectedUser?.roleName : "",
      selectedHubStations: [],
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [systemUserRoles, setSystemUserRoles] = useState([]);

  const [focus, setFocus] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    role: false,
    hub: false,
  });

  const roleError = formState?.errors?.roleName?.message;
  const hubStationError = formState?.errors?.selectedHubStations?.message;

  const modifySystemRoles = useCallback(async () => {
    const modified = systemRoles
      ?.filter((item) => +item.id !== SEED_ROLES.DRIVER)
      ?.map((r) => ({
        id: r.id,
        value: r.name,
      }));

    setSystemUserRoles([...modified]);
  }, [systemRoles]);

  const handleHubStationSelect = (data) => {
    const current = watch("selectedHubStations");

    const exists = current.find((item) => item.id === data.id);

    if (exists) {
      const updatedHubs = current.filter((item) => item.id !== data.id);
      setValue("selectedHubStations", updatedHubs);
    } else {
      setValue("selectedHubStations", [...current, data]);
    }
  };

  const onSubmit = async (e) => {
    const hubs = e.selectedHubStations.map((item) => parseInt(item.id, 10));

    const payload = {
      firstName: e.firstName.trim(),
      lastName: e.lastName.trim(),
      email: e.email.trim(),
      password: e.password.trim(),
      roleId: e.roleId,
      hubs,
    };

    try {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: true,
        }),
      );

      await client.post(
        `${process.env.REACT_APP_API_URL}/auth/create-user`,
        payload,
      );

      dispatch(getSystemUsers());

      dispatch(
        updateLoggedInUser({
          key: "toastMessageModal",
          value: {
            visible: true,
            title: "New User Created",
            description: "1 new user credential was created successfully.",
          },
        }),
      );

      dispatch(
        updateLoggedInUser({
          key: "settingsTab",
          value: "system users",
        }),
      );

      navigate("/settings/system-users");
    } catch (error) {
      setError(error.response.data.type, {
        type: "server",
        message: error.response.data.message,
      });
    } finally {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: false,
        }),
      );
    }
  };

  const onCancel = () => {
    dispatch(updateLoggedInUser({ key: "settingsTab", value: "system users" }));
    navigate("/settings/system-users");
  };

  useEffect(() => {
    modifySystemRoles();
  }, [modifySystemRoles]);

  return (
    <CustomLayout pageName="Create User">
      <div className="w-full h-full p-6 bg-background-white">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between pb-6 border-b border-light-grey">
            <p className="text-base font-medium text-black capitalize font-poppins">
              Create a new user credential
            </p>
            <div className="flex items-center gap-x-3">
              <Button
                size="filter"
                width="generateUser"
                colors="grey"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button size="filter" width="generateUser" type="submit">
                {id ? "Save" : "Create"}
              </Button>
            </div>
          </div>

          <div className="max-h-[calc(100vh-185px)] overflow-y-scroll">
            <div className="flex flex-col mt-6 gap-y-6">
              <div>
                <Label text="First Name" />
                <CustomInputWithController
                  rules={{ required: "Please enter first name" }}
                  intent="createUser"
                  border="primary"
                  control={control}
                  formState={formState}
                  focus={focus.firstName}
                  setFocus={(e) => setFocus({ ...focus, firstName: e })}
                  name="firstName"
                  placeholder="First Name"
                />
              </div>
              <div>
                <Label text="Last Name" />
                <CustomInputWithController
                  rules={{ required: "Please enter last name" }}
                  intent="createUser"
                  border="primary"
                  control={control}
                  formState={formState}
                  focus={focus.lastName}
                  setFocus={(e) => setFocus({ ...focus, lastName: e })}
                  name="lastName"
                  placeholder="Last Name"
                />
              </div>
              <div>
                <Label text="Email" />
                <CustomInputWithController
                  rules={validationForEmail}
                  // intent="generalSettingsDisable"
                  intent={id ? "generalSettingsDisable" : "createUser"}
                  border="primary"
                  control={control}
                  formState={formState}
                  focus={focus.email}
                  setFocus={(e) => setFocus({ ...focus, email: e })}
                  name="email"
                  placeholder="Email"
                  isFieldDisabled={id}
                />
              </div>

              <div>
                <Label text="Password" />
                <CustomInputWithController
                  inputType={showPassword ? "text" : "password"}
                  intent="createUser"
                  border="primary"
                  control={control}
                  formState={formState}
                  focus={focus.password}
                  setFocus={(e) => setFocus({ ...focus, password: e })}
                  name="password"
                  placeholder="Set A Password"
                  rules={validationForPassword}
                  suffix={
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <img src="/images/eye.png" className="w-3 h-3" alt="" />
                      ) : (
                        <BlockedEyeIcon size={12} className="text-black" />
                      )}
                    </button>
                  }
                />
              </div>

              <div>
                <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
                  Role
                </p>

                <Controller
                  name="roleName"
                  rules={{ required: "Please select role" }}
                  control={control}
                  render={({ field }) => (
                    <PopoverDropdown
                      {...field}
                      placeholder="Enter Role"
                      className="max-h-[200px] overflow-y-scroll"
                      formState={formState}
                      options={systemUserRoles}
                      onSelect={(data) => {
                        setValue("roleId", data.id);
                        setValue("roleName", data.value);
                        clearErrors("roleName");
                      }}
                    />
                  )}
                />
                <p
                  className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
                    roleError ? "text-alert-red visible" : "invisible"
                  }`}
                >
                  {roleError}
                </p>
              </div>

              <div>
                <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
                  Hub Station
                </p>

                <Controller
                  name="selectedHubStations"
                  rules={{ required: "Please select a hub station" }}
                  control={control}
                  render={({ field }) => (
                    <AssignHubWhileCreation
                      {...field}
                      selectedHubs={watch("selectedHubStations")}
                      onSelect={(data) => {
                        handleHubStationSelect(data);
                        clearErrors("selectedHubStations");
                      }}
                      hasError={hubStationError}
                    />
                  )}
                />

                <p
                  className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                    hubStationError
                      ? "text-alert-red visible mt-2"
                      : "invisible"
                  }`}
                >
                  {hubStationError}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomLayout>
  );
};

export default GenerateUser;
