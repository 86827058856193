import { createSlice } from "@reduxjs/toolkit";
import {
  getHomeKpi,
  getNotifications,
  updateNotifications,
} from "../APIs/homeAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  loggedIn: false,
  filters: [],

  // phase 3
  qrCodeModal: {
    visible: false,
    data: "",
    header: "",
    type: "",
  },

  kpiData: {
    kpiData: {
      trikes: 0,
      batteries: 0,
      drivers: 0,
      inventory: 0,
    },
  },

  notificationPopupVisible: false,
  showNotificationDot: false,
  notifications: [],
  confirmRoleModal: {
    visible: false,
    mode: "create",
  },
  cannotClearNotificationModal: false,
  noHubStationModal: false,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    updateHome: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    builder.addCase(getHomeKpi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHomeKpi.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.kpiData = action.payload.kpiData;
    });
    builder.addCase(getHomeKpi.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get user notifications
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.notifications = action.payload.result;

      // whether to show red not or no
      if (action.payload.result.some((item) => +item.seen === 0)) {
        state.showNotificationDot = true;
      }
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // update user notifications to seen = 1
    builder.addCase(updateNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.notifications = action.payload.result;
      state.showNotificationDot = false;
    });
    builder.addCase(updateNotifications.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateHome } = homeSlice.actions;

export default homeSlice.reducer;
