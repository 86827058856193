/* eslint-disable react/no-array-index-key */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomModal from "./CustomModal";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import ArrowIcon from "../../../Common/Svgs/ArrowIcon";
import TickIcon from "../../../Common/Svgs/TickIcon";
import AlertIcon from "../../../Common/Svgs/AlertIcon";
import { TRIKE_STATUS } from "../../../Modules/Trikes/Data";

export const ChecklistCTA = ({ record }) => {
  const dispatch = useDispatch();

  const onTripStatus = [TRIKE_STATUS.ON_TRIP, TRIKE_STATUS.OUT_OF_GEOFENCE];

  if (!onTripStatus.includes(+record.status)) {
    return (
      <p className="text-xs font-semibold font-poppins leading-4.5 text-dark-gray mx-5 capitalize">
        --
      </p>
    );
  }

  return (
    <button
      type="button"
      className="flex items-center cursor-pointer gap-x-1"
      onClick={() =>
        dispatch(
          updateTrikes({
            key: "checklistModal",
            value: {
              visible: true,
              checklist: record.checklist,
              trike: record,
            },
          }),
        )
      }
    >
      <div className="text-xs font-semibold font-poppins leading-4.5 text-dark-gray capitalize">
        View
      </div>
      <img src="/assets/icons/chevron-down.svg" alt="" />
    </button>
  );
};

const IndexPassIcon = () => {
  return (
    <div className="w-6 h-6 rounded-full flex items-center justify-center bg-green">
      <TickIcon size={14} className="text-yellow" />
    </div>
  );
};

const IndexFailIcon = () => {
  return (
    <div className="w-6 h-6 rounded-full flex items-center justify-center bg-alert-red">
      <AlertIcon size={14} className="text-white" />
    </div>
  );
};

const ChecklistModal = ({ visible, onCancel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { checklistModal } = useSelector((state) => state.trikes);

  const handleKnownIssues = () => {
    dispatch(
      updateTrikes({
        key: "currentTrike",
        value: checklistModal.trike,
      }),
    );

    navigate(`/add-known-issue/${checklistModal.trike.registrationNo}`);
    onCancel();
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="662px">
      <div
        className="p-6 max-h-[685px] overflow-y-scroll"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between">
          <p className="font-poppins text-base font-medium">
            Safety Checklist Form
          </p>

          <button type="button" onClick={onCancel}>
            <CloseIcon className="stroke-dark-gray w-6 h-6" />
          </button>
        </div>

        <p className="font-aileron text-sm font-normal text-[#6F6F6F]">
          View the status of the trike recorded at the parking lot.
        </p>

        <button
          type="button"
          className="mt-2 py-2 px-4 rounded-md bg-primary-10 text-primary"
          onClick={handleKnownIssues}
        >
          <div className="flex items-center gap-x-2">
            <p className="font-poppins text-sm font-medium">Known Issues</p>
            <ArrowIcon size={20} className="rotate-90" strokeWidth={1} />
          </div>
        </button>

        <div className="my-6">
          {checklistModal.checklist.map((item, index) => (
            <div
              key={index}
              className={`flex items-center justify-between py-3 border-b border-[#E7E7E7] ${
                index === 0 && "border-t"
              }`}
            >
              <div className="flex items-center gap-x-2">
                {item.passed ? <IndexPassIcon /> : <IndexFailIcon />}

                <p className="font-poppins text-sm font-medium capitalize">
                  {item.partName}
                </p>
              </div>

              <div className="flex items-center gap-x-6">
                <div
                  className={`w-10 h-10 flex items-center justify-center rounded-lg ${
                    item.passed ? "bg-[#E7E7E7]" : "bg-alert-red"
                  }`}
                >
                  <CloseIcon
                    className={`w-4 h-4 ${
                      item.passed ? "stroke-[#6F6F6F]" : "stroke-white"
                    }`}
                  />
                </div>

                <div
                  className={`w-10 h-10 flex items-center justify-center rounded-lg ${
                    item.passed ? "bg-green" : "bg-[#E7E7E7]"
                  }`}
                >
                  <TickIcon size={16} className="text-white" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </CustomModal>
  );
};

export default ChecklistModal;
