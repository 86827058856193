import React from "react";

const BatteryBar = ({
  color,
  current,
  text,
  width,
  height,
  textColor = "text-dark-gray font-light text-xs leading-4.5",
  containerClassName = "",
}) => {
  return (
    <div
      className={`relative w-full h-full bg-background-white-hover rounded-xl max-w-[100px] ${containerClassName}`}
      style={{
        width,
        height,
      }}
    >
      <div
        style={{
          height: `${current}%`,
        }}
        className={`${color} w-full rounded-xl absolute bottom-0`}
      >
        <div
          className={`${textColor} font-aileron text-center absolute bottom-2 left-0 right-0 capitalize`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};
export default BatteryBar;
