import React from "react";
import { useSelector } from "react-redux";
import { LOCATION_TYPE } from "../../Location/Data";

const useLocationStops = () => {
  const { allLocations } = useSelector((state) => state.locations);

  const { stops } = useSelector((state) => state.trips);

  const origin = stops[0];

  // if origin is selected, return all the locations whose hubId matches with locationId of origin
  if (+origin.locationId !== 1) {
    const locations = allLocations
      .filter(
        (loc) =>
          loc?.hubId === origin.locationId &&
          loc.type !== LOCATION_TYPE.HUB_STATION,
      )
      .map((item) => ({
        key: item.id,
        value: item.name,
        label: (
          <div className="flex items-center px-2 py-2 gap-x-3">
            <p className="text-xs font-semibold text-black capitalize font-aileron">
              {item.name}
            </p>
          </div>
        ),
      }));

    return locations;
  }

  // if origin is not yet selected, return all the locations
  const locations = allLocations
    .filter((loc) => loc.type !== LOCATION_TYPE.HUB_STATION)
    .map((item) => ({
      key: item.id,
      value: item.name,
      label: (
        <div className="flex items-center px-2 py-2 gap-x-3">
          <p className="text-xs font-semibold text-black capitalize font-aileron">
            {item.name}
          </p>
        </div>
      ),
    }));

  return locations;
};

export default useLocationStops;
