import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { UpcomingAndOngoingData } from "../Data/TripDetailData";
import { TRIP_STATUS } from "../Data/TripConstants";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import TripInformation from "../TripDetails/TripInformation";
import StopDetails from "../TripDetails/StopDetails";

const TripKpiAndInformation = () => {
  const { currentTrip, tripDetailsKPI } = useSelector((state) => state.trips);

  return (
    <div className="flex flex-col p-6 gap-y-6 bg-background h-[calc(100vh-128px)] overflow-y-scroll">
      <Row gutter={24}>
        {UpcomingAndOngoingData(tripDetailsKPI, currentTrip).map((kpi) => (
          <Col span={6} key={kpi.id}>
            <div
              className={`p-4 rounded-md ${
                currentTrip.statusId === TRIP_STATUS.UPCOMING
                  ? "bg-light-grey"
                  : "bg-kpi-background"
              }
        `}
            >
              <div className="flex items-center gap-x-2.5">
                <CustomRoundedImage background="bg-kpi-icon" image={kpi.icon} />
                <p className="text-xs font-normal capitalize font-aileron text-trip-kpi-title">
                  {kpi.title}
                </p>
              </div>
              <p className="ml-12 text-2xl font-semibold text-trip-kpi-value font-poppins">
                {kpi.value}
                {kpi.unit && (
                  <span className="text-base ml-0.5">{kpi.unit}</span>
                )}
              </p>
            </div>
          </Col>
        ))}
      </Row>
      <Row>
        <Col span={24}>
          <TripInformation />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StopDetails />
        </Col>
      </Row>
    </div>
  );
};

export default TripKpiAndInformation;
