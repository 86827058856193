export const headerButtons = [
  {
    id: 1,
    padding: "filter",
    width: "settingsDriversTab",
    text: "Payments",
  },
  {
    id: 2,
    padding: "filter",
    width: "settingsDriversTab",
    text: "Collections",
  },
];

export const PAYMENT_RECEIVER_TYPE = {
  DRIVER: 1,
  FARMER: 2,
  OFFTAKER: 3,
};

export const PAYMENT_TYPE = {
  PAYMENT: 1,
  COLLECTION: 2,
};

export const PAYMENT_STATUS = {
  PENDING: 1,
  PARTIAL: 2,
  COMPLETED: 3,
};
