/* eslint-disable no-console */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox } from "antd";
import Header from "./Header";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import useSearch from "../../../Core/hooks/useSearch";
import { BATTERY_STATUS } from "../../Battery/Data";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import getBatteryStatus from "../../../Utils/getBatteryStatus";
import BatteryPercentageStatus from "../../Battery/phase3/BatteryPercentageStatus";
import TickIcon from "../../../Common/Svgs/TickIcon";
import ToastMessageModal from "../ToastMessageModal";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import {
  addBatteryAmbulance,
  editBatteryAmbulance,
} from "../../../Redux/APIs/tripsAPI";
import { client } from "../../../Utils/axiosClient";

const DisabledCheckbox = () => {
  return (
    <div className="w-4 h-4 rounded flex items-center justify-center bg-background border border-light-grey">
      <TickIcon size={12} className="text-light-grey" strokeWidth={3.5} />
    </div>
  );
};

const BatteryAmbulance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { stops, tripDetails, toastMessage, editBatterySOS } = useSelector(
    (state) => state.trips,
  );

  const { allBatteries } = useSelector((state) => state.batteries);

  const [focus, setFocus] = useState(false);
  const [batteriesInSystem, setBatteriesInSystem] = useState([]);
  const [selectedBatteries, setSelectedBatteries] = useState([]);
  const [associatedActiveBatteries, setAssociatedActiveBatteries] = useState(
    [],
  );
  const [associatedInactiveBatteries, setAssociatedInactiveBatteries] =
    useState([]);

  const { filteredData, search, setSearch } = useSearch(batteriesInSystem, [
    "value",
  ]);

  // find the batteries currently associated to the trip
  const getTripBatteries = useCallback(async () => {
    try {
      dispatch(updateTrips({ key: "loading", value: true }));

      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/trip/get-trip-batteries`,
        {
          params: {
            tripId: +id,
          },
        },
      );

      if (response.data.result) {
        const processedBatteries = response.data.result.map((item) => {
          const matchingBattery = allBatteries.find(
            (battery) => battery.id === item.id,
          );
          return {
            ...item,
            ...matchingBattery,
            value: item.registrationNo,
          };
        });

        const activeBatteries = processedBatteries.filter(
          (item) => item.assetMappingActiveStatus,
        );

        const inactiveBatteries = processedBatteries.filter(
          (item) => item.assetMappingActiveStatus == 0,
        );

        setAssociatedActiveBatteries(activeBatteries);
        setAssociatedInactiveBatteries(inactiveBatteries);
        setSelectedBatteries(() => inactiveBatteries.map((item) => item.id));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateTrips({ key: "loading", value: false }));
    }
  }, [dispatch, id, allBatteries]);

  // find the batteries currently in hub station (hub station == origin while creating the trip)
  const getBatteries = useCallback(() => {
    let local = allBatteries;

    if (associatedInactiveBatteries.length) {
      const ignoredIds = associatedInactiveBatteries.map((item) => item.id);
      local = local.filter((item) => !ignoredIds.includes(item.id));
    }

    // Find hub station batteries
    const hubStationBatteries = local
      .filter(
        (item) =>
          +item.status === BATTERY_STATUS.HUB_STATION &&
          item.currentHubId === stops[0].locationId,
      )
      .map((battery) => ({
        ...battery,
        value: battery.registrationNo,
        assetMappingActiveStatus: 2,
      }));

    setBatteriesInSystem(hubStationBatteries);
  }, [allBatteries, stops, associatedInactiveBatteries]);

  const handleBatterySelection = (battery) => {
    const updatedBatteries = selectedBatteries.includes(battery.id)
      ? selectedBatteries.filter((item) => item !== battery.id)
      : [...selectedBatteries, battery.id];

    setSelectedBatteries(updatedBatteries);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSaveChanges = async () => {
    if (!selectedBatteries.length) {
      dispatch(
        updateTrips({
          key: "toastMessage",
          value: {
            ...toastMessage,
            visible: true,
            type: "noBatteriesError",
          },
        }),
      );
      return;
    }

    const payload = {
      tripId: tripDetails.id,
      batteries: selectedBatteries,
    };

    if (editBatterySOS) {
      await dispatch(editBatteryAmbulance(payload));
    } else {
      await dispatch(addBatteryAmbulance(payload));
    }
    navigate(-1);
  };

  // clear the toast message
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTrips({
          key: "toastMessage",
          value: { ...toastMessage, visible: false },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, toastMessage]);

  useEffect(() => {
    getBatteries();
  }, [getBatteries]);

  useEffect(() => {
    getTripBatteries();
  }, [getTripBatteries]);

  return (
    <CustomLayout pageName={id}>
      <div className="h-full bg-background-white">
        <div className="flex justify-between py-[13px] px-6 border-b border-light-grey">
          <Header />

          <div className="flex items-center gap-x-3">
            <Button
              size="primary"
              width="generateUser"
              colors="grey"
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <Button
              size="primary"
              width="generateUser"
              type="submit"
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
          </div>
        </div>

        <div className="py-6 mx-6 border-b border-light-grey">
          <p className="font-poppins text-base font-medium">
            Select More Batteries (Battery Ambulance)
          </p>

          <p className="mt-1 font-aileron text-10 font-normal text-dark-gray">
            Select from the available batteries at your hub station.
          </p>
        </div>

        <div className="w-full px-6">
          <div className="py-2">
            <CustomInput
              intent="searchHome"
              prefix={
                <img src="/assets/icons/search.svg" alt="" className="mr-2" />
              }
              value={search}
              focus={focus}
              border={search.length > 0 ? "success" : "none"}
              placeholderText="filterSelected"
              placeholder="Search Battery ID"
              onChange={(e) => setSearch(e.target.value)}
              suffix={
                search.length > 0 ? (
                  <CloseIcon
                    className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                    onClick={() => {
                      setSearch("");
                      setFocus(false);
                    }}
                  />
                ) : null
              }
            />
          </div>
          <div className="h-[calc(100vh-340px)] mt-2 overflow-y-scroll">
            {associatedActiveBatteries.map((battery) => {
              return (
                <div
                  className="flex justify-between h-10 px-6 bg-[#f1f1f1] border-b border-light-grey cursor-not-allowed"
                  key={battery.id}
                >
                  <div className="flex items-center gap-x-3">
                    <DisabledCheckbox />
                    <p className="text-xs font-semibold text-black capitalize font-aileron">
                      {battery.value}
                    </p>
                  </div>

                  <div className="flex items-center gap-x-12">
                    <div className="max-w-max flex items-center justify-center py-1 px-2 bg-yellow rounded-md">
                      <p className="font-poppins text-xs font-medium text-green">
                        Associated
                      </p>
                    </div>

                    <div className="w-[52px]">
                      <BatteryPercentageStatus
                        detail={battery}
                        showChargingStatus={false}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            {associatedInactiveBatteries.map((battery) => {
              const selected = selectedBatteries.includes(battery.id);

              return (
                <div
                  className={`flex justify-between h-10 px-6 border-b cursor-pointer hover:bg-primary-20 border-light-grey 
                  ${selected ? "bg-primary-20" : ""}`}
                  key={battery.id}
                  onClick={() => handleBatterySelection(battery)}
                >
                  <div className="flex items-center gap-x-3">
                    <Checkbox checked={selected} />
                    <p className="text-xs font-semibold text-black capitalize font-aileron">
                      {battery.value}
                    </p>
                  </div>

                  <div className="flex items-center gap-x-12">
                    <div className="w-[112px] flex justify-end">
                      {selected ? (
                        <div className="max-w-max flex items-center justify-center py-1 px-2 bg-green-10 rounded-md">
                          <p className="font-poppins text-xs font-medium text-green">
                            Selected
                          </p>
                        </div>
                      ) : (
                        getBatteryStatus(battery.status)
                      )}
                    </div>

                    <div className="w-[52px]">
                      <BatteryPercentageStatus
                        detail={battery}
                        showChargingStatus={false}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            {filteredData.map((battery) => {
              const selected = selectedBatteries.includes(battery.id);

              return (
                <div
                  className={`flex justify-between h-10 px-6 border-b cursor-pointer hover:bg-primary-20 border-light-grey 
                  ${selected ? "bg-primary-20" : ""}`}
                  key={battery.id}
                  onClick={() => handleBatterySelection(battery)}
                >
                  <div className="flex items-center gap-x-3">
                    <Checkbox checked={selected} />
                    <p className="text-xs font-semibold text-black capitalize font-aileron">
                      {battery.value}
                    </p>
                  </div>

                  <div className="flex items-center gap-x-12">
                    <div className="w-[112px] flex justify-end">
                      {getBatteryStatus(battery.status)}
                    </div>

                    <div className="w-[52px]">
                      <BatteryPercentageStatus
                        detail={battery}
                        showChargingStatus={false}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <ToastMessageModal
        visible={toastMessage.visible}
        onCancel={() =>
          dispatch(
            updateTrips({
              key: "toastMessage",
              value: { ...toastMessage, visible: false },
            }),
          )
        }
      />
    </CustomLayout>
  );
};

export default BatteryAmbulance;
