import dayjs from "dayjs";

const getTimeDifference = (startTime, endTime) => {
  if (Number.isNaN(startTime) || Number.isNaN(endTime)) {
    return null;
  }

  const start = new Date(`2023-01-01T${startTime}:00Z`);
  const end = new Date(`2023-01-01T${endTime}:00Z`);

  const timeDiffInMilliseconds = end - start;

  if (Number.isNaN(timeDiffInMilliseconds)) {
    return null;
  }

  const hours = Math.floor(timeDiffInMilliseconds / 3600000);
  const minutes = Math.floor((timeDiffInMilliseconds % 3600000) / 60000);

  return `${hours}h ${minutes}m`;
};

export default getTimeDifference;

export const tripDuration = (startTime, endTime) => {
  if (!startTime && !endTime) return "--";

  const differenceInMinutes = dayjs(endTime).diff(dayjs(startTime), "minutes");

  const hours = Math.floor(differenceInMinutes / 60); // Get the hours
  const minutes = differenceInMinutes % 60; // Get the remaining minutes

  return `${hours}h ${minutes}m`;
};
