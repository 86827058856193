import React from "react";
import { PAYMENT_STATUS } from "../data";
import { getCollectionPaymentStatus } from "../getPaymentStatus";

const PaymentStatus = ({ payment }) => {
  if (
    payment?.details?.every(
      (item) => item.paymentStatus === PAYMENT_STATUS.COMPLETED,
    )
  ) {
    return (
      <div className="w-[120px]">
        {getCollectionPaymentStatus(PAYMENT_STATUS.COMPLETED)}
      </div>
    );
  }

  if (
    payment?.details?.every(
      (item) => item.paymentStatus === PAYMENT_STATUS.PENDING,
    )
  ) {
    return (
      <div className="w-[120px]">
        {getCollectionPaymentStatus(PAYMENT_STATUS.PENDING)}
      </div>
    );
  }

  return (
    <div className="w-[130px]">
      {getCollectionPaymentStatus(PAYMENT_STATUS.PARTIAL)}
    </div>
  );
};

export default PaymentStatus;
