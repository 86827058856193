/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Radio } from "antd";
import CustomInput from "../../../Core/Components/CustomInput";
import CustomTags from "../../../Core/Components/CustomTags";
import useSearch from "../../../Core/hooks/useSearch";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import Button from "../../../Core/Components/CustomButton";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import BatteryLowIcon from "../../../Common/Svgs/BatteryChargeIcon";
import { TRIKE_STATUS } from "../../Trikes/Data";
import ToastMessageModal from "../ToastMessageModal";
import { TrikeEmptyState } from "./EmptyState";

const getAvailablityTag = (isAvaliable) => {
  switch (isAvaliable) {
    case 1:
      return (
        <CustomTags colors="checkIn" background="checkIn">
          <p className="text-xs font-medium font-poppins">Available</p>
        </CustomTags>
      );
    case 2:
      return (
        <CustomTags colors="warning" background="warning">
          <p className="text-xs font-medium font-poppins">Unvailable</p>
        </CustomTags>
      );
    default:
      return (
        <CustomTags colors="checkIn" background="checkIn">
          <p className="text-xs font-medium font-poppins">Available</p>
        </CustomTags>
      );
  }
};

const TripStep4 = () => {
  const dispatch = useDispatch();

  const { tripDetails, toastMessage, stops } = useSelector(
    (state) => state.trips,
  );
  const { allTrikes } = useSelector((state) => state.trikes);

  const [focus, setFocus] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [trikesInSystem, setTrikesInSystem] = useState([]);

  const { filteredData, search, setSearch } = useSearch(trikesInSystem, [
    "value",
  ]);

  const handleSelection = (trike) => {
    // if (editTripDetails.flag && tripDetails.statusId === TRIP_STATUS.ONGOING) {
    //   dispatch(
    //     updateTrips({
    //       key: "toastMessage",
    //       value: {
    //         ...toastMessage,
    //         visible: true,
    //         type: "editTrikeError",
    //       },
    //     }),
    //   );
    // } else {
    dispatch(
      updateTrips({
        key: "tripDetails",
        value: {
          ...tripDetails,
          trikeId: trike.id,
          trikeRegistrationNo: trike.value,
        },
      }),
    );
    // }
  };

  const onClearSelection = () => {
    // if (editTripDetails.flag && tripDetails.statusId === TRIP_STATUS.ONGOING) {
    //   dispatch(
    //     updateTrips({
    //       key: "toastMessage",
    //       value: {
    //         ...toastMessage,
    //         visible: true,
    //         type: "editTrikeError",
    //       },
    //     }),
    //   );
    // } else {
    dispatch(
      updateTrips({
        key: "tripDetails",
        value: { ...tripDetails, trikeId: "", trikeRegistrationNo: "" },
      }),
    );
    // }
  };

  const getTrikesInSystem = useCallback(() => {
    let trikeStatusToCheck = [];

    // if creating a new trip and start date = current date, return only hub station trikes
    if (dayjs(tripDetails.startDate).isSame(dayjs(), "day")) {
      trikeStatusToCheck = [TRIKE_STATUS.HUB_STATION];
    }
    // if  trip date = future date, return all the trikes
    else {
      trikeStatusToCheck = [
        TRIKE_STATUS.ON_TRIP,
        TRIKE_STATUS.OUT_OF_GEOFENCE,
        TRIKE_STATUS.HUB_STATION,
      ];
    }

    const trikes = allTrikes
      ?.filter((item) => {
        if (
          trikeStatusToCheck.includes(+item.status) &&
          stops[0].locationId == item.hubId
        ) {
          return true;
        }
        return false;
      })
      ?.map((t) => ({
        id: t.id,
        value: t.registrationNo,
      }));

    setTrikesInSystem([...trikes]);
  }, [allTrikes]);

  const renderBody = () => {
    if (trikesInSystem.length) {
      return (
        <div className="w-full h-full">
          <div className="flex items-center gap-x-3">
            <CustomInput
              intent="searchHome"
              prefix={
                <img src="/assets/icons/search.svg" alt="" className="mr-2" />
              }
              value={search}
              focus={focus}
              border={search.length > 0 ? "success" : "none"}
              placeholderText="filterSelected"
              placeholder="Search Trike No."
              onChange={(e) => setSearch(e.target.value)}
              suffix={
                search.length > 0 ? (
                  <CloseIcon
                    className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                    onClick={() => {
                      setSearch("");
                      setFocus(false);
                    }}
                  />
                ) : null
              }
            />
            <Button
              size="primary"
              width="generateUser"
              colors="grey"
              onClick={onClearSelection}
            >
              Clear Selection
            </Button>
          </div>

          <div className="h-[calc(100vh-331px)] mt-2 overflow-y-scroll">
            {filteredData.map((trike) => (
              <div
                className={`flex justify-between h-10 px-6 border-b cursor-pointer hover:bg-primary-20 border-light-grey 
            ${
              trike.id === parseInt(tripDetails.trikeId, 10)
                ? "bg-primary-20"
                : ""
            }`}
                key={trike.id}
                onClick={() => handleSelection(trike)}
              >
                <div className="flex items-center gap-x-3">
                  <Radio
                    checked={trike.id === parseInt(tripDetails.trikeId, 10)}
                  />
                  <p className="text-xs font-semibold text-black capitalize font-aileron">
                    {trike.value}
                  </p>
                </div>
                <div className="flex items-center gap-x-6">
                  {getAvailablityTag(1)}
                  <div className="flex items-center gap-x-2">
                    <BatteryLowIcon />
                    <p className="text-xs font-semibold font-aileron text-alert-red">
                      N/A
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    // if (loading) return <div className="w-full h-full" />;

    return <TrikeEmptyState />;
  };

  useEffect(() => {
    getTrikesInSystem();
  }, [getTrikesInSystem]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTrips({
          key: "toastMessage",
          value: { ...toastMessage, visible: false },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, toastMessage]);

  return (
    <div className="w-full">
      {renderBody()}

      <ToastMessageModal
        visible={toastMessage.visible}
        onCancel={() =>
          dispatch(
            updateTrips({
              key: "toastMessage",
              value: { ...toastMessage, visible: false },
            }),
          )
        }
      />
    </div>
  );
};
export default TripStep4;
