/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";

export const createTrip = createAsyncThunk(
  "trips/createTrip",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trip/create-trip`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTrips = createAsyncThunk(
  "trips/getTrips",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/trip-details`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteTrip = createAsyncThunk(
  "trips/deleteTrip",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trip/delete-trip`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTripById = createAsyncThunk(
  "trips/getTripById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/get-trip-by-id`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editTrip = createAsyncThunk(
  "trips/editTrip",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trip/edit-trip`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTripsForTrikeId = createAsyncThunk(
  "trips/getTripsForTrikeId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/trips-for-trike-id`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const optimiseRoute = createAsyncThunk(
  "trips/optimiseRoute",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/optimise-route`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTrikesAndDriversForTrip = createAsyncThunk(
  "trips/getTrikesAndDriversForTrip",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/get-trikes-and-drivers`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getDistance = createAsyncThunk(
  "trips/getDistance",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/get-distance`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// phase 3
export const getTripsV3 = createAsyncThunk(
  "trips/getTripsV3",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/get-trips`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getKpiData = createAsyncThunk(
  "trips/getKpiData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/get-kpi-data`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTripsForTrikeV3 = createAsyncThunk(
  "trips/getTripsForTrikeV3",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/trips-for-trike`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTripsForDriverV3 = createAsyncThunk(
  "trips/getTripsForDriverV3",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/trips-for-driver`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getOverallSummary = createAsyncThunk(
  "trips/getOverallSummary",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/overall-summary`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const endTripByAdmin = createAsyncThunk(
  "trips/endTripByAdmin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trip/end-trip-by-admin`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTripDetailsKPI = createAsyncThunk(
  "trips/getTripDetailsKPI",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trip/trip-details-kpi`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addBatteryAmbulance = createAsyncThunk(
  "trips/addBatteryAmbulance",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trip/add-battery-ambulance`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editBatteryAmbulance = createAsyncThunk(
  "trips/editBatteryAmbulance",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trip/edit-battery-ambulance`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
