import React from "react";
import CustomTooltip from "../../../Core/Components/CustomTooltip";
import getDateAndTime from "../../../Utils/getDateAndTime";
import BatteryPercentage from "../../../Core/Components/BatteryPercentage";

const BatteryPackColumn = ({ record }) => {
  if (!record.batteryPack) return "--";

  const getBatteryPercentage = () => {
    const sum = record?.batteryPack.reduce((acc, current) => {
      acc += parseFloat(current.percentage);

      return acc;
    }, 0);

    const percentage =
      sum > 0 ? (sum / record.batteryPack.length).toFixed(2) : -1;

    return percentage;
  };

  return (
    <div>
      {record.lastOnline ? (
        <CustomTooltip
          tooltipTitle={`As on ${getDateAndTime(record.lastOnline)}`}
          placement="topLeft"
        >
          <div>
            <BatteryPercentage
              percentage={getBatteryPercentage()}
              chargingStatus={false}
            />
          </div>
        </CustomTooltip>
      ) : (
        <div>
          <BatteryPercentage
            percentage={getBatteryPercentage()}
            chargingStatus={false}
          />
        </div>
      )}
    </div>
  );
};

export default BatteryPackColumn;
