import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { LOCATION_TYPE } from "../Location/Data";
import CustomLayout from "../../Core/Layout/CustomLayout";
import Button from "../../Core/Components/CustomButton";
import { PopoverDropdown } from "../../Core/Components/CustomDropdown";
import { CustomInputWithController } from "../../Core/Components/CustomInput";
import { rateUnitOptions } from "./data";
import { updateRates } from "../../Redux/Slices/rates.slice";
import { addCommodity, editCommodity } from "../../Redux/APIs/ratesAPI";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
  showUnit = true,
}) => {
  return (
    <div className="pt-6">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>

      <div className="flex items-center gap-x-3">
        <CustomInputWithController
          intent="createUser"
          control={control}
          formState={formState}
          focus={focus}
          setFocus={(e) => setFocus(e)}
          name={name}
          placeholder={placeholder}
          rules={rules}
        />
        {showUnit && <p className="font-aileron text-xs font-normal">KSH</p>}
      </div>
    </div>
  );
};

const AddCommodity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { currentCommodity } = useSelector((state) => state.rates);

  const { allLocations } = useSelector((state) => state.locations);

  const hubStations = allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const { handleSubmit, control, formState, setValue, clearErrors, setError } =
    useForm({
      defaultValues: {
        name: id ? currentCommodity?.name : "",
        hubId: id ? currentCommodity?.hubId : null,
        hubName: id ? currentCommodity?.hubName : "",
        unit: id ? currentCommodity?.unit : "",
        paymentRate: id ? currentCommodity?.paymentRate : null,
        collectionRate: id ? currentCommodity?.collectionRate : null,
        commissionRate: id ? currentCommodity?.commissionRate : null,
      },
    });

  const [focus, setFocus] = useState({
    name: false,
    paymentRate: false,
    collectionRate: false,
    commissionRate: false,
  });

  const hubNameError = formState?.errors?.hubName?.message;
  const unitError = formState?.errors?.unit?.message;

  const rules = {
    required: "Required.",
    validate: {
      isNumber: (value) => {
        return value > 0 || "Please enter valid number";
      },
    },
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit = async (e) => {
    const payload = e;
    payload.unit = e.unit.toLowerCase();
    payload.name = e.name.toLowerCase();

    if (id) {
      payload.commodityId = id;

      const editResponse = await dispatch(editCommodity(payload));

      if (
        editResponse?.payload?.response &&
        editResponse?.payload?.response?.status !== 200
      ) {
        setError("name", {
          type: "server",
          shouldFocus: true,
          message: editResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateRates({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Commodity Edited",
                description: `Commodity ${e.name
                  .charAt(0)
                  .toUpperCase()}${e.name.slice(1)} for ${
                  e.hubName
                } was edited successfully`,
              },
            },
          }),
        );

        navigate(-1);
      }
    } else {
      const addResponse = await dispatch(addCommodity(payload));

      if (
        addResponse?.payload?.response &&
        addResponse?.payload?.response?.status !== 200
      ) {
        setError("name", {
          type: "server",
          shouldFocus: true,
          message: addResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateRates({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "New Commodity Added",
                // description: `${e.name} was added for ${e.hubName} successfully`,
                description: `${e.name.charAt(0).toUpperCase()}${e.name.slice(
                  1,
                )} was added for ${e.hubName} successfully`,
              },
            },
          }),
        );

        navigate(-1);
      }
    }
  };

  return (
    <CustomLayout pageName="Rates Manager" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            Add a new commodity
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              size="filter"
              width="generateUser"
              colors="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {id ? "Save Changes" : "Add Commodity"}
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-[calc(100vh-72px-89px)] overflow-y-scroll px-6 pb-6 bg-background-white">
        <LabelAndInput
          label="Name"
          control={control}
          formState={formState}
          name="name"
          placeholder="Enter Name"
          rules={{ required: "Required." }}
          focus={focus.name}
          setFocus={(state) => setFocus({ ...focus, name: state })}
          showUnit={false}
        />

        <div className="mt-6">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Hub Station
          </p>

          <Controller
            name="hubName"
            control={control}
            rules={{ required: "Required." }}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={hubStations}
                onSelect={(data) => {
                  setValue("hubName", data.value);
                  setValue("hubId", data.id);
                  clearErrors("hubName");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              hubNameError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {hubNameError}
          </p>
        </div>

        <div className="mt-6">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Unit of weight
          </p>

          <Controller
            name="unit"
            control={control}
            rules={{ required: "Required." }}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                placeholder="Select Unit"
                className="max-h-[300px]"
                formState={formState}
                options={rateUnitOptions}
                onSelect={(data) => {
                  setValue("unit", data.value);
                  clearErrors("unit");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              unitError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {unitError}
          </p>
        </div>

        <LabelAndInput
          label="Set a rate for payment"
          control={control}
          formState={formState}
          name="paymentRate"
          placeholder="Enter A Rate"
          rules={rules}
          focus={focus.paymentRate}
          setFocus={(state) => setFocus({ ...focus, paymentRate: state })}
        />

        <LabelAndInput
          label="Set a rate for collection"
          control={control}
          formState={formState}
          name="collectionRate"
          placeholder="Enter A Rate"
          rules={rules}
          focus={focus.collectionRate}
          setFocus={(state) => setFocus({ ...focus, collectionRate: state })}
        />

        <LabelAndInput
          label="Set a rate for commission"
          control={control}
          formState={formState}
          name="commissionRate"
          placeholder="Enter A Rate"
          rules={rules}
          focus={focus.commissionRate}
          setFocus={(state) => setFocus({ ...focus, commissionRate: state })}
        />
      </div>
    </CustomLayout>
  );
};

export default AddCommodity;
