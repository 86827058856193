/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Col, Divider, Row } from "antd";
import { trikeMapMarkerDetails } from "../../Modules/Home/Data/HomeData";
import Button from "./CustomButton";
import ZoomButton, { RelocateButton } from "./MapMarkerButtons";
import VerifyIcon from "../../Common/Svgs/VerifyIcon";
import CustomTooltip from "./CustomTooltip";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import { TRIKE_STATUS } from "../../Modules/Trikes/Data";
import { MobiliseModalCTA } from "./Modal/MobiliseModalV3";
import { updateTrikes } from "../../Redux/Slices/trikes.slice";

const TrikeMapMarkerDetails = ({
  assetId = " KTWC_408R",
  trikeDetail,
  page = "",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { trikeBatteries } = useSelector((state) => state.locations);

  const handleViewDetails = () => {
    dispatch(
      updateTrikes({
        key: "currentTrike",
        value: trikeDetail,
      }),
    );

    navigate(`/trikes/${trikeDetail?.registrationNo}`);
  };

  return (
    <div className="p-4 w-[336px] bg-white rounded-lg">
      <div className="flex justify-between pb-4 border-b border-light-grey">
        <div>
          <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
            {assetId}
          </div>
          <div className="flex items-center pt-1.5">
            <div className="font-normal font-aileron text-10 leading-3.75 text-dark-grey-text capitalize">
              {trikeDetail?.status == TRIKE_STATUS.ON_TRIP ||
              trikeDetail?.status == TRIKE_STATUS.OUT_OF_GEOFENCE
                ? "Online"
                : "Offline"}
            </div>
            <Divider type="vertical" className="bg-light-grey mt-[3px]" />
            <div className="font-light font-poppins text-10 leading-3.75 text-dark-grey-text">
              {trikeDetail.lastOnline === null
                ? "-"
                : dayjs(trikeDetail.lastOnline).format("hh:mm a")}
            </div>
          </div>
        </div>

        <div>
          <MobiliseModalCTA trike={trikeDetail} />
        </div>
      </div>
      {trikeMapMarkerDetails(trikeDetail, trikeBatteries?.[0]?.[0]).map(
        (item, index) => (
          <div key={item.id} className="flex items-center justify-between mt-4">
            <div
              className={`flex items-center gap-x-2
               ${
                 index === 5 && trikeDetail?.batteryPack?.length > 4
                   ? "items-start"
                   : "items-center"
               }
              `}
            >
              <CustomTooltip tooltipTitle={item.tooltipText}>
                <img
                  src={`/assets/icons/home/map-markers/${item.icon}`}
                  alt=""
                />
              </CustomTooltip>
              <div className="text-10 capitalize font-medium leading-3.75 font-aileron text-black">
                {item.title}
              </div>
            </div>
            <div className="text-10 font-normal leading-3.75 font-aileron text-dark-grey-text">
              {item.details}
            </div>
          </div>
        ),
      )}

      {page !== "details" && (
        <div className="flex justify-end mt-4">
          <Button onClick={handleViewDetails}>View Details</Button>
        </div>
      )}
    </div>
  );
};

export const OtherMapMarkerDetails = ({ item }) => {
  const { allTrikes } = useSelector((state) => state.trikes);

  const locationLat = item?.lat || item?.coordinates?.lat;
  const locationLng = item?.long || item?.coordinates?.lng;

  const renderLocation = () => {
    switch (item.type) {
      case "1":
        return "Hub Station";
      case "4":
        return "Farm";
      default:
        return "Hub Station";
    }
  };

  // get the total no of trikes available at a particular hub
  const getTrikes = useCallback(() => {
    return allTrikes?.filter((trike) => trike.hubId == item.id).length || 0;
  }, [allTrikes, item]);

  return (
    <div className="p-6 w-[336px] bg-white rounded-lg">
      <div className="flex justify-between pb-4 border-b border-light-grey">
        <div>
          <div className="text-xs font-medium font-poppins leading-4.5 text-new-black capitalize">
            {item?.name}
          </div>
          <div className="font-light font-poppins text-10 leading-3.75 text-dark-grey-text mt-1.5">
            {renderLocation()}
          </div>
        </div>
        {item?.type === "1" && (
          <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text">
            <span className="font-medium">{getTrikes(item)} </span>
            Trikes Available
          </div>
        )}
      </div>
      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center gap-x-2">
          <img src="/assets/icons/home/map-markers/location.svg" alt="" />
          <div className="font-poppins text-10 leading-3.75 font-medium text-new-black">
            {item?.address ? item?.address : "--"}
            {/* <div className="font-poppins text-10 leading-3.75 font-medium text-new-black">
              {city},{" "}
              <span className="font-light text-dark-grey-text">{country}</span>
            </div> */}
          </div>
        </div>
        <div className="text-10 font-light leading-3.75 font-poppins text-dark-grey-text">
          {parseFloat(locationLat).toFixed(6)},{" "}
          {parseFloat(locationLng).toFixed(6)}
        </div>
      </div>
    </div>
  );
};

export const MapDetailDrawer = ({ initialZoomLevel, mapRef, center }) => {
  // const { allTrikes } = useSelector((state) => state.trikes);

  // const { allLocations } = useSelector((state) => state.locations);

  // const [open, setOpen] = useState(false);

  // const getTrikes = (hub) => {
  //   return allTrikes?.filter((trike) => trike.hubId == hub.id).length || 0;
  // };

  return (
    <div className="flex gap-x-4 items-end absolute bottom-6 left-4 z-20 right-4 max-h-max">
      {/* <motion.div
        className="flex w-full rounded-md bg-white px-6 py-2"
        initial={{ height: open ? "100%" : "40px" }}
        animate={{ height: open ? "100%" : "40px" }}
        transition={{ duration: 0.2 }}
      >
        <div className="w-[50%] transition-all">
          <p className="text-base font-medium text-black font-poppins">
            Locations
          </p>

          {open && (
            <motion.div className="mt-1 flex flex-col gap-y-2">
              {allLocations &&
                allLocations
                  ?.filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
                  ?.map((location, idx) => (
                    <div key={idx} className="max-h-16">
                      <div className="flex items-center justify-start py-2 gap-x-3">
                        <CustomMapMarker
                          icon={getColorAndIcon(1).icon}
                          colors={getColorAndIcon(1).colors}
                        />
                        <div>
                          <p className="text-base font-medium text-black font-poppins">
                            {location?.name}
                          </p>

                          <div className="mt-1.5 flex items-center gap-x-2">
                            <p className="font-aileron text-xs font-normal text-dark-gray">
                              Hub Station
                            </p>

                            <div className="w-[1px] h-4 bg-light-grey" />

                            <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text">
                              <span className="font-medium">
                                {getTrikes(location)}{" "}
                              </span>
                              Trikes Available
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </motion.div>
          )}
        </div>

        <Button
          onClick={() => setOpen(!open)}
          padding="arrows"
          size="arrows"
          colors="grey"
        >
          <ChevronIcon
            className={`w-4 h-4 transition-all ${
              open ? "rotate-90" : "-rotate-90"
            } stroke-black`}
          />
        </Button>
      </motion.div> */}
      <div className="flex-1" />

      <div className="flex flex-col h-full gap-y-3">
        <RelocateButton
          mapRef={mapRef}
          initialZoomLevel={initialZoomLevel}
          center={center}
        />
        <ZoomButton mapRef={mapRef} />
      </div>
    </div>
  );
};

export const TrikeDetailsDrawer = ({
  mapRef,
  initialZoomLevel,
  center,
  setViewDetailsDrawer,
}) => {
  const { currentTrike } = useSelector((state) => state.trikes);

  const onTripsClick = () => {
    setViewDetailsDrawer(true);
  };

  return (
    <div className="absolute left-0 right-0 z-20 w-full bottom-6">
      <Row gutter={16}>
        <Col span={21}>
          <div className="p-3 ml-6 bg-white rounded-md h-27">
            <div className="flex justify-between">
              <div className="flex items-center gap-x-2">
                <div className="text-base font-medium capitalize font-poppins text-new-black">
                  {currentTrike.driverFirstName
                    ? `${currentTrike.driverFirstName} ${currentTrike.driverLastName}`
                    : "--"}
                </div>
                <VerifyIcon />
              </div>
              {currentTrike?.currentDriverId ? (
                <button
                  type="button"
                  className="flex items-center px-2 py-1 gap-x-2"
                  onClick={onTripsClick}
                >
                  <p className="text-xs font-medium font-poppins text-primary">
                    Trips
                  </p>
                  <ChevronIcon className="w-4 h-4 stroke-primary" />
                </button>
              ) : null}
            </div>

            <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
              Driver
            </div>

            <div className="flex gap-x-[67px] mt-3">
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Distance
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {currentTrike.distance ? currentTrike?.distance : "--"} Km
                </div>
              </div>
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Current Speed
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {currentTrike?.currentSpeed || "--"} Km/H
                </div>
              </div>
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Avg. Speed
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {currentTrike?.averageSpeed || "--"} Km/H
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="flex flex-col gap-y-3">
            <RelocateButton
              mapRef={mapRef}
              initialZoomlevel={initialZoomLevel}
              center={center}
            />
            <ZoomButton mapRef={mapRef} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const BatteryDetailsDrawer = ({ mapRef, initialZoomLevel, center }) => {
  const { currentBattery } = useSelector((state) => state.batteries);

  return (
    <div className="absolute left-0 right-0 z-20 w-full bottom-6">
      <Row gutter={16} className="flex items-end">
        <Col span={21}>
          <div className="h-16 p-3 ml-6 bg-white rounded-md">
            <div className="text-base font-medium font-poppins text-new-black">
              {currentBattery?.trikeRegistrationNo || "--"}
            </div>
            <div className="flex items-center">
              <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text capitalize">
                {currentBattery.driverFirstName
                  ? `${currentBattery.driverFirstName} ${currentBattery.driverLastName}`
                  : "Moses Sambaza"}
              </div>
              <Divider type="vertical" className="bg-light-grey h-2.5 mt-0.5" />
              <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                20 Min Ago
              </div>
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="flex flex-col gap-y-3">
            <RelocateButton
              mapRef={mapRef}
              initialZoomlevel={initialZoomLevel}
              center={center}
            />
            <ZoomButton mapRef={mapRef} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const TripDetailsDrawer = ({ mapRef, initialZoomLevel, center }) => {
  const { currentTrip } = useSelector((state) => state.trips);
  const { currentTrike } = useSelector((state) => state.trikes);

  return (
    <div className="absolute left-0 right-0 z-20 w-full bottom-6">
      <Row gutter={16}>
        <Col span={21}>
          <div className="p-3 ml-6 bg-white rounded-md h-27">
            <div className="flex items-center gap-x-2">
              <div className="text-base font-medium text-black capitalize font-poppins">
                {currentTrip.id}
              </div>
            </div>
            <div className="flex gap-x-[67px] mt-3">
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Distance
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium mt-1">
                  {currentTrip.distance
                    ? `${parseFloat(currentTrip.distance).toFixed(2)} Km`
                    : "--"}
                </div>
              </div>
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Current Speed
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium mt-1">
                  {currentTrike?.currentSpeed || "--"} Km/H
                </div>
              </div>
              <div>
                <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
                  Avg. Speed
                </div>
                <div className="font-poppins text-xs leading-4.5 font-medium mt-1">
                  {currentTrike?.averageSpeed || "--"} Km/H
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="flex flex-col gap-y-3">
            <RelocateButton
              mapRef={mapRef}
              initialZoomlevel={initialZoomLevel}
              center={center}
            />
            <ZoomButton mapRef={mapRef} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TrikeMapMarkerDetails;
