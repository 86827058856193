import React from "react";
import CustomTags from "../Core/Components/CustomTags";
import TrikeIcon from "../Common/Svgs/TrikeIcon";
import Button from "../Core/Components/CustomButton";
import SwitchIcon from "../Common/Svgs/switch";
import ServiceIcon from "../Common/Svgs/ServiceIcon";
import HubStationIcon from "../Common/Svgs/HubStationIcon";
import BatteryInTransitIcon from "../Common/Svgs/BatteryInTransitIcon";

const getBatteryStatus = (batteryStatus) => {
  switch (batteryStatus) {
    case 1:
      return (
        <CustomTags colors="onATrip" background="onATrip">
          <div className="flex items-center gap-x-2">
            <TrikeIcon size={16} className="stroke-yellow-dark fill-none" />
            <p>On A Trip</p>
          </div>
        </CustomTags>
      );
    case 2:
      return (
        <CustomTags colors="batteryDispatched" background="batteryDispatched">
          <div className="flex items-center gap-x-2">
            <TrikeIcon size={16} className="stroke-[#107F6C] fill-none" />
            <p>Dispatched</p>
          </div>
        </CustomTags>
      );
    case 3:
      return (
        <CustomTags colors="hubStation" background="hubStation">
          <div className="flex items-center gap-x-2">
            <HubStationIcon className=" stroke-black fill-none" />
            <p>Hub Station</p>
          </div>
        </CustomTags>
      );
    case 4:
      return (
        <CustomTags colors="batteryService">
          <div className="flex items-center gap-x-2">
            <ServiceIcon className=" stroke-new-black fill-none w-4 h-4" />
            <p>In Service</p>
          </div>
        </CustomTags>
      );

    case 5:
      return (
        <CustomTags colors="batteryInTransit">
          <div className="flex items-center gap-x-2">
            <BatteryInTransitIcon />
            <p>In Transit</p>
          </div>
        </CustomTags>
      );
    default:
      return (
        <CustomTags colors="inATrike" background="inATrike">
          <div className="flex items-center gap-x-2">
            <TrikeIcon size={16} className="stroke-yellow-dark fill-none" />
            <p>In A Trike</p>
          </div>
        </CustomTags>
      );
  }
};

export const getTrikeStatus = (buttonProps) => {
  return (
    <CustomTags
      colors={buttonProps?.colors}
      padding={buttonProps?.primary}
      size={buttonProps?.size}
    >
      <div className="flex items-center gap-x-2">{buttonProps?.children}</div>
    </CustomTags>
  );
};

export const getMobiliseStatus = (id) => {
  switch (id) {
    case 1:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="light-alert"
          padding="mobilize"
        >
          <div className="flex items-center justify-start gap-x-2">
            <SwitchIcon className="stroke-[#C84630] fill-none" />
            <div className="text-xs font-medium font-poppins leading-4.5">
              Immobilise
            </div>
          </div>
        </Button>
      );
    case 2:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="mobilizePrimary"
          padding="mobilize"
        >
          <div className="flex items-center justify-start gap-x-2">
            <SwitchIcon className="stroke-primary-green fill-none" />
            <div className="text-xs font-medium font-poppins leading-4.5">
              Mobilise
            </div>
          </div>
        </Button>
      );
    default:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="mobilizePrimary"
          padding="mobilize"
        >
          <div className="flex items-center justify-start gap-x-2">
            <SwitchIcon className="stroke-primary-green fill-none" />
            <div className="text-xs font-medium font-poppins leading-4.5">
              Mobilise
            </div>
          </div>
        </Button>
      );
  }
};

export const getUserRole = (roleProps) => {
  return (
    <CustomTags
      colors={roleProps?.colors}
      padding={roleProps?.primary}
      size={roleProps?.size}
    >
      {roleProps?.children}
    </CustomTags>
  );
};

export default getBatteryStatus;
