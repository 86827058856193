/* eslint-disable no-nested-ternary */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import getTripStatus from "../../../Utils/getTripStatus";
import EditAndDeleteDropDown from "../EditAndDeleteDropDown";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import FarmerBadge from "./FarmerBadge";
import { TRIP_STATUS } from "../Data/TripConstants";

const TripDetailsHeader = () => {
  const { stopId, farmerId } = useParams();
  const navigate = useNavigate();

  const { currentTrip, currentStop, currentFarmer, overallSummaryForTrip } =
    useSelector((state) => state.trips);

  const onHeaderClick = () => {
    navigate(-1);
  };

  const getHeader = () => {
    if (farmerId) {
      return (
        <div className="flex items-center">
          <button
            type="button"
            className="flex items-center gap-x-2"
            onClick={onHeaderClick}
          >
            <ChevronIcon className="w-5 h-5 stroke-dark-gray rotate-180" />
            <p className="font-poppins text-base font-medium">
              {currentFarmer.name}
            </p>
          </button>

          {currentFarmer.isNewFarmer ? (
            <div className="ml-2">
              <FarmerBadge type={1} text="New Entry" />
            </div>
          ) : currentFarmer?.farmerStop ? (
            <div className="ml-2">
              <FarmerBadge
                type={2}
                text={`Farmer originally from Stop ${currentFarmer.farmerStopName}`}
              />
            </div>
          ) : null}
        </div>
      );
    }
    if (stopId) {
      return (
        <button
          type="button"
          className="flex items-center gap-x-2"
          onClick={onHeaderClick}
        >
          <ChevronIcon className="w-5 h-5 stroke-dark-gray rotate-180" />
          <p className="font-poppins text-base font-medium">
            {currentStop?.stopName || "--"}
          </p>
          <div className="bg-primary-10 py-1 px-2 max-w-max rounded font-poppins text-xs font-medium text-primary">
            Stop {currentStop?.index}
          </div>
        </button>
      );
    }

    return (
      <div className="flex items-center gap-x-3">
        <p className="text-base font-medium font-poppins text-new-black">
          Overview
        </p>

        {currentTrip?.statusId === TRIP_STATUS.COMPLETED &&
        overallSummaryForTrip?.forceEnd ? (
          <div className="py-0.5 px-2 rounded max-w-max bg-yellow-dark">
            <p className="font-poppins text-xs font-normal text-background-white">
              Trip completed by {overallSummaryForTrip?.forceEndFirstName}{" "}
              {overallSummaryForTrip?.forceEndLastName}
            </p>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="flex items-center justify-between px-6 border-b border-r bg-background-white border-light-grey h-14">
      {getHeader()}
      <div className="flex items-center justify-end gap-x-3">
        <div className="flex items-center gap-x-3">
          {getTripStatus(currentTrip?.statusId)}

          <EditAndDeleteDropDown
            tripId={currentTrip.id}
            detail={currentTrip}
            listingPage={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TripDetailsHeader;
