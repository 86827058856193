/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-expressions */
import React from "react";

import CustomTooltip from "../../../Core/Components/CustomTooltip";
import getBatteryStatus from "../../../Utils/getBatteryStatus";
import BatteryIcon from "../../../Common/Svgs/BatteryIcon";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import ServiceIcon from "../../../Common/Svgs/ServiceIcon";
import ListDropdown from "../phase3/ListDropdown";
import EditIcon from "../../../Common/Svgs/EditIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import ViewDetailsIcon from "../../../Common/Svgs/ViewDetailsIcon";
import AddToServiceIcon from "../../../Common/Svgs/AddToServiceIcon";
import SubmittedDetails from "../../../Core/Components/SubmittedDetails";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import BatteryPercentageStatus from "../phase3/BatteryPercentageStatus";
import { CHARGER_STATUS } from "../../Charger/Data/constants";
import AssociatedWithColumn from "./AssociatedWithColumn";

export const batteriesColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Registration no.
      </div>
    ),
    dataIndex: "registrationNo",
    width: "140px",

    sorter: (a, b) => a.registrationNo.localeCompare(b.registrationNo),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black group-hover:underline group-hover:text-black">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Tag number
      </div>
    ),
    dataIndex: "tagNo",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text || "--"}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Battery Type
      </div>
    ),
    sorter: (a, b) => a?.type?.localeCompare(b?.type),
    dataIndex: "type",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Current Hub
      </div>
    ),
    dataIndex: "currentHubName",
    sorter: (a, b) => a?.currentHubName?.localeCompare(b?.currentHubName),
    width: "140px",
    render: (text) => (
      <div>
        <CustomTooltip tooltipTitle={text} textColor="capitalize">
          <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
            {text.length > 15 ? `${text.substring(0, 15)}...` : text}
          </div>
        </CustomTooltip>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Permanent Hub
      </div>
    ),
    width: "140px",
    dataIndex: "permanentHubName",
    sorter: (a, b) => a?.permanentHubName?.localeCompare(b?.permanentHubName),
    render: (text) => (
      <div>
        <CustomTooltip tooltipTitle={text} textColor="capitalize">
          <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
            {text.length > 15 ? `${text.substring(0, 15)}...` : text}
          </div>
        </CustomTooltip>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "status",
    width: "170px",
    render: (text) => getBatteryStatus(text),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Associated With
      </div>
    ),
    width: "150px",
    dataIndex: "id",
    render: (text, record) => <AssociatedWithColumn record={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Battery %
      </div>
    ),
    dataIndex: "percentage",
    // width: "150px",
    sorter: (a, b) => a.percentage - b.percentage,
    render: (text, record) => (
      <div>
        <BatteryPercentageStatus
          detail={record}
          isCharging={record.chargingStatus === CHARGER_STATUS.CHARGING}
        />
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Driver
      </div>
    ),
    dataIndex: "driverFirstName",
    sorter: (a, b) => a?.driverFirstName?.localeCompare(b?.driverFirstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text ? `${text} ${record.driverLastName}` : "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date of Entry
      </div>
    ),
    dataIndex: "submittedBy",
    sorter: (a, b) => a?.submittedAt?.localeCompare(b?.submittedAt),
    render: (text, record) => (
      <div className="whitespace-nowrap">
        <SubmittedDetails record={record} truncate />
      </div>
    ),
  },
  {
    dataIndex: "id",
    width: "80px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="w-full flex items-center justify-center invisible group-hover:visible"
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];

export const BatteryKPIData = (kpiData, navigate, callBack) => {
  const hasServicePermission = usePermission([
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SERVICE_VIEW_AND_EDIT,
  ]);

  return [
    {
      id: 1,
      title: "No. of Batteries in Hub Station",
      count: kpiData.batteriesInHubStation || 0,
      total: kpiData.total,
      image: <BatteryIcon className="w-4 h-4 fill-none stroke-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 2,
      title: "No. of Batteries in trikes",
      count: kpiData.batteriesInTrike || 0,
      total: kpiData.total,
      image: <TrikeIcon size={16} className="text-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 3,
      title: "No. of Batteries in Service",
      count: kpiData.batteriesInService || 0,
      image: <ServiceIcon className="w-4 h-4 fill-none stroke-kpi-icon" />,
      icon: (
        <img
          src="/assets/icons/chevron-down.svg"
          className="-rotate-90 "
          alt="maintainance"
        />
      ),
      onClick: () => {
        if (hasServicePermission) {
          navigate("/service");
          callBack();
        }
      },
    },
  ];
};

export const batteriesDropdownBtnArr = [
  {
    title: "Battery %",
    renderBody: "Battery %",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "percentage",
  },
  {
    title: "Driver",
    renderBody: "Driver",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "drivers",
  },
  {
    title: "Status",
    renderBody: "BatteryStatus",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "status",
  },
];

export const chargerTypeOptions = [
  {
    id: 1,
    value: "Canbus",
  },
  {
    id: 2,
    value: "Non-Canbus",
  },
];

export const batteryTypeOptions = [
  {
    id: 1,
    value: "acceleron",
  },
  {
    id: 2,
    value: "fjshoan",
  },
];

export const threeDotsOptions = [
  {
    id: 1,
    value: "View Details",
    icon: <ViewDetailsIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 2,
    value: "Edit Details",
    icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 3,
    value: "Add to Service",
    icon: (
      <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
    ),
  },
  {
    id: 4,
    value: "Delete Battery",
    icon: <TrashIcon className="text-alert-red" />,
  },
];
