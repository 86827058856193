import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Core/Components/CustomButton";

const DriverUnavailableIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        fill="none"
        viewBox="0 0 42 42"
      >
        <path
          stroke="#DC553E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M34.125 33.25h-7"
        />
        <path
          fill="#C84630"
          stroke="#C84630"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M21.26 19.023a3 3 0 0 0-.577 0c-4.165-.14-7.472-3.553-7.472-7.753a7.764 7.764 0 0 1 7.77-7.77 7.775 7.775 0 0 1 7.77 7.77c-.018 4.2-3.325 7.613-7.49 7.753"
        />
        <path
          stroke="#C84630"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M20.983 38.167c-3.185 0-6.353-.805-8.768-2.415-4.235-2.835-4.235-7.455 0-10.272 4.813-3.22 12.705-3.22 17.518 0"
        />
      </svg>
    </span>
  );
};

const TrikeUnavailableIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        fill="none"
        viewBox="0 0 42 42"
      >
        <path
          stroke="#C84630"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M24.5 8.75V21a3.51 3.51 0 0 1-3.5 3.5H1.75v-7"
        />
        <path
          stroke="#C84630"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M36.75 31.5 35 21h-5.25m3.5 14h-17.5a3.51 3.51 0 0 0-3.5-3.5 3.51 3.51 0 0 0-3.5 3.5h-7V24.5H21a3.51 3.51 0 0 0 3.5-3.5m0-12.25h-5.25H35"
        />
        <path
          stroke="#C84630"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12.25 38.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M36.75 38.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M5.25 19.25 10.5 14 5.25 8.75M15.75 19.25 10.5 14l5.25-5.25"
        />
      </svg>
    </span>
  );
};

const BatteryUnavailableIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        stroke="#F18725"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M35.875 16.625c2.625 0 2.625.875 2.625 2.625v3.5c0 1.75 0 2.625-2.625 2.625M7 37.625l21-33.25M12.25 8.75c-7 0-8.75 1.75-8.75 8.75v7c0 5.95 1.26 8.102 5.932 8.61M22.75 33.25c7 0 8.75-1.75 8.75-8.75v-7c0-5.932-1.26-8.102-5.898-8.61"
      />
    </svg>
  );
};

const DriverEmptyState = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-[calc(100vh-287px)]">
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center bg-alert-10 w-[84px] h-[84px] rounded-full">
          <DriverUnavailableIcon />
        </div>

        <h1 className="mt-6 font-poppins text-base font-medium capitalize">
          Driver not available
        </h1>

        <p className="mt-2 font-poppins text-xs font-light text-center mb-4">
          Uh oh, drivers are not available at the moment to create a trip,
          <br />
          please check again later.
        </p>

        <Button width="dynamic" onClick={() => navigate("/trips")}>
          <p>Back To Trips</p>
        </Button>
      </div>
    </div>
  );
};

const TrikeEmptyState = () => {
  return (
    <div className="flex items-center justify-center h-[calc(100vh-287px)]">
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center bg-alert-10 w-[84px] h-[84px] rounded-full">
          <TrikeUnavailableIcon />
        </div>

        <h1 className="mt-6 font-poppins text-base font-medium capitalize">
          Trikes not available
        </h1>

        <p className="mt-2 font-poppins text-xs font-light text-center mb-4">
          Uh oh, trikes are not available at the moment,
          <br />
          please check again later.
        </p>
      </div>
    </div>
  );
};

const BatteryEmptyState = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-[calc(100vh-287px)]">
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center bg-warning-10 w-[84px] h-[84px] rounded-full">
          <BatteryUnavailableIcon />
        </div>

        <h1 className="mt-6 font-poppins text-base font-medium capitalize">
          Batteries not available
        </h1>

        <p className="mt-2 font-poppins text-xs font-light text-center">
          Uh-oh, batteries are not available at the moment, please check
          <br /> again later or send a request to your nearest hub station.
        </p>

        <button
          type="button"
          className="mt-6 px-4 py-[11px] bg-yellow hover:bg-yellow-dark rounded-md"
          onClick={() => navigate("/request-batteries")}
        >
          <div className="flex item-center gap-x-2">
            <img
              src="/assets/icons/request-battery.svg"
              className="w-4 h-4"
              alt=""
            />

            <p className="font-poppins text-xs leading-4.5 font-medium text-black">
              Request Batteries
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};

export { DriverEmptyState, TrikeEmptyState, BatteryEmptyState };
