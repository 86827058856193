/* eslint-disable no-plusplus */
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Divider, Popover } from "antd";
import dayjs from "dayjs";
import Button from "../../../Core/Components/CustomButton";
import CustomInput from "../../../Core/Components/CustomInput";
import CustomSearchAndSelect from "../../../Core/Components/CustomSearchAndSelect";
import CloseIcon from "../../../Common/Svgs/CloseIcon";

const MoreTrikeFilters = ({ filters, setFilters, drivers }) => {
  const { allSystemUsers } = useSelector((state) => state.loggedInUser);

  const [open, setOpen] = useState(false);
  const [finalAddedByList, setFinalAddedByList] = useState([]);
  const [finalDriverList, setFinalDriverList] = useState([]);
  const [moreFilters, setMoreFilters] = useState({
    dateRangeStart: "",
    dateRangeEnd: "",
    addedBy: [],
    driver: [],
    distance: "",
    mileageStart: "",
    mileageEnd: "",
    averageSpeedStart: "",
    averageSpeedEnd: "",
  });

  const driversList = drivers
    ?.map((item) => {
      return { id: item.id, name: `${item.firstName} ${item.lastName}` };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const addedByList = allSystemUsers
    ?.map((user) => {
      return { id: user.id, name: `${user.firstName} ${user.lastName}` };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const filterCount = useMemo(() => {
    let count = 0;
    if (moreFilters.dateRangeStart && moreFilters.dateRangeEnd) count++;
    if (moreFilters.averageSpeedStart && moreFilters.averageSpeedEnd) count++;
    if (moreFilters.mileageStart && moreFilters.mileageEnd) count++;
    if (moreFilters.distance) count++;
    if (moreFilters.driver?.length > 0) count++;
    if (moreFilters.addedBy?.length > 0) count++;
    return count;
  }, [moreFilters]);

  const hasFieldValues = () => {
    return (
      moreFilters?.addedBy?.length > 0 ||
      moreFilters?.driver?.length > 0 ||
      moreFilters?.distance !== "" ||
      (moreFilters?.mileageStart !== "" && moreFilters?.mileageEnd !== "") ||
      (moreFilters?.averageSpeedStart !== "" &&
        moreFilters?.averageSpeedEnd !== "") ||
      (moreFilters?.dateRangeStart !== "" && moreFilters?.dateRangeEnd !== "")
    );
  };

  const handleDateRangeChange = (e) => {
    if (e && e.length === 2 && e[0] && e[1]) {
      setMoreFilters({
        ...moreFilters,
        dateRangeStart: e[0].format("YYYY-MM-DD"),
        dateRangeEnd: e[1].format("YYYY-MM-DD"),
      });
    }
  };

  const handleClear = () => {
    setFinalAddedByList([]);
    setFinalDriverList([]);
    setMoreFilters({
      dateRangeStart: "",
      dateRangeEnd: "",
      addedBy: [],
      driver: [],
      distance: "",
      mileageStart: "",
      mileageEnd: "",
      averageSpeedStart: "",
      averageSpeedEnd: "",
    });
    setFilters({
      ...filters,
      dateRangeStart: "",
      dateRangeEnd: "",
      addedBy: [],
      driver: [],
      distance: "",
      mileageStart: "",
      mileageEnd: "",
      averageSpeedStart: "",
      averageSpeedEnd: "",
    });
  };

  const handleApply = () => {
    setFilters({ ...filters, ...moreFilters });
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div className="h-[618px] max-h-[50vh] overflow-y-auto bg-background-white w-78 rounded-md p-3 shadow-moreFilters">
          <div className="flex items-center justify-between">
            <div className="text-base font-medium text-black font-poppins">
              More filters
            </div>
            <CloseIcon
              className="w-5 h-5 cursor-pointer stroke-dark-gray"
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="mt-6">
            <div className="font-normal font-poppins text-xs leading-[14.4px] text-dark-gray mb-2">
              Date Range
            </div>
            <DatePicker.RangePicker
              value={[
                moreFilters.dateRangeStart !== "" &&
                  dayjs(moreFilters.dateRangeStart),
                moreFilters.dateRangeEnd !== "" &&
                  dayjs(moreFilters.dateRangeEnd),
              ]}
              format="DD MMM YYYY"
              separator={<div className="text-dark-gray"> - </div>}
              onCalendarChange={(e) => handleDateRangeChange(e)}
              allowClear={false}
              className="h-[39px]"
              disabledDate={(current) => current.isAfter(dayjs())}
            />

            <div className="font-normal font-poppins text-xs leading-[14.4px] text-dark-gray mt-6">
              Added By
            </div>
            <CustomSearchAndSelect
              placeholder="Type or Select User"
              inputList={addedByList}
              finalList={finalAddedByList}
              setFinalList={setFinalAddedByList}
              onChange={(e) => setMoreFilters({ ...moreFilters, addedBy: e })}
            />
          </div>
          <div className="font-normal font-poppins text-xs leading-[14.4px] text-dark-gray mt-6">
            Driver
          </div>
          <CustomSearchAndSelect
            placeholder="Select Driver"
            inputList={driversList}
            finalList={finalDriverList}
            setFinalList={setFinalDriverList}
            onChange={(e) => setMoreFilters({ ...moreFilters, driver: e })}
          />

          <div className="font-normal font-poppins text-xs leading-[14.4px] text-dark-gray mt-6">
            Distance (Km)
          </div>
          <div className="pt-1.5">
            <CustomInput
              intent="primary"
              value={moreFilters.distance}
              border={moreFilters.distance ? "success" : "primary"}
              placeholder="Enter Value"
              onChange={(e) =>
                setMoreFilters({ ...moreFilters, distance: e.target.value })
              }
            />
          </div>

          <div className="font-normal font-poppins text-xs leading-[14.4px] text-dark-gray mt-6">
            Mileage (Km)
          </div>
          <div className="flex items-center justify-center gap-x-2">
            <CustomInput
              intent="primary"
              border={moreFilters.mileageStart ? "success" : "primary"}
              value={moreFilters.mileageStart}
              placeholder="Enter Value"
              onChange={(e) =>
                setMoreFilters({ ...moreFilters, mileageStart: e.target.value })
              }
            />
            <div className="w-6">
              <Divider className="" />
            </div>
            <CustomInput
              intent="primary"
              border={moreFilters.mileageEnd ? "success" : "primary"}
              placeholder="Enter Value"
              value={moreFilters.mileageEnd}
              onChange={(e) =>
                setMoreFilters({ ...moreFilters, mileageEnd: e.target.value })
              }
            />
          </div>
          <div className="font-normal font-poppins text-xs leading-[14.4px] text-dark-gray mt-6">
            Average Speed (Km/H)
          </div>
          <div className="flex items-center justify-center gap-x-2">
            <CustomInput
              intent="primary"
              border={moreFilters.averageSpeedStart ? "success" : "primary"}
              placeholder="Enter Value"
              value={moreFilters.averageSpeedStart}
              onChange={(e) =>
                setMoreFilters({
                  ...moreFilters,
                  averageSpeedStart: e.target.value,
                })
              }
            />
            <div className="w-6">
              <Divider className="" />
            </div>
            <CustomInput
              intent="primary"
              value={moreFilters.averageSpeedEnd}
              border={moreFilters.averageSpeedEnd ? "success" : "primary"}
              placeholder="Enter Value"
              onChange={(e) =>
                setMoreFilters({
                  ...moreFilters,
                  averageSpeedEnd: e.target.value,
                })
              }
            />
          </div>

          <div className="flex mt-6 gap-x-3">
            <Button
              size="primary"
              width="save"
              colors="grey"
              modifier={hasFieldValues() ? null : "blur"}
              disabled={!hasFieldValues()}
              onClick={() => {
                setOpen(false);
                handleClear();
              }}
            >
              Clear All
            </Button>
            <Button
              size="primary"
              width="save"
              type="submit"
              modifier={hasFieldValues() ? null : "blur"}
              disabled={!hasFieldValues()}
              onClick={() => {
                setOpen(false);
                handleApply();
              }}
            >
              Apply Filters
            </Button>
          </div>
        </div>
      }
    >
      {filterCount > 0 ? (
        <div className="flex items-center gap-x-3">
          <button
            type="button"
            className="px-3 py-[11px] bg-green rounded-md flex items-center gap-x-3 h-10"
          >
            <p className="font-poppins text-xs text-background-white">
              Filters
            </p>
            <div className="w-4.5 h-4.5 rounded-full flex items-center justify-center bg-background-white">
              <p className="text-10 font-aileron text-black font-semibold">
                {filterCount}
              </p>
            </div>
          </button>
          <button
            type="button"
            className="px-3 py-[11px] rounded-md hover:bg-background h-10"
            onClick={handleClear}
          >
            <p className="font-poppins text-xs text-black font-medium">
              Clear All
            </p>
          </button>
        </div>
      ) : (
        <button
          type="button"
          className="flex items-center gap-x-3 px-3 py-[11px] hover:bg-background rounded-md h-10"
        >
          <img src="/assets/icons/sort.svg" alt="Filters" />
          <p className="font-poppins text-xs leading-5 font-medium text-dark-gray">
            More Filters
          </p>
        </button>
      )}
    </Popover>
  );
};

export default MoreTrikeFilters;
