import React from "react";
import dayjs from "dayjs";
import CustomTooltip from "../../../Core/Components/CustomTooltip";
import getSeveritytext from "../../../Utils/getSeverityText";
import ListDropdown from "../phase3/ListDropdown";
import { SERVICE_ASSET_TYPE } from ".";
import SubmittedDetails from "../../../Core/Components/SubmittedDetails";

export const renderSeverityTextColor = (severity) => {
  switch (severity) {
    case 1:
      return "text-dark-grey-text";
    case 2:
      return "text-light-blue";
    case 3:
      return "text-warning";
    case 4:
      return "text-alert-red";
    default:
      return "text-dark-grey-text";
  }
};

export const serviceColumns = [
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Asset Number & Type
      </div>
    ),
    dataIndex: "assetRegistrationNo",
    sorter: (a, b) =>
      a.assetRegistrationNo.localeCompare(b.assetRegistrationNo),

    render: (text, record) => (
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
          {text}
        </div>
        <div className="font-normal text-10 font-aileron leading-3.75 text-dark-gray capitalize">
          {record.assetType === SERVICE_ASSET_TYPE.TRIKE ? "Trike" : "Battery"}
        </div>
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Issue Description
      </div>
    ),
    dataIndex: "description",
    render: (text) => (
      <CustomTooltip
        tooltipTitle={text}
        bgColor="#fff"
        textColor="text-dark-gray"
        placement="bottomLeft"
        padding="8px"
        trigger="click"
      >
        <div className="flex items-center cursor-pointer gap-x-1">
          <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
            View Description
          </div>
          <img src="/assets/icons/chevron-down.svg" alt="" />
        </div>
      </CustomTooltip>
    ),
  },

  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Hub Station
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a.hubName.localeCompare(b.hubName),
    render: (text) => (
      <p className="text-xs font-aileron font-semibold leading-3.75 text-dark-gray capitalize">
        {text || "--"}
      </p>
    ),
  },
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Severity
      </div>
    ),
    dataIndex: "severity",
    sorter: (a, b) => a.severity - b.severity,
    render: (text) => (
      <p
        className={`font-aileron text-xs leading-4.5 font-semibold capitalize
         ${renderSeverityTextColor(text)}`}
      >
        {getSeveritytext(text)}
      </p>
    ),
  },
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Date Of Completion
      </div>
    ),
    dataIndex: "isCompleted",
    sorter: (a, b) => a?.completionDate?.localeCompare(b?.completionDate),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text ? dayjs(record.completionDate).format("ddd, MMM DD YYYY") : "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Maintenance Report
      </div>
    ),
    dataIndex: "reportDescription",
    render: (text) => (
      <CustomTooltip
        tooltipTitle={text}
        bgColor="#fff"
        textColor="text-dark-gray"
        placement="bottomLeft"
        padding="8px"
        trigger="click"
      >
        <div className="flex items-center cursor-pointer gap-x-1">
          <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
            View Report
          </div>
          <img src="/assets/icons/chevron-down.svg" alt="" />
        </div>
      </CustomTooltip>
    ),
  },
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Date Of Entry
      </div>
    ),
    dataIndex: "submittedBy",
    sorter: (a, b) => a?.submittedAt.localeCompare(b?.submittedAt),
    render: (text, record) => (
      <div>
        <SubmittedDetails record={record} />
      </div>
    ),
  },
  {
    dataIndex: "assetNumber",
    width: "100px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="invisible group-hover:visible w-full flex items-center justify-center"
      >
        <ListDropdown selectedService={record} />
      </button>
    ),
  },
];

export const serviceDropdownBtnArr = [
  {
    title: "Type",
    renderBody: "Type",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "assetType",
  },
  {
    title: "Severity",
    renderBody: "Severity",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "severity",
  },
];
