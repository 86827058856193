/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../Core/Components/CustomTable";
import UserTableHeader from "./UserTableHeader";
import {
  getSystemRoles,
  getSystemUsers,
  getUserByEmail,
} from "../../../Redux/APIs/systemUsersAPI";
import ToastMessageModal from "../ToastMessageModal";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
// import systemUserColumns from "../Data/usersData";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import DeleteSystemUserModal from "./DeleteSystemUserModal";
import useDebounce from "../../../Core/hooks/useDebounce";
import UserIcon from "../../../Common/Svgs/UserIcon";
import { getAllHubs } from "../../../Redux/APIs/locationsAPI";
import { getTableContainerHeight } from "../../../Utils/getTableContainerHeight";
import AssignRoleDropdown from "./AssignRoleDropdown";
import AssignHubStationDropdown from "./AssignHubStationDropdown";
import { DeleteAction } from "./EditAndDeleteDropDown";

const LOCAL_DEFAULT_LIMIT = 10;

const UserSettings = () => {
  const dispatch = useDispatch();

  const { systemUsers, toastMessageModal, deleteSystemUserModal, userDetails } =
    useSelector((state) => state.loggedInUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    role: [],
  });

  const tableContainerRef = useRef(null);

  const debounceSearch = useDebounce(search, 500);

  const systemUserColumns = [
    {
      title: () => (
        <div className="font-light font-aileron text-10 text-dark-gray">
          First Name
        </div>
      ),
      width: "20%",
      dataIndex: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (text) => (
        <div className="text-xs font-semibold text-black font-aileron py-2 capitalize">
          {text?.trim()}
        </div>
      ),
    },
    {
      title: () => (
        <div className="font-light font-aileron text-10 text-dark-gray">
          Last Name
        </div>
      ),
      width: "20%",
      dataIndex: "lastName",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      render: (text) => (
        <div className="text-xs font-semibold text-dark-gray capitalize font-aileron">
          {text?.trim()}
        </div>
      ),
    },
    {
      title: () => (
        <div className="font-light font-aileron text-10 text-dark-gray">
          Email
        </div>
      ),
      width: "25%",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text) => (
        <div className="text-xs font-semibold text-dark-gray font-aileron">
          {text?.trim()}
        </div>
      ),
    },
    {
      title: () => (
        <div className="font-light font-aileron text-10 text-dark-gray">
          Role
        </div>
      ),
      width: "25%",
      dataIndex: "roleName",
      sorter: (a, b) => a.roleName.localeCompare(b.roleName),
      render: (text, record) => (
        <AssignRoleDropdown detail={record} setFilters={setFilters} />
      ),
    },
    {
      title: () => (
        <div className="font-light font-aileron text-10 text-dark-gray">
          Hub Station
        </div>
      ),
      width: "30%",
      dataIndex: "roleName",
      sorter: (a, b) => a.roleName.localeCompare(b.roleName),
      render: (text, record) => (
        <div className="whitespace-nowrap">
          <AssignHubStationDropdown detail={record} />
        </div>
      ),
    },
    {
      dataIndex: "id",
      width: "100px",
      render: (text, record) => <DeleteAction user={record} />,
    },
  ];

  // get system users
  useEffect(() => {
    const filterObj = {
      limit: LOCAL_DEFAULT_LIMIT,
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubs = filters.hub;
    if (filters.role.length) filterObj.roles = filters.role;

    dispatch(getSystemUsers(filterObj));
  }, [dispatch, currentPage, debounceSearch, filters]);

  // get system user roles
  useEffect(() => {
    dispatch(getSystemRoles());
  }, [dispatch]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
    dispatch(getAllHubs());
  }, [dispatch]);

  return (
    <div>
      <div>
        <h1 className="px-6 pt-6 text-xs font-medium text-black font-poppins">
          System Users
        </h1>
        <p className="mt-2 px-6 text-xs font-normal text-dark-gray font-aileron">
          Create login credentials for system users of this platform
        </p>

        <div className="mt-4 px-6">
          <UserTableHeader
            search={search}
            setSearch={setSearch}
            filters={filters}
            setFilters={setFilters}
            setCurrentPage={setCurrentPage}
          />

          <div
            className={`h-[calc(100vh-72px-69px-24px-16px-8px-16px-16px-61px-16px-40px)] ${
              systemUsers.result.length ? "overflow-y-scroll" : ""
            }`}
          >
            <CustomTable
              // scrollX={0}
              disableScroll
              columns={systemUserColumns}
              data={systemUsers.result}
              rowClassName="group"
              emptyState={
                <UserIcon size={16} className="stroke-dark-gray fill-none" />
              }
              emptyStateHeight={getTableContainerHeight(tableContainerRef)}
              pagination={{
                pageSize: LOCAL_DEFAULT_LIMIT,
                total: systemUsers.totalRecords,
                showSizeChanger: false,
                onChange: (page) => {
                  setCurrentPage(page);
                },
                current: currentPage,
                itemRender: (current, type, originalElement) => {
                  const lastPage = systemUsers.totalRecords / current;

                  if (type === "prev" && current === 0) {
                    return (
                      <button
                        type="button"
                        className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                        style={{ pointerEvents: "none" }}
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "prev" && current > 0) {
                    return (
                      <button
                        type="button"
                        className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "next" && lastPage <= 10) {
                    return (
                      <button
                        type="button"
                        className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                        style={{
                          pointerEvents: "none",
                        }}
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "next") {
                    return (
                      <button
                        type="button"
                        className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  return originalElement;
                },
              }}
            />
          </div>
        </div>
      </div>

      <ToastMessageModal
        visible={toastMessageModal?.visible}
        onCancel={() =>
          dispatch(
            updateLoggedInUser({
              key: "toastMessageModal",
              value: { ...toastMessageModal, visible: false },
            }),
          )
        }
      />

      <DeleteSystemUserModal
        visible={deleteSystemUserModal?.visible}
        onCancel={() =>
          dispatch(
            updateLoggedInUser({
              key: "deleteSystemUserModal",
              value: { visible: false, user: null },
            }),
          )
        }
      />
    </div>
  );
};

export default UserSettings;
