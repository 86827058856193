/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { addFarmerV3, editFarmerV3 } from "../../../Redux/APIs/usersAPI";
import { PARTNER_ROLE, farmerRoleOptions } from "../Data";
import useLocationOnAdd from "./useLocationOnAdd";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const AddFarmer = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();

  const { currentFarmer } = useSelector((state) => state.users);

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    clearErrors,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      firstName: id ? currentFarmer.firstName : "",
      lastName: id ? currentFarmer.lastName : "",
      uniqueId: id ? currentFarmer?.uniqueId : "",
      roleId: id ? currentFarmer.role : 2,
      roleValue: id
        ? currentFarmer.role === PARTNER_ROLE.FARMER
          ? "Farmer"
          : "Offtaker"
        : "",
      contactNumber: id ? currentFarmer.contactNo : "",
      collectionPointName: id ? currentFarmer.locationName : "",
      collectionPointId: id ? currentFarmer.locationId : "",
    },
  });

  const [focus, setFocus] = useState({
    firstName: false,
    lastName: false,
    uniqueId: false,
    contactNumber: false,
  });

  const rules = {
    required: "Required.",
  };

  const locationListForFarmers = useLocationOnAdd();

  const roleError = formState?.errors?.roleValue?.message;
  const collectionPointError = formState?.errors?.collectionPointName?.message;

  const onCancel = () => {
    navigate("/users?tab=partners");
  };

  const onAdd = async (e) => {
    const payload = e;

    if (id) {
      payload.farmerId = id;
      const editResponse = await dispatch(editFarmerV3(payload));

      if (
        editResponse?.payload?.response &&
        editResponse?.payload?.response?.status !== 200
      ) {
        setError("contactNumber", {
          type: "server",
          shouldFocus: true,
          message: editResponse?.payload?.response?.data?.message,
        });
      } else {
        reset();
        navigate("/users?tab=partners");
      }
    } else {
      const addResponse = await dispatch(addFarmerV3(payload));

      if (
        addResponse?.payload?.response &&
        addResponse?.payload?.response?.status !== 200
      ) {
        setError("contactNumber", {
          type: "server",
          shouldFocus: true,
          message: addResponse?.payload?.response?.data?.message,
        });
      } else {
        reset();
        navigate("/users?tab=partners");
      }
    }
  };

  return (
    <CustomLayout pageName="Users" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {id ? "Edit Partner" : "Add a new partner"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              {id ? "Save" : "Add"} Partner
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-[calc(100vh-88px-72px)] bg-background-white p-6 flex flex-col gap-y-6 overflow-y-scroll">
        <LabelAndInput
          label="First Name"
          control={control}
          formState={formState}
          name="firstName"
          placeholder="Enter Name"
          rules={rules}
          focus={focus.firstName}
          setFocus={(state) => setFocus({ ...focus, firstName: state })}
        />

        <LabelAndInput
          label="Last Name"
          control={control}
          formState={formState}
          name="lastName"
          placeholder="Enter Name"
          rules={rules}
          focus={focus.lastName}
          setFocus={(state) => setFocus({ ...focus, lastName: state })}
        />

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Role
          </p>
          <Controller
            name="roleValue"
            rules={rules}
            control={control}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                placeholder="Select Role"
                formState={formState}
                options={farmerRoleOptions}
                onSelect={(data) => {
                  setValue("roleId", data.id);
                  setValue("roleValue", data.value);
                  clearErrors("roleValue");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              roleError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {roleError}
          </p>
        </div>

        <LabelAndInput
          label="Unique ID"
          control={control}
          formState={formState}
          name="uniqueId"
          placeholder="Enter Unique Id"
          rules={rules}
          focus={focus.uniqueId}
          setFocus={(state) => setFocus({ ...focus, uniqueId: state })}
        />

        <LabelAndInput
          label="Contact Number"
          control={control}
          formState={formState}
          name="contactNumber"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.contactNumber}
          setFocus={(state) => setFocus({ ...focus, contactNumber: state })}
        />

        <div>
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Collection Point
          </p>
          <Controller
            name="collectionPointName"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Location"
                placeholder="Select Location"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={locationListForFarmers}
                onSelect={(data) => {
                  setValue("collectionPointName", data.value);
                  setValue("collectionPointId", data.id);
                  clearErrors("collectionPointName");
                }}
              />
            )}
          />
          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
              collectionPointError ? "text-alert-red visible" : "invisible"
            }`}
          >
            {collectionPointError}
          </p>
        </div>
      </div>
    </CustomLayout>
  );
};

export default AddFarmer;
