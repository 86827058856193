/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { addTrikeModel, addTrikeType } from "../Data";
import { addTrikeV3, editTrikeV3 } from "../../../Redux/APIs/trikesAPI";
import AlertIcon from "../../../Common/Svgs/AlertIcon";
import CalendarIcon from "../../../Common/Svgs/CalendarIcon";
import { LOCATION_TYPE } from "../../Location/Data";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="mt-6">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const AddTrike = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();

  const { currentTrike } = useSelector((state) => state.trikes);
  const { allLocations } = useSelector((state) => state.locations);

  const hubStations = allLocations
    ?.filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const { handleSubmit, control, formState, setValue, setError, clearErrors } =
    useForm({
      defaultValues: {
        registrationNo: id ? currentTrike.registrationNo : "",
        sensorNo: id ? currentTrike.sensorNo : "",
        chassisNo: id ? currentTrike.chassisNo : "",
        assetTag: id ? currentTrike.assetTag : "",
        type: id ? currentTrike.vehicleType.toUpperCase() : "EV",
        model: id ? currentTrike.modelType : "Dukar",
        hubId: id ? currentTrike?.hubId : "",
        hubName: id ? currentTrike?.hubName : "",
        insuranceValidity: id
          ? dayjs().isValid(currentTrike?.insuranceValidity)
            ? dayjs(currentTrike?.insuranceValidity)
            : null
          : "",
        totalCapacity: id ? currentTrike?.totalCapacity : null,
      },
    });

  const [focus, setFocus] = useState({
    registrationNo: false,
    sensorNo: false,
    chassisNo: false,
    assetTag: false,
    capacity: false,
    type: false,
    model: false,
    location: false,
  });

  const rules = {
    required: "Required.",
  };

  const rulesForCapacity = {
    required: "Required.",
    validate: {
      isNumber: (value) => {
        return value > 0 || "Please enter valid number";
      },
    },
  };

  const insuranceValidityError = formState?.errors?.insuranceValidity?.message;
  const hubNameError = formState?.errors?.hubName?.message;

  const onCancel = () => navigate("/trikes");

  const onAdd = async (e) => {
    const payload = e;

    if (id) {
      payload.trikeId = id;

      const editResponse = await dispatch(editTrikeV3(payload));

      if (
        editResponse?.payload?.response &&
        editResponse?.payload?.response?.status !== 200
      ) {
        setError("registrationNo", {
          type: "server",
          shouldFocus: true,
          message: editResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateTrikes({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Trike Edited",
                description: "1 trike was edited successfully.",
              },
            },
          }),
        );

        navigate("/trikes");
      }
    } else {
      const addResponse = await dispatch(addTrikeV3(payload));

      if (
        addResponse?.payload?.response &&
        addResponse?.payload?.response?.status !== 200
      ) {
        setError("registrationNo", {
          type: "server",
          shouldFocus: true,
          message: addResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateTrikes({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Trike Added",
                description: "1 new trike was added successfully.",
              },
            },
          }),
        );

        navigate("/trikes");
      }
    }
  };

  return (
    <CustomLayout pageName="Trikes" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {id ? " Edit trike" : "Add a new trike"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              {id ? "Save" : "Add"} Trike
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="flex items-center h-[calc(100vh-88px-72px)] p-6 bg-background-white overflow-y-scroll">
        <div className="flex-1 h-full">
          <p className="font-poppins text-xs font-medium capitalize">
            Add trike details
          </p>
          <LabelAndInput
            label="Trike Registration number"
            control={control}
            formState={formState}
            name="registrationNo"
            placeholder="Enter Number"
            rules={rules}
            focus={focus.registrationNo}
            setFocus={(state) => setFocus({ ...focus, registrationNo: state })}
          />

          <div className="mt-6">
            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
              Insurance Validity
            </p>

            <Controller
              name="insuranceValidity"
              rules={rules}
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  className={`font-aileron text-dark-gray font-semibold text-xs h-10 w-[367px] ${
                    insuranceValidityError &&
                    "border border-alert-red hover:border-alert-red"
                  }`}
                  placeholder="Select Date"
                  format="MMM DD, YYYY"
                  disabledDate={(current) =>
                    current.isBefore(dayjs().subtract(1, "day"))
                  }
                  suffixIcon={
                    insuranceValidityError ? (
                      <AlertIcon className="text-alert-red" />
                    ) : (
                      <CalendarIcon />
                    )
                  }
                  allowClear={false}
                />
              )}
            />

            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
                insuranceValidityError ? "text-alert-red visible" : "invisible"
              }`}
            >
              {insuranceValidityError}
            </p>
          </div>

          <LabelAndInput
            label="Sensor number"
            control={control}
            formState={formState}
            name="sensorNo"
            placeholder="Enter Number"
            rules={rules}
            focus={focus.sensorNo}
            setFocus={(state) => setFocus({ ...focus, sensorNo: state })}
          />

          <LabelAndInput
            label="Chassis number"
            control={control}
            formState={formState}
            name="chassisNo"
            placeholder="Enter Number"
            rules={rules}
            focus={focus.chassisNo}
            setFocus={(state) => setFocus({ ...focus, chassisNo: state })}
          />

          <LabelAndInput
            label="Asset Tag"
            control={control}
            formState={formState}
            name="assetTag"
            placeholder="Enter Number"
            rules={rules}
            focus={focus.assetTag}
            setFocus={(state) => setFocus({ ...focus, assetTag: state })}
          />

          <LabelAndInput
            label="Total Capacity"
            control={control}
            formState={formState}
            name="totalCapacity"
            placeholder="Enter Weight (Kg)"
            rules={rulesForCapacity}
            focus={focus.capacity}
            setFocus={(state) => setFocus({ ...focus, capacity: state })}
          />

          <div className="mt-6">
            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
              Type of Vehicle
            </p>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <PopoverDropdown
                  {...field}
                  formState={formState}
                  options={addTrikeType}
                  onSelect={(data) => setValue("type", data.value)}
                />
              )}
            />
          </div>

          <div className="mt-6">
            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
              Vehicle Model
            </p>
            <Controller
              name="model"
              control={control}
              render={({ field }) => (
                <PopoverDropdown
                  {...field}
                  formState={formState}
                  options={addTrikeModel}
                  onSelect={(data) => setValue("model", data.value)}
                  valueStyle={{
                    textTransform: "capitalize",
                  }}
                />
              )}
            />
          </div>

          <div className="mt-6 pb-6">
            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
              Hub Station
            </p>

            <Controller
              name="hubName"
              control={control}
              rules={rules}
              render={({ field }) => (
                <PopoverDropdown
                  {...field}
                  enableSearch
                  searchPlaceholder="Search Hub Station"
                  placeholder="Assign Hub Station"
                  className="max-h-[300px] overflow-y-scroll"
                  formState={formState}
                  options={hubStations}
                  onSelect={(data) => {
                    setValue("hubName", data.value);
                    setValue("hubId", data.id);
                    clearErrors("hubName");
                  }}
                />
              )}
            />

            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
                hubNameError ? "text-alert-red visible" : "invisible"
              }`}
            >
              {hubNameError}
            </p>
          </div>
        </div>
      </div>
    </CustomLayout>
  );
};

export default AddTrike;
