import dayjs from "dayjs";
import { TRIP_STATUS } from "./TripConstants";

export const getBatteryText = (batteries) => {
  if (!batteries.length) return "--";
  if (batteries.length === 1) return "1 Battery";
  return `${batteries.length} Batteries`;
};

export const getDateOfTripText = (startDate) => {
  if (!startDate) return "--";

  return dayjs(startDate).format("MMM DD YYYY, hh:mm a");
};

export const getWeightText = (weight, currentStatus) => {
  if (weight && currentStatus === TRIP_STATUS.ONGOING)
    return parseFloat(weight).toFixed(2);

  return "--";
};
