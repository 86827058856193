/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { SERVICE_ASSET_TYPE } from "../Data";
import { updateServices } from "../../../Redux/Slices/services.slice";
import useServiceThreeDots from "./useServiceThreeDots";

const ListDropdown = ({ selectedService }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { threeDotsPopoverId } = useSelector((state) => state.services);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [open, setOpen] = useState(false);

  const threeDotsOptions = useServiceThreeDots().filter(
    (item) => item.hasPermission,
  );

  const filteredOptions = () => {
    let options = [];

    // if service is completed, only show view service option
    if (selectedService.isCompleted) {
      const localOptions = threeDotsOptions.filter((item) => item.id === 2);
      options = [...localOptions];
    } else {
      const localOptions = threeDotsOptions.filter((item) => item.id !== 2);
      options = [...localOptions];
    }

    return options;
  };

  const onOptionClick = async (optionId) => {
    dispatch(
      updateServices({
        key: "selectedService",
        value: selectedService,
      }),
    );

    const type =
      selectedService.assetType === SERVICE_ASSET_TYPE.TRIKE
        ? "trikes"
        : "batteries";

    switch (optionId) {
      case 1:
        dispatch(
          updateServices({
            key: "updatingService",
            value: true,
          }),
        );
        navigate(
          `/add-to-service/${type}/${selectedService.assetRegistrationNo}`,
        );

        break;

      // redirect to complete service on both view and complete service options
      case 2:
      case 3:
        navigate(
          `/complete-service/${type}/${selectedService.assetRegistrationNo}`,
        );
        break;
      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateServices({
        key: "threeDotsPopoverId",
        value: selectedService.id,
      }),
    );
  };

  // if three dots has no items (depending on the permission) then return empty fragments
  if (!filteredOptions().length) {
    return <></>;
  }

  // do not show the three dots options if the user does not have access in that hub
  if (
    selectedService?.hubId &&
    !userDetails.hubs.includes(+selectedService.hubId)
  ) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && selectedService.id === threeDotsPopoverId}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
            paddingTop: filteredOptions().length && "4px",
            paddingBottom: filteredOptions().length && "4px",
          }}
        >
          {filteredOptions().map((option) => (
            <button
              type="button"
              className="w-full flex items-center gap-x-3 px-3 py-2 group hover:bg-background"
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p className="font-aileron text-xs font-semibold capitalize text-dark-gray group-hover:text-black">
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
