import React from "react";
import { PAYMENT_STATUS } from "../data";

const due = (payment) => {
  let total = 0;

  payment.details.forEach((item) => {
    // add amount whose payment status is pending
    if (item.paymentStatus === PAYMENT_STATUS.PENDING) total += item.amount;
  });

  return total;
};

export const sortByTotalAmountDue = (a, b) => {
  const first = due(a);
  const second = due(b);

  return second - first;
};

const TotalAmountDue = ({ payment }) => {
  return (
    <div className="text-xs font-medium font-poppins leading-4.5">
      {due(payment)} KSH
    </div>
  );
};

export default TotalAmountDue;
