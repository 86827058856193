/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import TrackerIcon from "../../../Common/Svgs/TrackerIcon";
import Button from "../../../Core/Components/CustomButton";
import {
  getTrikeByIdV3,
  getTrikesV3,
  updateLocationSwitchV3,
} from "../../../Redux/APIs/trikesAPI";
import { LOCATION_FETCH_SOURCE } from "../Data";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import { getTripsV3 } from "../../../Redux/APIs/tripsAPI";
import { updateTrips } from "../../../Redux/Slices/trips.slice";

const TrikeTrackerModal = ({
  visible,
  onCancel,
  currentPage = 0,
  page = "trike-listing",
}) => {
  const dispatch = useDispatch();

  const { trikeTrackerModal } = useSelector((state) => state.trikes);
  const { toastMessage } = useSelector((state) => state.trips);

  const onConfirm = async () => {
    const locationFetchSource =
      trikeTrackerModal.currentSource === LOCATION_FETCH_SOURCE.MOBILE
        ? LOCATION_FETCH_SOURCE.THINGSBOARD
        : LOCATION_FETCH_SOURCE.MOBILE;

    await dispatch(
      updateLocationSwitchV3({
        trikeId: trikeTrackerModal.trikeId,
        locationFetchSource,
      }),
    );

    if (page === "trip-listing") {
      await dispatch(
        getTripsV3({
          offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
        }),
      );

      dispatch(
        updateTrips({
          key: "toastMessage",
          value: {
            ...toastMessage,
            visible: true,
            type: "trackerChanged",
            id: trikeTrackerModal.trikeRegistrationNo,
          },
        }),
      );

      onCancel();

      return;
    }

    if (page === "trike-listing") {
      await dispatch(
        getTrikesV3({
          offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
          limit: DEFAULT_LIMIT,
        }),
      );
    }

    if (page === "trike-details" || page === "trip-details") {
      dispatch(getTrikeByIdV3({ id: trikeTrackerModal.trikeId }));
    }

    dispatch(
      updateTrikes({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "Tracker Changed",
            description: `Trike ${trikeTrackerModal.trikeRegistrationNo}'s tracker was changed successfully.`,
          },
        },
      }),
    );

    onCancel();
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <CustomRoundedImage
              size="w-12 h-12"
              background="bg-green"
              image={
                <TrackerIcon size={24} className="text-background-white" />
              }
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        <div className="pl-2 mt-5">
          <p className="font-poppins text-base font-medium">Change tracker</p>
          <p className="pt-2 font-aileron text-xs font-normal">
            Are you sure you want to change the tracker for this trike?
          </p>
        </div>

        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="grey" size="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button colors="primary" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default TrikeTrackerModal;
