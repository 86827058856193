import React from "react";
import { useSelector } from "react-redux";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import TripsIcon from "../../../Common/Svgs/TripsIcon";
import getTripDays from "../../../Utils/getTripDays";
import WatchIcon from "../../../Common/Svgs/WatchIcon";
import UserIcon from "../../../Common/Svgs/UserIcon";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import { BatterySecondaryIcon } from "../../../Common/Svgs/BatteryIcon";

const StepItem = ({
  isCompleted = true,
  stepNumber = 1,
  icon,
  title,
  subtitle,
  label,
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <CustomRoundedImage
        size="w-7.5 h-7.5"
        background={isCompleted ? "bg-primary" : "bg-light-grey"}
        image={
          isCompleted ? (
            icon
          ) : (
            <div className="font-semibold font-aileron text-10 text-dark-gray">
              {stepNumber}
            </div>
          )
        }
      />

      {isCompleted ? (
        <div className="flex flex-col">
          <p className="font-semibold capitalize font-aileron text-10 text-primary">
            {title}
          </p>
          <p className="font-normal font-aileron text-10 text-dark-gray">
            {subtitle}
          </p>
        </div>
      ) : (
        <div className="font-semibold font-aileron text-10 text-dark-gray">
          {label}
        </div>
      )}
    </div>
  );
};

const StepProgressLine = ({ isCompleted = true }) => {
  return (
    <div
      className={`w-[113px] h-[1px] mx-1 ${
        isCompleted ? "bg-primary" : "bg-light-grey"
      }`}
    />
  );
};

const Header = () => {
  const { stops, tripDetails, optimisedRouteTime, calculatedRouteTime } =
    useSelector((state) => state.trips);

  return (
    <div className="flex items-center">
      <StepItem
        stepNumber={1}
        icon={<TripsIcon size={14} className="text-yellow" />}
        title={`${stops.length} (${optimisedRouteTime || calculatedRouteTime})`}
        subtitle="Stops selected"
        label="Select Stops"
      />
      <StepProgressLine />
      <StepItem
        stepNumber={2}
        icon={<WatchIcon className="text-yellow" />}
        title={getTripDays(
          tripDetails.startDate,
          tripDetails.endDate,
          tripDetails.daysOfOccurence,
        )}
        subtitle="Days selected"
        label="Schedule Trip"
      />
      <StepProgressLine />
      <StepItem
        stepNumber={3}
        icon={<UserIcon className="w-3.5 h-3.5 stroke-yellow fill-none" />}
        title={tripDetails.driverName || null}
        subtitle="Driver selected"
        label="Select Driver"
      />
      <StepProgressLine />
      <StepItem
        stepNumber={4}
        icon={<TrikeIcon size={14} className="text-yellow" />}
        title={tripDetails.trikeRegistrationNo || null}
        subtitle={tripDetails.trikeId ? "Trike selected" : ""}
        label="Select Trike"
      />
      <StepProgressLine />
      <StepItem
        stepNumber={5}
        icon={<BatterySecondaryIcon size={14} className="text-yellow" />}
        title={
          tripDetails?.batteries?.length
            ? `${tripDetails?.batteries?.length}`
            : ""
        }
        subtitle={tripDetails?.batteries?.length ? "Batteries selected" : ""}
        label="Select Batteries"
      />
    </div>
  );
};

export default Header;
