import React, { useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import HomeItem from "./HomeItem";
import EnergyResourcesItem from "./EnergyResourcesItem";
import AssetsItem from "./AssetsItem";
import ManagementCentreItem from "./ManagementCentreItem";
import TripItem from "./TripItem";
import SettingsItem from "./SettingsItem";
import LoggedUserItem from "./LoggedUserItem";
import FinanceItem from "./FinanceItem";
import { SUBMENU } from "../data/contants";

const Sidebar = ({ pageName }) => {
  const { companyLogo } = useSelector((state) => state.loggedInUser);

  const [sidebarActive, setSidebarActive] = useState(false);
  const [subMenu, setSubMenu] = useState(SUBMENU.DEFAULT);

  return (
    <aside
      className="absolute z-[99999] h-screen pt-6 flex flex-col bg-primary border-r border-light-grey transition-all duration-200"
      style={{
        width: sidebarActive ? "232px" : "72px",
      }}
      onMouseEnter={() =>
        setTimeout(() => {
          setSidebarActive(true);
        }, 200)
      }
      onMouseLeave={() =>
        setTimeout(() => {
          setSidebarActive(false);
        }, 200)
      }
    >
      <div className="w-full px-5">
        <motion.img
          layout
          src={companyLogo}
          className="w-8 h-8 shrink-0"
          alt="Songa Mobility"
        />
      </div>

      <div className="w-full">
        <HomeItem sidebarActive={sidebarActive} pageName={pageName} />

        <AssetsItem
          sidebarActive={sidebarActive}
          pageName={pageName}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
        />

        <EnergyResourcesItem
          sidebarActive={sidebarActive}
          pageName={pageName}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
        />

        <ManagementCentreItem
          sidebarActive={sidebarActive}
          pageName={pageName}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
        />

        <div className="mt-4 bg-primary-50 w-full h-[1px]" />

        <TripItem
          sidebarActive={sidebarActive}
          pageName={pageName}
          subMenu={subMenu}
        />

        <FinanceItem
          sidebarActive={sidebarActive}
          // pageName={pageName}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
        />

        <SettingsItem
          sidebarActive={sidebarActive}
          pageName={pageName}
          subMenu={subMenu}
        />

        <LoggedUserItem sidebarActive={sidebarActive} />
      </div>
    </aside>
  );
};

export default Sidebar;
