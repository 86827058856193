/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { updateLocations } from "../../../Redux/Slices/locations.slice";
import useLocationThreeDotsOptions from "./useLocationThreeDotsOptions";
import { LOCATION_TYPE } from "../Data";

const ListDropdown = ({ selectedLocation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { threeDotsPopoverId } = useSelector((state) => state.locations);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [open, setOpen] = useState(false);

  const threeDotsOptions = useLocationThreeDotsOptions().filter(
    (item) => item.hasPermission,
  );

  const onOptionClick = async (optionId) => {
    dispatch(
      updateLocations({ key: "currentLocation", value: selectedLocation }),
    );

    switch (optionId) {
      case 1:
        if (selectedLocation.type === LOCATION_TYPE.HUB_STATION) {
          navigate(`/edit-hub/${selectedLocation.id}`);
        } else {
          navigate(`/edit-location/${selectedLocation.id}`);
        }

        break;
      case 2:
        setOpen(false);

        if (selectedLocation.type === LOCATION_TYPE.HUB_STATION) {
          dispatch(
            updateLocations({
              key: "deleteHubStationModal",
              value: {
                visible: true,
                hub: selectedLocation,
              },
            }),
          );
        } else {
          dispatch(
            updateLocations({
              key: "deleteLocationModal",
              value: {
                visible: true,
                location: selectedLocation,
              },
            }),
          );
        }

        break;
      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateLocations({
        key: "threeDotsPopoverId",
        value: selectedLocation.id,
      }),
    );
  };

  // if three dots has no items (depending on the permission) then return empty fragments
  if (!threeDotsOptions.length) {
    return <></>;
  }

  // do not show the three dots options if the user does not have access in that hub
  if (
    selectedLocation?.hubId &&
    !userDetails.hubs.includes(+selectedLocation.hubId)
  ) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && threeDotsPopoverId === selectedLocation.id}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
            paddingTop: threeDotsOptions.length && "4px",
            paddingBottom: threeDotsOptions.length && "4px",
          }}
        >
          {threeDotsOptions.map((option, index) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group
              ${
                index === 1
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p
                className={`font-aileron text-xs font-semibold capitalize ${
                  index === 1
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
