/* eslint-disable no-console */
/* eslint-disable no-unsafe-optional-chaining */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTripById } from "../../../Redux/APIs/tripsAPI";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import { updateHome } from "../../../Redux/Slices/home.slice";
import { TRIP_STATUS } from "../../../Modules/Trips/Data/TripConstants";

const ItemBody2 = ({ notification }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const noRedirection = [TRIP_STATUS.ONGOING, TRIP_STATUS.COMPLETED];

  const handleClick = async () => {
    const tripId = notification?.metaData?.tripId;

    if (!tripId) return;

    try {
      dispatch(updateHome({ key: "notificationPopupVisible", value: false }));

      const response = await dispatch(getTripById({ tripId }));
      const { distance, time, statusId } = response?.payload?.result || {};

      if (noRedirection.includes(+statusId)) return;

      dispatch(
        updateTrips({
          key: "editTripDetails",
          value: { tripId, flag: true },
        }),
      );

      dispatch(
        updateTrips({
          key: "currentStep",
          value: 5,
        }),
      );

      dispatch(
        updateTrips({
          key: "isRouteOptimised",
          value: false,
        }),
      );

      dispatch(
        updateTrips({
          key: "calculatedRouteDistance",
          value: distance || 0,
        }),
      );

      dispatch(
        updateTrips({
          key: "calculatedRouteTime",
          value: time || 0,
        }),
      );

      navigate("/trips/create-trip");
    } catch (err) {
      console.log("Failed to fetch trip details:", err);
    }
  };

  return (
    <div>
      <p className="font-poppins text-xs font-light text-black">
        Please assign batteries to trip id{" "}
        <span className="font-medium">{notification?.metaData?.tripId}</span>{" "}
        starting at{" "}
        <span className="font-medium">{notification?.metaData?.timeSlot} </span>
        from{" "}
        <span className="capitalize">{notification?.metaData?.hubName}</span>
      </p>

      <div className="mt-1 w-full flex items-center gap-x-2">
        <button
          type="button"
          className="bg-primary hover:bg-primary-50 py-2 px-4 rounded-md w-full"
          onClick={handleClick}
        >
          <p className="font-poppins text-xs font-medium text-background-white text-center">
            Assign Batteries
          </p>
        </button>
      </div>
    </div>
  );
};

export default ItemBody2;
