import { Divider } from "antd";
import React from "react";

const TrikeBatteryPack = ({ detail }) => {
  if (!detail?.batteryPack?.length) return "";

  const getColor = (percentage) => {
    if (percentage >= 0 && percentage <= 24) return "text-alert-red";
    if (percentage >= 25 && percentage <= 50) return "text-warning";
    return "text-green";
  };

  return (
    <div className="flex flex-wrap items-center">
      {detail.batteryPack.map((item, index) => {
        return (
          <div className="flex items-center" key={item.id}>
            <div className="font-aileron text-10 leading-3.75 font-semibold text-black">
              B{index + 1}
              <span className={`pl-2 ${getColor(+item.percentage)}`}>
                {item.percentage}%
              </span>
            </div>

            {index < detail.batteryPack.length - 1 && (
              <Divider type="vertical" className="mt-[3px] bg-light-grey" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TrikeBatteryPack;
