import React from "react";
import { useSelector } from "react-redux";
import Button from "../../../Core/Components/CustomButton";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import UserIcon from "../../../Common/Svgs/UserIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";

const ConfirmRoleModal = ({ visible, onCancel, onCreate }) => {
  const { confirmRoleModal } = useSelector((state) => state.home);

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="">
            <CustomRoundedImage
              size="w-12 h-12"
              image={<UserIcon className="stroke-white fill-none" />}
              background="bg-primary"
            />
          </div>

          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        <div className="px-2 mt-5">
          <p className="text-base font-medium text-primary font-poppins">
            {confirmRoleModal?.mode === "edit" ? "Edit" : "Create"} role
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to{" "}
            {confirmRoleModal?.mode === "edit" ? "edit" : "create"} this role?
          </p>
        </div>

        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>

          <Button colors="primary" size="primary" onClick={onCreate}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmRoleModal;
