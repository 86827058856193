/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import CustomDetailsLayout from "../../Core/Layout/CustomDetailsLayout";
import { KPIData } from "./Data/TrikesDetailData";
import { CustomDetailsKPI } from "../../Core/Components/CustomKpi";
import { getTripsForDriverV3 } from "../../Redux/APIs/tripsAPI";
import ViewDetailsDrawer from "../Users/ViewDetailsDrawer";
import { getTrikeByIdV3 } from "../../Redux/APIs/trikesAPI";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import MobiliseModalV3 from "../../Core/Components/Modal/MobiliseModalV3";
import { updateTrikes } from "../../Redux/Slices/trikes.slice";
import TrikeTripTable from "./TrikeTripTable";
import TrikeBatteries from "./AssociatedBatteries/TrikeBatteries";

const TrikesDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { currentTrike, mobiliseModalV3 } = useSelector(
    (state) => state.trikes,
  );

  const { tripsForTrike } = useSelector((state) => state.trips);

  const { batteryDetails } = useSelector((state) => state.batteries);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [viewDetailsDrawer, setViewDetailsDrawer] = useState(false);

  useEffect(() => {
    dispatch(getTrikeByIdV3({ id: currentTrike.id }));

    if (currentTrike.currentDriverId !== null) {
      dispatch(getTripsForDriverV3({ driverId: currentTrike.currentDriverId }));
    }
  }, [dispatch]);

  // fetching current trike details every 15 seconds for getting the live location
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getTrikeByIdV3({ id: currentTrike.id }));
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch]);

  console.log(currentTrike, "check current trike");

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Trikes" nestedName={`${id}`}>
      <CustomDetailsLayout
        assetType="trikes"
        KPIBody={
          <div className="bg-background-white">
            <Row gutter={24} className="h-[360px] p-6">
              <Col span={10}>
                <div className="flex flex-col justify-start w-full h-full items-between gap-y-2">
                  {KPIData(currentTrike, batteryDetails)?.map((i) => (
                    <CustomDetailsKPI
                      key={i.id}
                      title={i.title}
                      value={i.value}
                      image={i.image}
                      background={i.background}
                    />
                  ))}
                </div>
              </Col>
              <Col span={14}>
                <TrikeBatteries />
              </Col>
            </Row>
          </div>
        }
        TableBody={
          <div className="bg-background-white border-y border-light-grey pb-2">
            <div className="flex items-center gap-x-3 px-6 py-4">
              <p className="font-poppins text-base font-medium">Trips</p>
              <div className="flex items-center justify-center rounded-full w-6 h-6 bg-light-grey">
                <p className="font-aileron text-10 font-semibold">
                  {tripsForTrike.totalRecords}
                </p>
              </div>
            </div>

            <div className="w-full h-[1px] bg-light-grey" />

            {/* <div className="h-[calc(100vh-544px)]" ref={tableRef}> */}
            <TrikeTripTable />
          </div>
          // </div>
        }
      />
      <div className="">
        <ViewDetailsDrawer
          visible={viewDetailsDrawer}
          onCancel={() => setViewDetailsDrawer(false)}
        />
      </div>

      <MobiliseModalV3
        visible={mobiliseModalV3?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "mobiliseModalV3",
              value: {
                visibe: false,
                trike: {},
              },
            }),
          )
        }
      />
    </CustomLayout>
  );
};
export default TrikesDetails;
