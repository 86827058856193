import React from "react";
import { PAYMENT_STATUS } from "../data";

const due = (payment) => {
  const totalTripsDue = [];

  payment.details.forEach((item) => {
    // include the trip id if the payment is pending and trip is not included in the array

    if (
      item.paymentStatus === PAYMENT_STATUS.PENDING &&
      !totalTripsDue.includes(item.tripId)
    ) {
      totalTripsDue.push(item.tripId);
    }
  });

  return totalTripsDue.length;
};

export const sortByTripsDue = (a, b) => {
  const first = due(a);
  const second = due(b);

  return second - first;
};

const TripsDue = ({ payment }) => {
  return (
    <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
      {due(payment)}
    </div>
  );
};

export default TripsDue;
