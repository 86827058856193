/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "./CustomModal";
import LockRedIcon, { LockGreenIcon } from "../../../Common/Svgs/LockIcon";
import CustomRoundedImage from "../CustomRoundedImage";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../CustomButton";
import InfoCircleIcon from "../../../Common/Svgs/InfoCircleIcon";
import SwitchIcon from "../../../Common/Svgs/switch";
import { TRIKE_STATUS } from "../../../Modules/Trikes/Data";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import { client } from "../../../Utils/axiosClient";
import { getAllTrikesV3, getTrikesV3 } from "../../../Redux/APIs/trikesAPI";

const Header = ({ type, onCancel }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="relative">
        <div className="absolute top-0 right-0">
          {type === TRIKE_STATUS.ON_TRIP ||
          type === TRIKE_STATUS.OUT_OF_GEOFENCE ? (
            <LockRedIcon />
          ) : (
            <LockGreenIcon />
          )}
        </div>

        <CustomRoundedImage
          size="w-12 h-12"
          background={
            type === TRIKE_STATUS.ON_TRIP ||
            type === TRIKE_STATUS.OUT_OF_GEOFENCE
              ? "bg-dark-gray"
              : "bg-marker-green"
          }
          image={<TrikeIcon size={24} className="text-background-white" />}
        />
      </div>

      <button type="button" onClick={onCancel}>
        <CloseIcon className="w-6 h-6 cursor-pointer stroke-dark-gray" />
      </button>
    </div>
  );
};

const Body = ({ onCancel, type }) => {
  const dispatch = useDispatch();

  const { mobiliseModalV3 } = useSelector((state) => state.trikes);

  const onConfirm = async () => {
    console.log(mobiliseModalV3.trike, "check status and is moving property");

    const toggle = !mobiliseModalV3.isMoving;

    try {
      const response = await client.post(
        `${process.env.REACT_APP_API_URL}/things/toggle`,
        {
          trikeId: mobiliseModalV3.trike.id,
          deviceId: mobiliseModalV3.trike.sensorNo,
          toggle,
        },
      );
      if (response) {
        dispatch(getAllTrikesV3());
        dispatch(getTrikesV3());
      }
    } catch (error) {
      console.log(error, "error in toggle from frontend");
    } finally {
      onCancel();
    }
  };

  if (type === TRIKE_STATUS.ON_TRIP) {
    // trike status === on trip, stop the trike
    return (
      <div>
        <div className="pt-5 text-base font-medium font-poppins text-alert-red">
          Immobilise this trike
        </div>
        <div>
          <div className="pt-2 text-xs leading-4.5 font-light font-aileron text-black">
            Lock your trikes easily in their live locations.
          </div>
        </div>
        <div className="pt-1 text-xs leading-4.5 font-light font-aileron text-black">
          You can reverse this action by simply clicking on{" "}
          <span className="font-medium text-green">Mobilise</span>.
        </div>
        <div className="flex items-center mt-4 gap-x-2">
          <InfoCircleIcon size={14} className="text-alert-red" />
          <p className="text-xs font-normal font-aileron text-alert-red">
            The trike will be immobilised only when it is static.
          </p>
        </div>
        <div className="flex justify-end gap-x-3 pt-4.5">
          <Button colors="grey" size="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button colors="alert" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    );
  }

  if (type === TRIKE_STATUS.OUT_OF_GEOFENCE) {
    // trike status === out of geofence, stop the trike with hazard sign

    return (
      <div>
        <div className="pt-5 text-base font-medium font-poppins text-alert-red">
          Cannot Immobilise this trike
        </div>
        <div className="pt-2 text-xs leading-4.5 font-light font-aileron text-black">
          Looks like this trike is still moving.
        </div>
        <div className="pt-1 text-xs leading-4.5 font-light font-aileron text-black">
          Make sure that the trike is static before immobilising.
        </div>
        <div className="flex items-center mt-4 gap-x-2">
          <InfoCircleIcon size={12} className="text-alert-red" />
          <p className="font-light text-10 font-aileron text-alert-red">
            The trike will be immobilised only when it is static.
          </p>
        </div>
        <div className="flex justify-end gap-x-3 pt-4.5">
          <Button colors="grey" size="primary" onClick={onCancel}>
            Cancel
          </Button>

          {/* confirm button should not appear if the trike is out of geofence */}
          {/* <Button colors="alert" size="primary" onClick={onConfirm}>
            Confirm
          </Button> */}
        </div>
      </div>
    );
  }
  return (
    // trike status === parking lot (or any other), move the trike
    <div>
      <div className="pt-5 text-base font-medium font-poppins text-green">
        Mobilise this trike
      </div>
      <div className="pt-2 text-xs leading-4.5 font-light font-aileron text-black">
        Unlock your trikes easily from their live locations.
      </div>
      <div className="pt-1 text-xs leading-4.5 font-light font-aileron text-black">
        You can reverse this action by simply clicking on{" "}
        <span className="font-medium text-alert-red">Immobilise</span>.
      </div>
      <div className="flex justify-end gap-x-3 pt-4.5">
        <Button colors="grey" size="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export const MobiliseModalCTA = ({ trike }) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.stopPropagation();

    dispatch(
      updateTrikes({
        key: "mobiliseModalV3",
        value: { visible: true, trike },
      }),
    );
  };

  switch (parseInt(trike.status, 10)) {
    case TRIKE_STATUS.HUB_STATION:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="mobilizePrimary"
          padding="mobilize"
          onClick={(e) => handleClick(e)}
        >
          <div className="flex items-center justify-start gap-x-2">
            <SwitchIcon className="stroke-primary fill-none" />
            <div className="text-xs font-medium font-poppins leading-4.5">
              Mobilise
            </div>
          </div>
        </Button>
      );

    case TRIKE_STATUS.ON_TRIP:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="light-alert"
          padding="mobilize"
          onClick={(e) => handleClick(e)}
        >
          <div className="flex items-center justify-start gap-x-2">
            <SwitchIcon className="stroke-[#C84630] fill-none" />
            <div className="text-xs font-medium font-poppins leading-4.5">
              Immobilise
            </div>
          </div>
        </Button>
      );

    case TRIKE_STATUS.OUT_OF_GEOFENCE:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="alert"
          padding="mobilize"
          onClick={(e) => handleClick(e)}
        >
          <div className="flex items-center justify-start gap-x-2">
            <img src="/assets/icons/danger.svg" alt="" />
            <div>Immobilise</div>
          </div>
        </Button>
      );

    default:
      return (
        <Button
          width="mobilize"
          size="mobilize"
          colors="mobilizePrimary"
          padding="mobilize"
          onClick={(e) => handleClick(e)}
        >
          <div className="flex items-center justify-start gap-x-2">
            <SwitchIcon className="stroke-primary fill-none" />
            <div className="text-xs font-medium font-poppins leading-4.5">
              Mobilise
            </div>
          </div>
        </Button>
      );
  }
};

const MobiliseModalV3 = ({ visible, onCancel }) => {
  const { mobiliseModalV3 } = useSelector((state) => state.trikes);

  const type = parseInt(mobiliseModalV3.trike.status, 10);

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px">
      <div
        className="p-6 rounded-xl"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <Header type={type} onCancel={onCancel} />
        <Body type={type} onCancel={onCancel} />
      </div>
    </CustomModal>
  );
};

export default MobiliseModalV3;
