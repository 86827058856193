import React from "react";
import { useNavigate } from "react-router-dom";

const ItemBody6 = ({ notification }) => {
  const navigate = useNavigate();

  return (
    <div>
      <p className="font-poppins text-xs font-light">
        <span className="font-medium text-primary capitalize">
          {notification?.metaData?.supplierHubName}{" "}
        </span>
        has <span className="font-medium text-alert-red">rejected </span>
        your request of {notification?.metaData?.batteryCount} batteries. Please
        reach out to another the hub station.
      </p>

      <div className="mt-1 w-full flex items-center gap-x-2">
        <button
          type="button"
          className="bg-primary hover:bg-primary-50 py-2 px-4 rounded-md w-full"
          onClick={() => navigate("/request-batteries")}
        >
          <p className="font-poppins text-xs font-medium text-background-white text-center">
            Request Batteries
          </p>
        </button>
      </div>
    </div>
  );
};

export default ItemBody6;
