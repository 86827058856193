import React from "react";
import dayjs from "dayjs";
import BatteryLowIcon, {
  BatteryChargingIcon,
  BatteryHighIcon,
  BatteryNeutralIcon,
  BatteryNoStatusIcon,
} from "../../../Common/Svgs/BatteryChargeIcon";
import CustomTooltip from "../../../Core/Components/CustomTooltip";

const ChargingStartTime = ({ detail }) => {
  if (dayjs(detail.chargingStartTime).isSame(dayjs(), "day")) {
    return (
      <CustomTooltip
        tooltipTitle={`Started at ${dayjs(detail?.chargingStartTime).format(
          "DD-MM-YYYY, hh:mm a",
        )}`}
      >
        <p className="font-light font-poppins text-10 text-dark-gray">
          Started at {dayjs(detail?.chargingStartTime).format("hh:mm a")}
        </p>
      </CustomTooltip>
    );
  }

  return (
    <CustomTooltip
      tooltipTitle={`Started at ${dayjs(detail?.chargingStartTime).format(
        "DD-MM-YYYY, hh:mm a",
      )}`}
    >
      <p className="font-light font-poppins text-10 text-dark-gray">
        Started at{" "}
        {dayjs(detail?.chargingStartTime).format("DD-MM-YYYY").slice(0, 10)}...
      </p>
    </CustomTooltip>
  );
};

const getBatteryIconAndColor = (percentage, isCharging) => {
  if (isCharging)
    return { icon: <BatteryChargingIcon />, color: "text-yellow-dark" };
  if (percentage >= 0 && percentage <= 24)
    return { icon: <BatteryLowIcon />, color: "text-alert-red" };
  if (percentage >= 25 && percentage <= 50)
    return { icon: <BatteryNeutralIcon />, color: "text-warning" };
  if (percentage >= 51 && percentage <= 100)
    return { icon: <BatteryHighIcon />, color: "text-primary" };
  return { icon: <BatteryNoStatusIcon />, color: "" };
};

const BatteryPercentageStatus = ({
  detail,
  isCharging = false,
  showChargingStatus = true,
}) => {
  const { percentage } = detail;

  const { icon: batteryIcon, color: batteryColor } = getBatteryIconAndColor(
    percentage,
    isCharging,
  );

  const chargingStatus = isCharging ? "Charging" : "Discharging";

  return (
    <div>
      <div className="flex items-center gap-x-2">
        {batteryIcon}
        <div
          className={`text-xs font-medium font-poppins leading-4.5 ${batteryColor}`}
        >
          {percentage === -1 ? "--" : `${percentage}%`}
        </div>
      </div>

      {showChargingStatus && (
        <div>
          {isCharging ? (
            <ChargingStartTime detail={detail} />
          ) : (
            <p className="font-light capitalize font-poppins text-10 text-dark-grey-text">
              {chargingStatus}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default BatteryPercentageStatus;
