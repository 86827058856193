/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Divider, Row } from "antd";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import CustomDetailsLayout from "../../Core/Layout/CustomDetailsLayout";
import {
  KPIData,
  batteryData,
  batteryDetailsColumns,
  voltageData,
} from "./Data/BatteryDetailData";
import CustomKPI from "../../Core/Components/CustomKpi";
import CustomTableTopBar from "../../Core/Components/CustomTableTopBar";
import CustomTable from "../../Core/Components/CustomTable";
import CustomRoundedImage from "../../Core/Components/CustomRoundedImage";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import BatteryStringsPopUp from "./BatteryStringsPopUp";

import BatteryIcon, { BatteryPackIcon } from "../../Common/Svgs/BatteryIcon";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import BatteryBar from "../Trikes/AssociatedBatteries/BatteryBar";

const BatteryDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { batterySwaps, currentBattery } = useSelector(
    (state) => state.batteries,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [showKpi, setShowKpi] = useState(true);
  const [batteryStringPopUp, setBatteryStringPopUp] = useState(false);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  const getBatteryColor = (percentage) => {
    if (percentage >= 0 && percentage <= 24) return "bg-alert-red";
    if (percentage >= 25 && percentage <= 50) return "bg-warning";
    return "bg-green";
  };

  return (
    <CustomLayout pageName="Batteries" nestedName={`${id}`}>
      <CustomDetailsLayout
        assetType="batteries"
        KPIBody={
          <div className="bg-background-white">
            <Row gutter={24} className="h-[360px] p-6">
              <Col span={6}>
                <div className="flex flex-col justify-start w-full h-full items-between gap-y-6">
                  {KPIData(currentBattery)?.map((i) => (
                    <CustomKPI
                      key={i.id}
                      title={i.title}
                      value={i.value}
                      image={i.image}
                      background={i.background}
                      count={i.count}
                    />
                  ))}
                </div>
              </Col>
              <Col span={18}>
                <div className="w-full h-full border-[0.5px] border-light-grey rounded-md p-4 flex flex-col justify-between">
                  <div className="flex items-center justify-between">
                    <div className="flex justify-start items-center gap-x-2.5">
                      <CustomRoundedImage
                        color="bg-primary"
                        image={<BatteryPackIcon className="text-kpi-icon" />}
                      />
                      <div className="font-aileron font-normal text-dark-gray text-xs leading-4.5">
                        Battery
                      </div>
                    </div>
                    <div className="flex justify-start items-start gap-x-2.5">
                      <img
                        src="/assets/icons/details/discharging-grey.svg"
                        alt=""
                      />
                      <div className="font-aileron font-normal text-dark-gray text-xs leading-4.5">
                        Discharging
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-start gap-x-5">
                    <div className="flex flex-col">
                      <div className="font-poppins font-semibold text-2xl leading-9 text-center py-2.25 relative z-10">
                        {currentBattery.percentage}%
                      </div>

                      <div className="w-20 h-[198px]">
                        {/* <CustomProgressBar
                          text=""
                          current={Number(currentBattery.percentage)}
                          color="bg-primary"
                        /> */}
                        <BatteryBar
                          current={
                            currentBattery.percentage > 0
                              ? currentBattery?.percentage
                              : 1
                          }
                          color={getBatteryColor(currentBattery.percentage)}
                        />
                      </div>
                    </div>
                    <div className="mt-[56px] h-[198px] px-[47px] py-6 bg-background rounded-md w-full">
                      <div className="font-aileron font-semibold text-black text-xs leading-4.5">
                        Specifications
                      </div>
                      <div className="grid grid-cols-5 mt-6">
                        <div className="grid grid-cols-2 gap-x-[42px] gap-y-6 col-span-2">
                          {voltageData(currentBattery)?.map((i) => (
                            <div>
                              <div className="text-10 font-aileron font-normal leading-[15px] text-dark-gray whitespace-nowrap">
                                {i.title}
                              </div>
                              <div className="font-aileron font-semibold text-black text-xs leading-4.5 capitalize">
                                {i.value}
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="flex items-center justify-center w-full h-full">
                          <Divider type="vertical" className="h-24 m-0" />
                        </div>

                        <div className="grid grid-cols-2 gap-x-[42px] gap-y-6 col-span-2">
                          {batteryData(currentBattery)?.map((i, index) => (
                            <div>
                              <div className="flex items-start gap-x-[5px]">
                                <div className="text-10 font-aileron font-normal leading-[15px] text-dark-gray whitespace-nowrap">
                                  {i.title}
                                </div>

                                {index === 3 && (
                                  <div className="pt-0.5">
                                    <ChevronIcon
                                      className="w-3 h-3 rotate-90 cursor-pointer stroke-dark-grey-text"
                                      onClick={() =>
                                        setBatteryStringPopUp(true)
                                      }
                                    />
                                  </div>
                                )}
                              </div>

                              <div className="font-aileron font-semibold text-black text-xs leading-4.5">
                                {i.value}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        }
        TableBody={
          <div
            className={`absolute w-full ${
              !showKpi ? "-translate-y-[361px]" : ""
            }  transition-transform duration-500`}
          >
            <CustomTableTopBar
              assetId={id}
              searchPlaceholder="Search Trikes or drivers"
              showBtnFunc={() => setShowKpi(!showKpi)}
              detailsTitle="Battery Swaps"
              detailsFilter
              module="battery-details"
            />
            <CustomTable
              scrollX={0}
              columns={batteryDetailsColumns}
              data={batterySwaps}
              emptyState={
                <BatteryIcon className="w-4 h-4 fill-none stroke-dark-grey-text" />
              }
              emptyStateHeight="200px"
            />
          </div>
        }
      />
      <BatteryStringsPopUp
        visible={batteryStringPopUp}
        setVisible={setBatteryStringPopUp}
      />
    </CustomLayout>
  );
};
export default BatteryDetails;
