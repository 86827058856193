import { createSlice } from "@reduxjs/toolkit";
import {
  getCollectionDetails,
  getCollectionKPI,
  getCollectionList,
  getPaymentDetails,
  getPaymentKPI,
  getPaymentList,
  updatePaymentStatus,
} from "../APIs/revenueAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",

  showToastMessage: {
    visible: false,
    data: {},
  },

  paymentKPI: {
    total: 0,
    drivers: 0,
    farmers: 0,
  },
  paymentList: {
    result: [],
    totalRecords: 0,
  },
  selectedPayment: {},
  paymentDetails: {
    result: [],
    totalDue: 0,
    totalRecords: 0,
  },
  collectionKPI: {
    total: 0,
    offtakers: 0,
  },
  collectionList: {
    result: [],
    totalRecords: 0,
  },
  selectedCollection: {},
  collectionDetails: {
    result: [],
    totalDue: 0,
    totalRecords: 0,
  },
  threeDotsPopoverId: 0,
  paymentDetailsPopoverId: 0,
  collectionDetailsPopoverId: 0,
  selectedPaymentDetailStatusId: 0,
  selectedCollectionDetailStatusId: 0,
};

export const revenueSlice = createSlice({
  name: "revenue",
  initialState,
  reducers: {
    updateRevenue: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // get payment kpi
    builder.addCase(getPaymentKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.paymentKPI = action.payload;
    });
    builder.addCase(getPaymentKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get payment list
    builder.addCase(getPaymentList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentList.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.paymentList = action.payload;
    });
    builder.addCase(getPaymentList.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get payment details
    builder.addCase(getPaymentDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.paymentDetails = action.payload.response;
    });
    builder.addCase(getPaymentDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get collection kpi
    builder.addCase(getCollectionKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.collectionKPI = action.payload;
    });
    builder.addCase(getCollectionKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get collection list
    builder.addCase(getCollectionList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionList.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.collectionList = action.payload;
    });
    builder.addCase(getCollectionList.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get payment details
    builder.addCase(getCollectionDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.collectionDetails = action.payload.response;
    });
    builder.addCase(getCollectionDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // update payment status
    builder.addCase(updatePaymentStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePaymentStatus.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updatePaymentStatus.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateRevenue } = revenueSlice.actions;

export default revenueSlice.reducer;
