import React from "react";
import BatteryLowIcon, {
  BatteryHighIcon,
  BatteryNeutralIcon,
  BatteryNoStatusIcon,
} from "../../Common/Svgs/BatteryChargeIcon";

const BatteryPercentage = ({ percentage, chargingStatus }) => {
  let batteryIcon;
  let batteryColor;

  if (percentage >= 0 && percentage <= 24) {
    batteryIcon = <BatteryLowIcon />;
    batteryColor = "text-alert-red";
  } else if (percentage >= 25 && percentage <= 50) {
    batteryIcon = <BatteryNeutralIcon />;
    batteryColor = "text-warning";
  } else if (percentage >= 51 && percentage <= 100) {
    batteryIcon = <BatteryHighIcon />;
    batteryColor = "text-primary";
  } else if (percentage === -1) {
    batteryIcon = <BatteryNoStatusIcon />;
  } else {
    batteryIcon = <BatteryNoStatusIcon />;
  }

  return (
    <div>
      <div className="flex items-center gap-x-2">
        {percentage !== -1 ? batteryIcon : null}
        <div
          className={`text-xs font-medium font-poppins leading-4.5 ${batteryColor}`}
        >
          {percentage === -1 ? "--" : `${Math.round(parseFloat(percentage))}%`}
        </div>
      </div>
      <div className="font-light capitalize font-poppins text-10 text-dark-grey-text">
        {chargingStatus}
      </div>
    </div>
  );
};

export default BatteryPercentage;
