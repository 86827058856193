/* eslint-disable radix */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import { TrashSecondaryIcon } from "../../../Common/Svgs/TrashIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import {
  deleteTrikeV3,
  getTrikesKPI,
  getTrikesV3,
} from "../../../Redux/APIs/trikesAPI";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import { TRIKE_STATUS } from "../Data";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";

const ongoingTripStatus = [
  TRIKE_STATUS.ON_TRIP,
  TRIKE_STATUS.OUT_OF_GEOFENCE,
  TRIKE_STATUS.DISPATCHED,
];

const DeleteTrikeModal = ({
  visible,
  onCancel,
  trikeCurrentPage,
  setTrikeCurrentPage,
}) => {
  const dispatch = useDispatch();

  const { deleteTrikeModal, currentTrike } = useSelector(
    (state) => state.trikes,
  );

  const onConfirm = async () => {
    await dispatch(deleteTrikeV3({ trikeId: deleteTrikeModal.id }));

    const response = await dispatch(
      getTrikesV3({
        offset: ((trikeCurrentPage || 1) - 1) * DEFAULT_LIMIT,
        limit: DEFAULT_LIMIT,
      }),
    );

    // If no records exist for the current page, move to the previous page
    if (!response.payload.result.length && trikeCurrentPage > 1) {
      const previousPage = trikeCurrentPage - 1;
      setTrikeCurrentPage(previousPage);

      await dispatch(
        getTrikesV3({
          offset: (previousPage - 1) * DEFAULT_LIMIT,
          limit: DEFAULT_LIMIT,
        }),
      );
    }

    dispatch(getTrikesKPI());

    dispatch(
      updateTrikes({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "1 Trike deleted.",
            description: `Trike ${
              deleteTrikeModal?.registrationNo || "1 trike"
            } was deleted successfully.`,
          },
        },
      }),
    );

    onCancel();
  };

  const getBody = () => {
    if (ongoingTripStatus.includes(+currentTrike.status)) {
      return (
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Cannot delete trike
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            The trike is currently on trip.
          </p>
        </div>
      );
    }

    return (
      <div className="px-2 mt-5">
        <p className="text-base font-medium text-alert-red font-poppins">
          Delete trike
        </p>
        <p className="mt-2 text-xs font-normal text-black font-aileron">
          Are you sure you want to delete this trike from the system?
        </p>
        <p className="mt-2 text-xs font-normal text-black font-aileron">
          This action cannot be undone.
        </p>
      </div>
    );
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>

            <CustomRoundedImage
              size="w-12 h-12"
              image={<TrikeIcon className="text-white" />}
              background="bg-primary"
            />
          </div>

          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        {getBody()}

        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>

          {/* do not show the confirm button if trike is currently on trip or
          dispatch */}
          {!ongoingTripStatus.includes(+currentTrike.status) && (
            <Button colors="alert" size="primary" onClick={onConfirm}>
              Confirm
            </Button>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteTrikeModal;
