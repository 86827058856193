/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import { getTripsForDriverV3 } from "../../../Redux/APIs/tripsAPI";
import { updateQrCode } from "../../../Redux/Slices/qrcode.slice";
import { QR_CODE_TYPE } from "../../QRCode/data";
import {
  getDriversV3,
  suspendOrResumeDriverV3,
} from "../../../Redux/APIs/usersAPI";
import useThreeDotsForDrivers from "./useThreeDotsForDrivers";
import useThreeDotsForFarmers from "./useThreeDotsForFarmers";

const ListDropdown = ({ detail, type = "driver" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { driverThreeDotsPopoverId, farmerThreeDotsPopoverId } = useSelector(
    (state) => state.users,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [open, setOpen] = useState(false);

  // fetch options based on type
  const options =
    type === "driver"
      ? useThreeDotsForDrivers().filter((item) => item.hasPermission)
      : useThreeDotsForFarmers().filter((item) => item.hasPermission);

  const getFilteredOptions = () => {
    if (type === "driver") {
      let localOptions = [];

      // if the driver is suspended, option should show resume, else show suspend
      if (detail.isSuspended) {
        const filteredOptions = options.filter((item) => item.id !== 4);
        localOptions = [...filteredOptions];
      } else {
        const filteredOptions = options.filter((item) => item.id !== 5);
        localOptions = [...filteredOptions];
      }
      return localOptions;
    }

    // return all the options in case of drivers
    return options;
  };

  const onOptionClick = async (optionId) => {
    switch (optionId) {
      case 1:
        if (type === "driver") {
          setOpen(false);

          await dispatch(getTripsForDriverV3({ driverId: detail.id }));

          dispatch(
            updateUsers({
              key: "tripDetailsDrawer",
              value: {
                visible: true,
                driverName: `${detail.firstName} ${detail.lastName}`,
              },
            }),
          );
        } else {
          dispatch(
            updateUsers({
              key: "currentFarmer",
              value: detail,
            }),
          );
          navigate(`/edit-partner/${detail.id}`);
        }
        break;

      case 2:
        if (type === "driver") {
          dispatch(
            updateUsers({
              key: "currentDriver",
              value: detail,
            }),
          );
          navigate(`/edit-driver/${detail.id}`);
        } else {
          setOpen(false);

          dispatch(
            updateQrCode({
              key: "viewModal",
              value: {
                visible: true,
                data: {
                  assetId: `${detail.firstName} ${detail.lastName}`,
                  assetType: QR_CODE_TYPE.FARMER,
                  metaData: {
                    farmerid: detail.id,
                    contactno: detail.contactNo,
                    collectionpointid: detail.locationId,
                    collectionpointname: detail.locationName,
                  },
                },
              },
            }),
          );
        }
        break;

      case 3:
        if (type === "driver") {
          dispatch(
            updateUsers({
              key: "currentDriver",
              value: detail,
            }),
          );

          setOpen(false);

          dispatch(
            updateUsers({
              key: "deleteDriverModal",
              value: {
                visible: true,
                id: detail.id,
                name: `${detail.firstName} ${detail.lastName}`,
              },
            }),
          );
        } else {
          setOpen(false);

          dispatch(
            updateUsers({
              key: "deleteFarmerModal",
              value: {
                visible: true,
                id: detail.id,
                name: `${detail.firstName} ${detail.lastName}`,
              },
            }),
          );
        }
        break;
      case 4:
        setOpen(false);
        await dispatch(
          suspendOrResumeDriverV3({ driverId: detail.id, suspend: 1 }),
        );

        await dispatch(getDriversV3());
        dispatch(
          updateUsers({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Driver Suspended",
                description: `${detail.firstName} ${detail.lastName} was suspended successfully.`,
              },
            },
          }),
        );

        break;

      case 5:
        setOpen(false);
        await dispatch(
          suspendOrResumeDriverV3({ driverId: detail.id, suspend: 0 }),
        );

        await dispatch(getDriversV3());
        dispatch(
          updateUsers({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Driver Resumed",
                description: `${detail.firstName} ${detail.lastName} was resumed successfully.`,
              },
            },
          }),
        );

        break;
      default:
        break;
    }
  };

  // handle on open change of popover
  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateUsers({
        key:
          type === "driver"
            ? "driverThreeDotsPopoverId"
            : "farmerThreeDotsPopoverId",
        value: detail.id,
      }),
    );
  };

  // get popover state
  const getOpenState = () => {
    if (type === "driver") {
      return open && detail.id === driverThreeDotsPopoverId;
    }

    return open && detail.id === farmerThreeDotsPopoverId;
  };

  // if three dots has no items (depending on the permission) then return empty fragments
  if (!getFilteredOptions().length) {
    return <></>;
  }

  // do not show the three dots options if the user does not have access in that hub
  if (detail.hubId && !userDetails.hubs.includes(+detail.hubId)) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={getOpenState()}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
            paddingTop: options.length && "4px",
            paddingBottom: options.length && "4px",
          }}
        >
          {getFilteredOptions().map((option) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group
              ${
                option.id === 3
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p
                className={`font-aileron text-xs font-semibold capitalize ${
                  option.id === 3
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
