/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import NotificationIcon, {
  NotificationIconWithRedDot,
} from "../../../Common/Svgs/NotificationIcon";
import NotificationItem from "./NotificationItem";
import {
  getNotifications,
  updateNotifications,
} from "../../../Redux/APIs/homeAPI";
import { updateHome } from "../../../Redux/Slices/home.slice";
import { client } from "../../../Utils/axiosClient";
import CustomTooltip from "../../Components/CustomTooltip";

const EmptyState = () => {
  return (
    <div className="flex justify-center w-full h-[300px] px-2">
      <div className="mt-[100px] flex flex-col items-center gap-y-2">
        <NotificationIcon className="fill-light-grey stroke-light-grey" />

        <p className="text-sm font-semibold font-aileron text-dark-gray">
          No new notifications
        </p>
      </div>
    </div>
  );
};

const NotificationPopup = () => {
  const dispatch = useDispatch();

  const { notificationPopupVisible, showNotificationDot, notifications } =
    useSelector((state) => state.home);

  const handleClearAll = async () => {
    try {
      const response = await client.post(
        `${process.env.REACT_APP_API_URL}/notifications/clear-notifications`,
      );

      if (response.data.action == 0) {
        dispatch(
          updateHome({ key: "cannotClearNotificationModal", value: true }),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateHome({
          key: "notificationPopupVisible",
          value: false,
        }),
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getNotifications());
    }, 10000);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (notificationPopupVisible) dispatch(updateNotifications());
  }, [dispatch, notificationPopupVisible]);

  return (
    <Popover
      placement="bottom"
      open={notificationPopupVisible}
      onOpenChange={(state) =>
        dispatch(updateHome({ key: "notificationPopupVisible", value: state }))
      }
      arrow={false}
      trigger="click"
      content={
        <div className="w-[312px] max-h-[600px]">
          <div className="flex items-center justify-between px-6 pt-6">
            <h1 className="text-base font-medium text-new-black font-poppins">
              Alerts
            </h1>

            <CustomTooltip tooltipTitle="Clear Notifications">
              <CloseIcon
                className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
                onClick={handleClearAll}
              />
            </CustomTooltip>
          </div>

          <div className="max-h-[400px] mt-2 overflow-y-scroll">
            {notifications.length ? (
              <>
                {/* in order to show the notifications in reverse order */}
                {notifications
                  .slice()
                  .sort((a, b) => b.id - a.id)
                  .map((item, index) => (
                    <NotificationItem
                      key={item.id}
                      notification={item}
                      isLast={index === notifications.length - 1}
                    />
                  ))}
              </>
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
      }
    >
      <button type="button">
        {showNotificationDot ? (
          <NotificationIconWithRedDot className="fill-none stroke-black" />
        ) : (
          <NotificationIcon className="fill-none stroke-black" />
        )}
      </button>
    </Popover>
  );
};

export default NotificationPopup;
