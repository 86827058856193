/* eslint-disable radix */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import { TrashSecondaryIcon } from "../../../Common/Svgs/TrashIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import UserIcon from "../../../Common/Svgs/UserIcon";
import { deleteDriverV3, getDriversV3 } from "../../../Redux/APIs/usersAPI";
import { updateUsers } from "../../../Redux/Slices/users.slice";

const LOCAL_DEFAULT_LIMIT = 10;

const DeleteDriverModal = ({
  visible,
  onCancel,
  driversCurrentPage,
  setDriversCurrentPage,
}) => {
  const dispatch = useDispatch();

  const { deleteDriverModal, currentDriver } = useSelector(
    (state) => state.users,
  );

  const onConfirm = async () => {
    await dispatch(deleteDriverV3({ driverId: deleteDriverModal.id }));

    const response = await dispatch(
      getDriversV3({
        offset: ((driversCurrentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      }),
    );

    // If no records exist for the current page, move to the previous page
    if (!response?.payload?.result?.length && driversCurrentPage > 1) {
      const previousPage = driversCurrentPage - 1;
      setDriversCurrentPage(previousPage);

      await dispatch(
        getDriversV3({
          offset: (previousPage - 1) * LOCAL_DEFAULT_LIMIT,
        }),
      );
    }

    await dispatch(
      updateUsers({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "1 Driver Deleted",
            description: `${
              deleteDriverModal?.name || "1 driver"
            } was deleted sucessfully.`,
          },
        },
      }),
    );

    onCancel();
  };

  const getBody = () => {
    if (currentDriver?.currentTrike) {
      return (
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Cannot delete driver
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            The driver is currently on a trip.
          </p>
        </div>
      );
    }

    return (
      <div className="px-2 mt-5">
        <p className="text-base font-medium text-alert-red font-poppins">
          Delete driver
        </p>
        <p className="mt-2 text-xs font-normal text-black font-aileron">
          Are you sure you want to delete this driver from the system?
        </p>
        <p className="mt-2 text-xs font-normal text-black font-aileron">
          This action cannot be undone.
        </p>
      </div>
    );
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
            <CustomRoundedImage
              size="w-12 h-12"
              image={<UserIcon className="stroke-white fill-none" />}
              background="bg-primary"
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        {getBody()}

        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>

          {!currentDriver?.currentTrike && (
            <Button colors="alert" size="primary" onClick={onConfirm}>
              Confirm
            </Button>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteDriverModal;
