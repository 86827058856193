/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Checkbox } from "antd";
import CustomInput from "../../../Core/Components/CustomInput";
import useSearch from "../../../Core/hooks/useSearch";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import Button from "../../../Core/Components/CustomButton";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import ToastMessageModal from "../ToastMessageModal";
import getBatteryStatus from "../../../Utils/getBatteryStatus";
import BatteryPercentageStatus from "../../Battery/phase3/BatteryPercentageStatus";
import { BATTERY_STATUS } from "../../Battery/Data";
import { BatteryEmptyState } from "./EmptyState";

const TripStep5 = () => {
  const dispatch = useDispatch();

  const { tripDetails, toastMessage, stops } = useSelector(
    (state) => state.trips,
  );

  const { allBatteries } = useSelector((state) => state.batteries);

  const [focus, setFocus] = useState(false);
  const [batteriesInSystem, setBatteriesInSystem] = useState([]);

  const { filteredData, search, setSearch } = useSearch(batteriesInSystem, [
    "value",
  ]);

  // find the batteries currently in hub station (hub station == origin while creating the trip)
  const getBatteries = useCallback(() => {
    let batteryStatusToCheck = [];

    // if creating a new trip and start date = current date, return only hub station batteries
    if (dayjs(tripDetails.startDate).isSame(dayjs(), "day")) {
      batteryStatusToCheck = [BATTERY_STATUS.HUB_STATION];
    }
    // if trip date = future date, return all the batteries
    else {
      batteryStatusToCheck = [
        BATTERY_STATUS.ON_A_TRIP,
        BATTERY_STATUS.DISPATCHED,
        BATTERY_STATUS.HUB_STATION,
        BATTERY_STATUS.IN_TRANSIT,
      ];
    }

    const hubStationBatteries = allBatteries
      .filter(
        (item) =>
          batteryStatusToCheck.includes(+item.status) &&
          item.currentHubId == stops[0].locationId,
      )
      .map((battery) => ({
        ...battery,
        id: battery.id,
        value: battery.registrationNo,
      }));

    setBatteriesInSystem(hubStationBatteries);
  }, [allBatteries, stops, tripDetails]);

  const handleSelection = (battery) => {
    const batteries = tripDetails?.batteries;

    const updatedBatteries = batteries.includes(battery.id)
      ? batteries.filter((id) => id !== battery.id)
      : [...batteries, battery.id];

    dispatch(
      updateTrips({
        key: "tripDetails",
        value: {
          ...tripDetails,
          batteries: updatedBatteries,
        },
      }),
    );
  };

  const onClearSelection = () => {
    dispatch(
      updateTrips({
        key: "tripDetails",
        value: { ...tripDetails, batteries: [] },
      }),
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTrips({
          key: "toastMessage",
          value: { ...toastMessage, visible: false },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, toastMessage]);

  useEffect(() => {
    getBatteries();
  }, [getBatteries]);

  return (
    <div className="w-full">
      {batteriesInSystem.length === 0 ? (
        <BatteryEmptyState />
      ) : (
        <div className="w-full h-full">
          <div className="flex items-center gap-x-3">
            <CustomInput
              intent="searchHome"
              prefix={
                <img src="/assets/icons/search.svg" alt="" className="mr-2" />
              }
              value={search}
              focus={focus}
              border={search.length > 0 ? "success" : "none"}
              placeholderText="filterSelected"
              placeholder="Search Battery ID"
              onChange={(e) => setSearch(e.target.value)}
              suffix={
                search.length > 0 ? (
                  <CloseIcon
                    className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                    onClick={() => {
                      setSearch("");
                      setFocus(false);
                    }}
                  />
                ) : null
              }
            />
            <Button
              size="primary"
              width="generateUser"
              colors="grey"
              onClick={onClearSelection}
            >
              Clear Selection
            </Button>
          </div>

          <div className="h-[calc(100vh-374px)] mt-2 overflow-y-scroll">
            {filteredData.map((battery) => {
              const selected = tripDetails?.batteries?.includes(battery.id);

              return (
                <div
                  className={`flex justify-between h-10 px-6 border-b cursor-pointer hover:bg-primary-20 border-light-grey 
                  ${selected ? "bg-primary-20" : ""}`}
                  key={battery.id}
                  onClick={() => handleSelection(battery)}
                >
                  <div className="flex items-center gap-x-3">
                    <Checkbox checked={selected} />
                    <p className="text-xs font-semibold text-black capitalize font-aileron">
                      {battery.value}
                    </p>
                  </div>

                  <div className="flex items-center gap-x-12">
                    <div className="w-[112px] flex justify-end">
                      {getBatteryStatus(battery.status)}
                    </div>

                    <div className="w-[52px]">
                      <BatteryPercentageStatus
                        detail={battery}
                        showChargingStatus={false}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <ToastMessageModal
        visible={toastMessage.visible}
        onCancel={() =>
          dispatch(
            updateTrips({
              key: "toastMessage",
              value: { ...toastMessage, visible: false },
            }),
          )
        }
      />
    </div>
  );
};
export default TripStep5;
