/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { json2csv } from "json-2-csv";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import UserIcon from "../../../Common/Svgs/UserIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import { client } from "../../../Utils/axiosClient";
import { rowTitleKeyMap } from "../Data";
import { getFarmersV3 } from "../../../Redux/APIs/usersAPI";

const ExportFarmerModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const { selectedFarmersForExport } = useSelector((state) => state.users);

  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);

      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/farmers/export-farmers`,
        {
          params: {
            farmerIds: selectedFarmersForExport,
          },
        },
      );

      const data = json2csv(response.data.response, {
        emptyFieldValue: "--",
        keys: rowTitleKeyMap,
      });

      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);

        link.setAttribute("href", url);

        link.setAttribute("download", `${new Date()}.csv`);

        link.style.visibility = "hidden";

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      }

      setTimeout(() => {
        dispatch(
          updateUsers({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "Data exported successfully",
                description:
                  "Data for selected farmers was exported successfully.",
              },
            },
          }),
        );
      }, 2000);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateUsers({
          key: "selectedFarmersForExport",
          value: [],
        }),
      );

      dispatch(
        getFarmersV3({
          offset: 0,
        }),
      );

      onCancel();
      setLoading(false);
    }
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <CustomRoundedImage
              size="w-12 h-12"
              image={<UserIcon className="stroke-white fill-none" />}
              background="bg-primary"
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-black font-poppins">
            Export as CSV
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to export selected farmers data as csv?
          </p>
          {/* <p className="mt-2 text-xs font-normal text-black font-aileron">
            This action cannot be undone.
          </p> */}
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>

          <Button colors="primary" size="primary" onClick={onConfirm}>
            <div className="flex items-center gap-x-3">
              <p>Confirm</p>

              {loading ? (
                <Spin
                  spinning={loading}
                  indicator={<LoadingOutlined />}
                  className="text-white"
                  size="small"
                />
              ) : null}
            </div>
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ExportFarmerModal;
