import React from "react";
import { PARTNER_ROLE } from "../Data";
import {
  getCollectionDetailStatus,
  getPaymentDetailStatus,
} from "../../Revenue/getPaymentStatus";

export const PartnerTag = ({ role }) => {
  const isFarmer = parseInt(role, 10) === PARTNER_ROLE.FARMER;

  const getStyle = () => {
    if (isFarmer) {
      return "bg-yellow-10 text-yellow max-w-[60px]";
    }

    return "bg-green-10 text-green max-w-[74px]";
  };

  return (
    <div className={`py-1 px-2 rounded-md ${getStyle()}`}>
      <p className="font-poppins text-xs font-medium">
        {isFarmer ? "Farmer" : "Off-Taker"}
      </p>
    </div>
  );
};

export const PaymentStatus = ({ detail }) => {
  const isFarmer = parseInt(detail.role, 10) === PARTNER_ROLE.FARMER;

  if (detail.paymentStatus === 0) {
    return (
      <p className="text-xs font-semibold font-aileron leading-4.5 text-dark-grey-text">
        --
      </p>
    );
  }

  return (
    <div>
      {isFarmer
        ? getPaymentDetailStatus(detail.paymentStatus)
        : getCollectionDetailStatus(detail.paymentStatus)}
    </div>
  );
};
