import React from "react";

const ItemBody7 = ({ notification }) => {
  return (
    <p className="font-poppins text-xs font-light">
      Batteries are ready to check-in from{" "}
      <span className="font-medium text-primary capitalize">
        {notification?.metaData?.receiverHubName}.
      </span>{" "}
      Please reach the hub station to collect the batteries.
    </p>
  );
};

export default ItemBody7;
