/* eslint-disable no-console */
import { Popover } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TickIcon from "../../../Common/Svgs/TickIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { InfoCircleSecondaryIcon } from "../../../Common/Svgs/InfoCircleIcon";
import {
  getSystemUsers,
  getUserByEmail,
  updateUser,
} from "../../../Redux/APIs/systemUsersAPI";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import CustomInput from "../../../Core/Components/CustomInput";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS, SEED_ROLES } from "../../Login/constants";

const ShowOnlyRole = ({ detail }) => {
  return (
    <div>
      {detail?.roleId ? (
        <div className="flex items-center gap-x-2">
          <p className="font-aileron text-xs font-semibold text-primary capitalize">
            {detail?.roleName}
          </p>
        </div>
      ) : (
        <div className="flex items-center gap-x-2">
          <InfoCircleSecondaryIcon />

          <p className="font-aileron text-xs font-semibold text-yellow-dark capitalize">
            Assign role
          </p>
        </div>
      )}
    </div>
  );
};

const AssignRoleDropdown = ({ detail, setFilters }) => {
  const dispatch = useDispatch();

  const { systemRoles, userDetails } = useSelector(
    (state) => state.loggedInUser,
  );

  // check if the current logged in user is a super admin
  const isSuperAdmin = userDetails.roleId === SEED_ROLES.SUPER_ADMIN;

  // check if permission exists to edit the role of the users
  const hasPermission = usePermission([PERMISSIONS.SETTINGS_SYSTEM_USER_EDIT]);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const filteredData = () => {
    let roles = [...systemRoles];

    // if the current logged in user is not a super admin then do not show super admin role option
    if (!isSuperAdmin) {
      roles = roles.filter((item) => item.id !== SEED_ROLES.SUPER_ADMIN);
    }

    // filter data based on search
    if (search) {
      roles = roles.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return roles;
  };

  const handleClick = async (role) => {
    setOpen(false);
    setFilters((prev) => ({ ...prev, role: [] }));

    await dispatch(
      updateUser({
        email: detail.email,
        roleId: role.id,
        userId: detail.id,
      }),
    );

    await dispatch(getSystemUsers());

    // if the role was changed for the current user then fetch the latest permissions for the current user
    if (detail?.id === userDetails.userId) {
      await dispatch(getUserByEmail({ email: detail?.email }));
    }

    dispatch(
      updateLoggedInUser({
        key: "toastMessageModal",
        value: {
          visible: true,
          title: "Role Assigned",
          description: `${role.name} role was assigned successfully to ${detail?.firstName}.`,
        },
      }),
    );
  };

  // if the user role id is system role or the permission does not exist, do not show the assign role dropdown
  if (!hasPermission) {
    return <ShowOnlyRole detail={detail} />;
  }

  // if the current logged in user is not a super admin then he/she should not be able to change the role of other super admin
  if (detail?.roleId === SEED_ROLES.SUPER_ADMIN && !isSuperAdmin) {
    return <ShowOnlyRole detail={detail} />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1 max-h-[200px] overflow-y-scroll"
          style={{
            maxWidth: "250px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className="px-1">
            <CustomInput
              intent="searchHome"
              placeholder="Search Role"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {filteredData().map((role) => {
            const selected = detail?.roleId === role.id;

            return (
              <button
                type="button"
                className="w-full flex items-center justify-between gap-x-3 px-3 py-2 group hover:bg-background"
                key={role.id}
                onClick={() => handleClick(role)}
              >
                <p
                  className="font-aileron text-xs font-semibold capitalize group-hover:text-black"
                  style={{
                    color: selected ? "#000" : "#525252",
                  }}
                >
                  {role.name}
                </p>

                {selected ? (
                  <TickIcon size={16} className="text-primary" />
                ) : null}
              </button>
            );
          })}
        </div>
      }
    >
      <div>
        {detail?.roleId ? (
          <button type="button" className="flex items-center gap-x-2">
            <p className="font-aileron text-xs font-semibold text-primary capitalize">
              {detail?.roleName}
            </p>

            <ChevronIcon
              strokeWidth="1"
              className={`mt-0.5 w-4 h-4 stroke-primary ${
                open ? "-rotate-90" : "rotate-90"
              }`}
            />
          </button>
        ) : (
          <button type="button" className="flex items-center gap-x-2">
            <InfoCircleSecondaryIcon />

            <p className="font-aileron text-xs font-semibold text-yellow-dark capitalize">
              Assign role
            </p>

            <ChevronIcon
              strokeWidth="1"
              className={`mt-0.5 w-4 h-4 stroke-yellow-dark ${
                open ? "-rotate-90" : "rotate-90"
              }`}
            />
          </button>
        )}
      </div>
    </Popover>
  );
};

export default AssignRoleDropdown;
