import React from "react";
import BatteryBar from "./BatteryBar";

const EmptyState = () => {
  return (
    <div className="w-full h-full my-4">
      <div className="absolute right-12 top-[52px]">
        <p className="text-2xl font-poppins font-semibold">0 %</p>
      </div>

      <div className="flex items-center justify-between">
        <p className="text-xs font-aileron font-normal text-dark-gray capitalize">
          No Batteries Added
        </p>
      </div>

      <div className="flex items-start justify-between w-full h-full">
        <div className="pt-4.5 flex flex-1 items-center gap-x-1 w-full h-full">
          {[1, 2, 3].map((item, index) => {
            return (
              <div className="flex items-center flex-1 w-full h-full">
                <div className="w-full h-full flex-1 mr-1">
                  <BatteryBar
                    key={item}
                    width="64px"
                    height="100%"
                    text={`B${index + 1}`}
                    containerClassName="border border-light-grey"
                  />
                </div>

                {index < 2 && (
                  <div className="w-4 h-4 flex-1 mx-auto">
                    <img
                      src="/assets/icons/details/add.svg"
                      alt=""
                      className="w-4 h-4 mx-auto"
                    />
                  </div>
                )}
              </div>
            );
          })}

          <div className="flex-1" />
          <img
            src="/assets/icons/details/arrow-full.svg"
            alt=""
            className="w-4 h-4 rotate-180"
          />
          <div className="flex-1" />
        </div>

        <div className="flex-1 w-full h-full max-w-[100px]">
          <BatteryBar
            width="100%"
            height="100%"
            containerClassName="border border-light-grey"
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
