/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../Core/Components/CustomTable";
import { trikesDetailsColumns } from "./Data/TrikesDetailData";
import TrikeIcon from "../../Common/Svgs/TrikeIcon";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import { getTripsForTrikeV3 } from "../../Redux/APIs/tripsAPI";

const LOCAL_DEFAULT_LIMIT = 10;

const TrikeTripTable = () => {
  const dispatch = useDispatch();

  const { currentTrike } = useSelector((state) => state.trikes);

  const { tripsForTrike } = useSelector((state) => state.trips);

  const [currentPage, setCurrentPage] = useState(1);

  const tableRef = useRef(null);

  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: LOCAL_DEFAULT_LIMIT,
      trikeId: currentTrike.id,
    };

    dispatch(getTripsForTrikeV3(filterObj));
  }, [dispatch, currentPage]);

  return (
    <div
      className={`h-[calc(100vh-544px)] ${
        tripsForTrike?.result?.length && "overflow-y-scroll"
      }`}
      ref={tableRef}
    >
      <CustomTable
        // scrollX={0}
        // scrollY={tableScrollYHeight}
        disableScroll
        columns={trikesDetailsColumns}
        data={tripsForTrike?.result}
        emptyState={<TrikeIcon size={16} className="text-dark-gray" />}
        customTableClassName="w-full"
        pagination={{
          pageSize: LOCAL_DEFAULT_LIMIT,
          total: tripsForTrike?.totalRecords,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPage(page);
          },
          current: currentPage,
          itemRender: (current, type, originalElement) => {
            const lastPage = tripsForTrike.totalRecords / current;

            if (type === "prev" && current === 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "prev" && current > 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next" && lastPage <= 10) {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{ pointerEvents: "none" }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next") {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            return originalElement;
          },
        }}
      />
    </div>
  );
};

export default TrikeTripTable;
