import React from "react";
import CustomTooltip from "../../../Core/Components/CustomTooltip";

const AssociatedWithColumn = ({ record }) => {
  if (record?.trikeRegistrationNo) {
    return (
      <div>
        <CustomTooltip tooltipTitle={record.trikeRegistrationNo}>
          <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
            {record.trikeRegistrationNo.length > 15
              ? `${record.trikeRegistrationNo.substring(0, 15)}...`
              : record.trikeRegistrationNo}
          </div>
        </CustomTooltip>
      </div>
    );
  }

  return (
    <div>
      <CustomTooltip tooltipTitle={record.currentHubName}>
        <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
          {record.currentHubName.length > 15
            ? `${record.currentHubName.substring(0, 15)}...`
            : record.currentHubName}
        </div>
      </CustomTooltip>
    </div>
  );
};

export default AssociatedWithColumn;
