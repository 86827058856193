import React from "react";
import { useSelector } from "react-redux";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import { BatteryPackIcon } from "../../../Common/Svgs/BatteryIcon";
import EmptyState from "./EmptyState";
import MappedBatteries from "./MappedBatteries";

const TrikeBatteries = () => {
  const { currentTrike } = useSelector((state) => state.trikes);

  const getBody = () => {
    if (!currentTrike.batteryPack.length) return <EmptyState />;
    return <MappedBatteries />;
  };

  return (
    <div className="w-full h-full border-[0.5px] border-light-grey rounded-md p-4 flex flex-col justify-between">
      <div className="flex items-center justify-between">
        <div className="flex justify-start items-center gap-x-2.5">
          <CustomRoundedImage
            background="bg-primary"
            image={<BatteryPackIcon className="text-white" />}
          />
          <div className="font-aileron font-normal text-dark-gray text-xs leading-4.5">
            Battery Pack
          </div>
        </div>

        <div className="flex justify-start items-start gap-x-2.5">
          <img src="/assets/icons/details/discharging-grey.svg" alt="" />
          <div className="font-aileron font-normal text-dark-gray text-xs leading-4.5">
            Discharging
          </div>
        </div>
      </div>

      {getBody()}

      {/* <div className="grid items-center grid-cols-5">
        <div className="flex flex-col col-span-1">
          <div className="font-aileron font-semibold text-2xl leading-9 text-center py-2.25 relative z-10">
            {currentTrike.batteryPack}%
          </div>
          <div className="w-[106px] h-[198px]">
            <CustomProgressBar
              text=""
              current={
                currentTrike.batteryPack ? currentTrike?.batteryPack : "N/A"
              }
              color="bg-primary"
              textColor="text-white"
            />
          </div>
        </div>
        <img
          src="/assets/icons/details/arrow-full.svg"
          alt=""
          className="mx-auto mt-[46px]"
        />
        <div className="col-span-3 pr-[2px]">
          <div className="mt-5">
            <div className="font-aileron font-light text-dark-gray text-xs leading-4.5">
              Batteries
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex flex-col items-center gap-y-1">
                <p className="text-xs font-aileron font-seimbold text-dark-gray">
                  {currentTrike.batteryPack
                    ? `${currentTrike?.batteryPack} %`
                    : "N/A"}
                </p>

                <div
                  className="w-[74px] h-[182px] cursor-pointer"
                  // onClick={() => {
                  //   if (
                  //     currentTrike?.associatedBatteries[0] !== null
                  //   ) {
                  //     navigate(
                  //       `/batteries/${currentTrike?.associatedBatteries[0]}`,
                  //     );
                  //   }
                  // }}
                >
                  <CustomProgressBar
                    text="B1"
                    current={currentTrike?.batteryPack || 0}
                    color="bg-battery-orange"
                  />
                </div>
              </div>
              <img src="/assets/icons/details/add.svg" alt="" className="" />
              <div className="flex flex-col items-center gap-y-1">
                <p className="text-xs font-aileron font-seimbold text-dark-gray">
                  {currentTrike.batteryPack
                    ? `${currentTrike?.batteryPack} %`
                    : "N/A"}
                </p>
                <div
                  className="w-[74px] h-[182px] cursor-pointer"
                  // onClick={() => {
                  //   if (
                  //     currentTrike?.associatedBatteries[1] !== null
                  //   ) {
                  //     navigate(
                  //       `/batteries/${currentTrike?.associatedBatteries[1]}`,
                  //     );
                  //   }
                  // }}
                >
                  <CustomProgressBar
                    text="B2"
                    current={currentTrike?.batteryPack || 0}
                    color="bg-primary-20"
                  />
                </div>
              </div>

              <img src="/assets/icons/details/add.svg" alt="" />
              <div className="flex flex-col items-center gap-y-1">
                <p className="text-xs font-aileron font-seimbold text-dark-gray">
                  {currentTrike.batteryPack
                    ? `${currentTrike?.batteryPack} %`
                    : "N/A"}
                </p>
                <div
                  className="w-[74px] h-[182px] cursor-pointer"
                  // onClick={() => {
                  //   if (
                  //     currentTrike?.associatedBatteries[2] !== null
                  //   ) {
                  //     navigate(
                  //       `/batteries/${currentTrike?.associatedBatteries[2]}`,
                  //     );
                  //   }
                  // }}
                >
                  <CustomProgressBar
                    text="B3"
                    current={currentTrike?.batteryPack || 0}
                    color="bg-light-alert-red"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TrikeBatteries;
