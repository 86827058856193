/* eslint-disable no-useless-return */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import StepProgress, { StepDescription } from "./StepProgress";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import TripStep1 from "./TripStep1";
import TripStep2 from "./TripStep2";
import TripStep3 from "./TripStep3";
import TripStep4 from "./TripStep4";
import { resetTrips, updateTrips } from "../../../Redux/Slices/trips.slice";
import {
  HEADER_STEP_1,
  HEADER_STEP_2,
  HEADER_STEP_3,
  HEADER_STEP_4,
  HEADER_STEP_5,
} from "../Data/TripConstants";
import checkValidaton from "./validationForSteps";
import { createTrip, editTrip } from "../../../Redux/APIs/tripsAPI";
import TripStep1Modal from "./TripStep1Modal";
import CancelTripModal from "./CancelTripModal";
import TripStep5 from "./TripStep5";

const handleResetState = ({ dispatch }) => {
  dispatch(resetTrips(["tripDetails"]));
  dispatch(resetTrips(["stops"]));
};

const handleToastMessage = ({ dispatch, toastMessage, type }) => {
  dispatch(
    updateTrips({
      key: "toastMessage",
      value: {
        ...toastMessage,
        visible: true,
        type,
      },
    }),
  );
};

const handleResponse = (
  response,
  dispatch,
  navigate,
  toastMessage,
  operationType,
) => {
  if (operationType === "create") {
    if (response.payload.message === "Trip created successfully") {
      navigate("/trips");
      handleResetState({ dispatch });
      handleToastMessage({ dispatch, toastMessage, type: "tripCreated" });
    }

    if (response.payload.response.data.message === "Trip already exists") {
      navigate("/trips");
      handleResetState({ dispatch });
      handleToastMessage({ dispatch, toastMessage, type: "tripExists" });
    }

    return;
  }
  if (operationType === "edit") {
    navigate("/trips");
    handleResetState({ dispatch });
    handleToastMessage({ dispatch, toastMessage, type: "tripUpdated" });

    return;
  }

  return;
};

const TripScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    currentStep,
    stops,
    tripDetails,
    validationForSteps,
    toastMessage,
    editTripDetails,
    tripStep1Modal,
    cancelTripModal,
    isRouteOptimised,
    optimisedRouteDistance,
    optimisedRouteTime,
    calculatedRouteDistance,
    calculatedRouteTime,
  } = useSelector((state) => state.trips);

  const [currentHeader, setCurrentHeader] = useState(HEADER_STEP_1);

  const changeStepHeader = (step) => {
    switch (step) {
      case 1:
        setCurrentHeader(HEADER_STEP_1);
        break;
      case 2:
        setCurrentHeader(HEADER_STEP_2);
        break;
      case 3:
        setCurrentHeader(HEADER_STEP_3);
        break;
      case 4:
        setCurrentHeader(HEADER_STEP_4);
        break;
      case 5:
        setCurrentHeader(HEADER_STEP_5);
        break;
      default:
        setCurrentHeader(HEADER_STEP_1);
    }
  };

  useEffect(() => {
    changeStepHeader(currentStep);
  }, [currentStep]);

  useEffect(() => {
    dispatch(updateTrips({ key: "isRouteOptimised", value: false }));

    if (!editTripDetails.flag) {
      dispatch(resetTrips(["calculatedRouteDistance"]));
      dispatch(resetTrips(["calculatedRouteTime"]));
    }
  }, [dispatch, stops, editTripDetails]);

  const renderScreen = (step) => {
    switch (step) {
      case 1:
        return <TripStep1 />;
      case 2:
        return <TripStep2 />;
      case 3:
        return <TripStep3 />;
      case 4:
        return <TripStep4 />;
      case 5:
        return <TripStep5 />;
      default:
        return <TripStep1 />;
    }
  };

  const handleCreate = async () => {
    const commonPayload = {
      stops,
      startDate: tripDetails.startDate,
      timeSlot: tripDetails.timeSlot,
      driverName: tripDetails.driverName,
      driverId: tripDetails.driverId,
      trikeId: tripDetails.trikeId,
      trikeRegistrationNo: tripDetails.trikeRegistrationNo,
      distance: isRouteOptimised
        ? optimisedRouteDistance
        : calculatedRouteDistance,
      time: isRouteOptimised ? optimisedRouteTime : calculatedRouteTime,
      batteries: tripDetails.batteries,
    };

    try {
      if (editTripDetails.flag) {
        const payload = { ...commonPayload, tripId: editTripDetails.tripId };
        const response = await dispatch(editTrip(payload));
        handleResponse(response, dispatch, navigate, toastMessage, "edit");
        return;
      }

      // } else {
      const payload = {
        ...commonPayload,
        endDate: tripDetails.endDate,
        occurence: tripDetails.occurence,
        daysOfOccurence: tripDetails.daysOfOccurence,
      };
      const response = await dispatch(createTrip(payload));
      handleResponse(response, dispatch, navigate, toastMessage, "create");
      // }
    } catch (err) {
      console.log(err, "error in creating trip");
    }
  };

  const handleNext = async () => {
    // if last step, create the trip
    if (currentStep === 5) {
      handleCreate();
      return;
    }

    // check for validations for step 1, 2 and 3 (step 4 and 5 are optional so no validation required)
    if (currentStep < 5) {
      const validationPassed = checkValidaton(
        currentStep,
        stops,
        dispatch,
        tripDetails,
        updateTrips,
        validationForSteps,
      );

      if (validationPassed) {
        if (currentStep === 1 && !isRouteOptimised) {
          dispatch(updateTrips({ key: "tripStep1Modal", value: true }));
        } else {
          dispatch(
            updateTrips({
              key: "currentStep",
              value: currentStep + 1,
            }),
          );
        }
      }
    }
  };

  const handleCancel = () => {
    if (currentStep === 1) {
      dispatch(updateTrips({ key: "cancelTripModal", value: true }));
      return;
    }

    dispatch(
      updateTrips({
        key: "currentStep",
        value: currentStep - 1,
      }),
    );
  };

  return (
    <CustomLayout pageName="Trips">
      <div className="h-full bg-background-white">
        <div className="flex justify-between py-[13px] px-6 border-b border-light-grey">
          <StepProgress
            currentStep={currentStep}
            currentHeader={currentHeader}
          />
          <button
            type="button"
            onClick={() =>
              dispatch(updateTrips({ key: "cancelTripModal", value: true }))
            }
          >
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        <div className="flex items-center justify-between pb-6 mx-6 mt-6 border-b border-light-grey">
          <StepDescription step={currentHeader} />

          <div className="flex items-center gap-x-3">
            <Button
              size="primary"
              width="generateUser"
              colors="grey"
              onClick={handleCancel}
            >
              {currentStep === 1 ? "Cancel" : "Previous"}
            </Button>

            <Button
              size="primary"
              width="generateUser"
              type="submit"
              onClick={handleNext}
            >
              {currentStep === 5 && editTripDetails.flag
                ? "Save"
                : currentStep === 5
                ? "Create Trip"
                : "Next"}
            </Button>
          </div>
        </div>
        <div className="w-full h-full px-6 pt-6">
          {renderScreen(currentStep)}
        </div>
      </div>
      <TripStep1Modal
        visible={tripStep1Modal}
        onCancel={() =>
          dispatch(updateTrips({ key: "tripStep1Modal", value: false }))
        }
      />
      <CancelTripModal
        visible={cancelTripModal}
        onCancel={() =>
          dispatch(updateTrips({ key: "cancelTripModal", value: false }))
        }
      />
    </CustomLayout>
  );
};

export default TripScreen;
