import React, { useState } from "react";
import { Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import CustomDropdown from "../../Core/Components/CustomDropdown";
import { updateTrikes } from "../../Redux/Slices/trikes.slice";
import Button from "../../Core/Components/CustomButton";
import useMoreColumnsData from "./Data/useMoreColumnsData";

const MoreColumnsDropdown = () => {
  const dispatch = useDispatch();

  const { selectedColumns } = useSelector((state) => state.trikes);

  const [open, setOpen] = useState(false);
  const [moreColumnsCount, setMoreColumnsCount] = useState(0);

  // filter - check whether to include or exclude mobilise and tracker columns
  // map - hasPermission should not be passed down to dropdown items
  const moreColumns = useMoreColumnsData()
    .filter((item) => item.hasPermission)
    .map((column) => ({
      key: column.key,
      label: column.label,
    }));

  const handleClick = (e) => {
    if (selectedColumns.includes(e.key)) {
      setMoreColumnsCount((prev) => prev - 1);

      dispatch(
        updateTrikes({
          key: "selectedColumns",
          value: selectedColumns.filter((item) => item !== e.key),
        }),
      );
    } else {
      setMoreColumnsCount((prev) => prev + 1);
      dispatch(
        updateTrikes({
          key: "selectedColumns",
          value: [...selectedColumns, e.key],
        }),
      );
    }
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div className="shadow-moreFilters bg-background-white rounded-md max-h-[250px] overflow-y-scroll">
          {moreColumns.map((item) => (
            <div
              className="group cursor-pointer hover:bg-background-white-hover"
              onClick={() => handleClick(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      }
    >
      {moreColumnsCount > 0 ? (
        <Button padding="filter" size="filter" width="moreColumns">
          <div className="flex item-center gap-x-3">
            <div className="font-poppins text-xs leading-4.5 font-medium">
              More Columns
            </div>
            <div className="w-4 h-4 bg-white text-new-black font-poppins font-medium text-10 leading-3.75 rounded-full flex items-center justify-center">
              {moreColumnsCount}
            </div>
          </div>
        </Button>
      ) : (
        <Button size="filter" colors="white" padding="filter">
          <div className="flex items-center justify-start gap-x-3">
            <img src="/assets/icons/add-square.svg" alt="" />
            <div>More Columns</div>
          </div>
        </Button>
      )}
    </Popover>
  );

  // return (
  //   <CustomDropdown
  //     items={moreColumns}
  //     handleClick={(e) => handleClick(e)}
  //     placement="bottomLeft"
  //     customHeight="h-[300px] hide-scrollbar rounded-lg bg-background-white"
  //     trigger="click"
  //   >
  //     {moreColumnsCount > 0 ? (
  //       <Button padding="filter" size="filter" width="moreColumns">
  //         <div className="flex item-center gap-x-3">
  //           <div className="font-poppins text-xs leading-4.5 font-medium">
  //             More Columns
  //           </div>
  //           <div className="w-4 h-4 bg-white text-new-black font-poppins font-medium text-10 leading-3.75 rounded-full flex items-center justify-center">
  //             {moreColumnsCount}
  //           </div>
  //         </div>
  //       </Button>
  //     ) : (
  //       <Button size="filter" colors="white" padding="filter">
  //         <div className="flex items-center justify-start gap-x-3">
  //           <img src="/assets/icons/add-square.svg" alt="" />
  //           <div>More Columns</div>
  //         </div>
  //       </Button>
  //     )}
  //   </CustomDropdown>
  // );
};

export default MoreColumnsDropdown;
