/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { LOCATION_TYPE } from "../../Location/Data";
import { getTrikesKPI, getTrikesV3 } from "../../../Redux/APIs/trikesAPI";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import { client } from "../../../Utils/axiosClient";
import { DEFAULT_LIMIT } from "../../../Utils/constants";

const MoveToHubStationModal = ({ visible, onCancel, currentPage }) => {
  const dispatch = useDispatch();

  const { moveToHubStationModal } = useSelector((state) => state.trikes);

  const { allLocations } = useSelector((state) => state.locations);

  const hubs = allLocations
    .filter((location) => location.type === LOCATION_TYPE.HUB_STATION)
    .map((item) => ({ id: item.id, value: item.name }));

  const [selected, setSelected] = useState({
    id: hubs[0]?.id,
    value: hubs[0]?.value,
  });

  const handleSelect = (hub) => {
    setSelected(hub);
  };

  const handleMoveTrike = async () => {
    dispatch(
      updateTrikes({
        key: "moveToHubStationModal",
        value: { ...moveToHubStationModal, visible: false },
      }),
    );

    try {
      const payload = {
        trikeId: [moveToHubStationModal?.trike?.id],
        hubId: selected?.id,
      };
      dispatch(updateTrikes({ key: "loading", value: true }));

      await client.post(
        `${process.env.REACT_APP_API_URL}/trike/move-to-parking-lot`,
        payload,
      );

      await dispatch(
        getTrikesV3({
          offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
          limit: DEFAULT_LIMIT,
        }),
      );

      dispatch(getTrikesKPI());

      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "1 Trike Moved To Hub Station.",
              description: `Trike ${moveToHubStationModal?.trike?.registrationNo} was moved to ${selected.value} successfully.`,
            },
          },
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      setSelected({});
      onCancel();
    }
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="312px">
      <div
        className="rounded-xl bg-background-white w-full p-4"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between">
          <h1 className="font-poppins text-base font-medium capitalize">
            Move to Hub Station
          </h1>

          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        <div className="mt-6">
          <p className="font-aileron text-xs font-normal text-dark-gray mb-1.5">
            Please select a Hub station
          </p>

          <PopoverDropdown
            value={selected.value}
            valueStyle={{ width: "100%" }}
            contentContainerStyle={{ width: "280px" }}
            options={hubs}
            enableSearch
            searchPlaceholder="Search Hub Station"
            onSelect={(data) => handleSelect(data)}
          />
        </div>

        <div className="mt-6 flex items-center gap-x-3">
          <button
            type="button"
            className="flex-1 py-3 px-4 rounded-md bg-light-grey"
            onClick={onCancel}
          >
            <p className="font-poppins text-xs font-medium">Cancel</p>
          </button>

          <button
            type="button"
            className="flex-1 py-3 px-4 rounded-md bg-primary hover:bg-primary-50"
            onClick={handleMoveTrike}
          >
            <p className="font-poppins text-xs font-medium text-white">
              Move Trike
            </p>
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default MoveToHubStationModal;
