/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../Core/Components/CustomButton";
import CustomTags from "../../Core/Components/CustomTags";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import { client } from "../../Utils/axiosClient";
import { homeKpis, mapMarkerHeaders } from "./Data/HomeData";
import { PERMISSIONS } from "../Login/constants";

const MapHeader = ({ setFilteredLocations, setLocationsLoading }) => {
  const navigate = useNavigate();

  const { userDetails } = useSelector((state) => state.loggedInUser);
  const { kpiData } = useSelector((state) => state.home);

  const [filterMarkers, setFilterMarkers] = useState([]);

  const handleFilters = (id) => {
    if (filterMarkers.includes(id)) {
      setFilterMarkers(filterMarkers?.filter((item) => item !== id));
    } else {
      setFilterMarkers([...filterMarkers, id]);
    }
  };

  const getPermissions = (module) => {
    let hasPermission = false;

    switch (module) {
      case "trikes":
        hasPermission =
          userDetails?.permissions?.includes(PERMISSIONS.TRIKE_VIEW) ||
          userDetails?.permissions?.includes(PERMISSIONS.TRIKE_VIEW_AND_EDIT);

        break;

      case "batteries":
        hasPermission =
          userDetails?.permissions?.includes(PERMISSIONS.BATTERY_VIEW) ||
          userDetails?.permissions?.includes(PERMISSIONS.BATTERY_VIEW_AND_EDIT);

        break;

      case "drivers":
        hasPermission =
          userDetails?.permissions?.includes(PERMISSIONS.DRIVER_VIEW) ||
          userDetails?.permissions?.includes(PERMISSIONS.DRIVER_VIEW_AND_EDIT);

        break;

      case "inventory":
        hasPermission = userDetails?.permissions?.includes(
          PERMISSIONS.INVENTORY_VIEW,
        );

        break;

      default:
        break;
    }

    return hasPermission;
  };

  useEffect(() => {
    const fetchHomeFilters = async () => {
      try {
        setLocationsLoading(true);

        const response = await client.get(
          `${process.env.REACT_APP_API_URL}/home/home-filters`,
          {
            params: {
              filters: filterMarkers,
            },
          },
        );

        setFilteredLocations(response.data?.filteredLocations);
      } catch (err) {
        console.log(err);
      } finally {
        setLocationsLoading(false);
      }
    };

    fetchHomeFilters();
  }, [filterMarkers]);

  return (
    <div className="absolute z-20 w-full px-6 top-6">
      <div className="flex gap-x-3">
        {homeKpis(
          kpiData.trikes,
          kpiData.batteries,
          kpiData.drivers,
          kpiData.inventory,
        ).map((item) => {
          const hasPermission = getPermissions(item.text);

          return (
            <div
              role={hasPermission && "button"}
              key={item.id}
              className="p-3 w-full h-12 bg-white text-dark-grey-text shadow-button font-poppins text-xs font-medium capitalize rounded-md"
              onClick={() => {
                // user should be able to visit the page only if permission exists
                if (hasPermission) {
                  if (item.text === "drivers") {
                    navigate("/users");
                  } else {
                    navigate(`/${item.text}`);
                  }
                }
              }}
            >
              <div className="flex items-center justify-around w-full gap-x-4">
                <CustomTags colors={item.color} width="full" size={homeKpis}>
                  <div className="flex items-center justify-start gap-x-2">
                    {item.icon}
                    <p className="text-xs font-semibold capitalize font-aileron">
                      {item.text}
                    </p>
                  </div>
                </CustomTags>
                <div className="text-base text-black font-poppins">
                  {item.total}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex mt-3 gap-x-3">
        {mapMarkerHeaders.map((item) => (
          <Button
            key={item.id}
            width="dynamic"
            size="maps"
            padding="mapMarkers"
            colors={filterMarkers.includes(item.id) ? "primary" : "white"}
            modifier="shadow"
            onClick={() => handleFilters(item.id)}
          >
            <div className="flex gap-x-2">
              <div>{item.title}</div>
              {filterMarkers.includes(item.id) ? (
                <CloseIcon className="w-4 h-4 stroke-white" />
              ) : null}
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default MapHeader;
