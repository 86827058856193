/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-properties */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import * as ReactDOMServer from "react-dom/server";
import L from "leaflet";
import getCenterOfPolygon from "../../../Utils/getCenterOfPolygon";
import { ExpandButton } from "../../../Core/Components/MapMarkerButtons";
import { TripDetailsDrawer } from "../../../Core/Components/CustomMapMarkerDetails";
import getTripPointsOnMap from "../../../Utils/getTripPointsOnMap";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import CustomMapMarker from "../../../Core/Components/CustomMapMarker";
import { TRIP_STATUS } from "../Data/TripConstants";
import TrikeTrackerSwitch from "../../../Core/Components/TrikeTrackerSwitch";
import TrackerTags from "../../Trikes/phase3/TrackerTags";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const renderMarkerIcon = (icon, topIcon, colors) => {
  // eslint-disable-next-line new-cap
  return new L.divIcon({
    html: ReactDOMServer.renderToString(
      <CustomMapMarker
        position="map"
        icon={icon}
        topIcon={topIcon}
        colors={colors}
      />,
    ),
  });
};

const TripDetailsMapSection = () => {
  const dispatch = useDispatch();

  const {
    fullScreenMapForTrips,
    currentTrip,
    fastestRouteCoordinates,
    loading: tripLoading,
  } = useSelector((state) => state.trips);

  const { currentTrike, loading: trikeLoading } = useSelector(
    (state) => state.trikes,
  );

  // previous use - allLocations
  const { globalLocations } = useSelector((state) => state.locations);

  // check if tracker permission exists
  const hasTrackerPermission = usePermission([PERMISSIONS.TRACKER_SWITCH]);

  const mapRef = useRef(null);

  const tempCoordinates = [-0.6803741, 34.7569482];

  const initialZoomLevel = 10;

  const lineOptions = {
    color: "#135bba",
  };

  const localCurrentTrip =
    currentTrip?.stopsId[0] ===
    currentTrip?.stopsId[currentTrip?.stopsId?.length - 1]
      ? currentTrip?.stops?.slice(0, -1)
      : currentTrip?.stops;

  const stopCoordinates = localCurrentTrip?.map((stop) => {
    const currentLocation = globalLocations?.find(
      (location) => location.name === stop.location,
    );

    if (currentLocation) {
      return [+currentLocation.lat, +currentLocation.long];
    }

    return [0, 0];
  });

  const getTrikeAssetLatLngs = () => {
    console.log(currentTrike, "check current trike");

    if (currentTrike?.locationLat != 0 && currentTrike?.locationLong != 0) {
      const lat = parseFloat(currentTrike?.locationLat);
      const long = parseFloat(currentTrike?.locationLong);

      return [lat, long];
    }

    return [-0.6803741, 34.7569482];
  };

  useEffect(() => {
    if (stopCoordinates.length > 1) {
      mapRef?.current?.setView(
        getCenterOfPolygon(stopCoordinates),
        initialZoomLevel,
      );
    }
  }, []);

  useEffect(() => {
    if (!trikeLoading && !tripLoading) {
      getTrikeAssetLatLngs();
      if (currentTrip.statusId === TRIP_STATUS.ONGOING) {
        mapRef?.current?.setView(getTrikeAssetLatLngs());
      }
    }
  }, [currentTrike, trikeLoading, tripLoading]);

  return (
    <div className="h-[calc(100vh-128px)]">
      <MapContainer
        center={
          stopCoordinates.length > 1
            ? getCenterOfPolygon(stopCoordinates)
            : tempCoordinates
        }
        // center={tempCoordinates}
        zoom={
          currentTrip.statusId === TRIP_STATUS.ONGOING ? 14 : initialZoomLevel
        }
        className="z-10 w-full h-full polyline"
        zoomControl={false}
        ref={mapRef}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <>
          {fastestRouteCoordinates?.length > 0 ? (
            <Polyline
              pathOptions={lineOptions}
              positions={fastestRouteCoordinates}
              weight={3}
            />
          ) : (
            <Polyline
              pathOptions={lineOptions}
              positions={stopCoordinates}
              weight={3}
            />
          )}
          {stopCoordinates?.map((item, index) => (
            <Marker
              key={index}
              position={item}
              icon={getTripPointsOnMap(
                index + 1,
                currentTrip?.stops?.length,
                currentTrip?.statusId,
                currentTrip,
              )}
            />
          ))}

          {/* show trike live location if the trip is ongoing */}

          {currentTrip.statusId === TRIP_STATUS.ONGOING && !trikeLoading && (
            <Marker
              position={getTrikeAssetLatLngs()}
              icon={renderMarkerIcon("trike.svg")}
            />
          )}
        </>
      </MapContainer>
      <div className="absolute z-20 w-full left-6 top-20">
        <div className="flex items-center justify-between">
          {/* show tracker information (switch or tag) only if permission exists */}
          {hasTrackerPermission ? (
            <div>
              {currentTrip.statusId === TRIP_STATUS.ONGOING ? (
                <TrikeTrackerSwitch
                  trikeId={currentTrike.id}
                  trikeRegistrationNo={currentTrike.registrationNo}
                  locationFetchSource={currentTrike.locationFetchSource}
                />
              ) : currentTrip.statusId === TRIP_STATUS.COMPLETED ? (
                <TrackerTags locationSource={currentTrip.locationSource} />
              ) : (
                <div />
              )}
            </div>
          ) : (
            <div />
          )}

          <div className="flex mr-14 gap-x-4">
            <ExpandButton
              onClick={() =>
                dispatch(
                  updateTrips({
                    key: "fullScreenMapForTrips",
                    value: {
                      ...fullScreenMapForTrips,
                      visible: true,
                      polygonCoordinates: stopCoordinates,
                      pointColor: currentTrip?.statusId,
                    },
                  }),
                )
              }
            />
          </div>
        </div>
      </div>
      <TripDetailsDrawer
        mapRef={mapRef}
        initialZoomLevel={initialZoomLevel}
        // center={getCenterOfPolygon(stopCoordinates)}
        center={tempCoordinates}
      />
    </div>
  );
};

export default TripDetailsMapSection;
