import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import PlusIcon from "../../../Common/Svgs/PlusIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import FilterItem from "../../../Core/Components/FilterItem";

const UserTableHeader = ({
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
}) => {
  const navigate = useNavigate();

  const { allHubs } = useSelector((state) => state.locations);

  const { systemRoles } = useSelector((state) => state.loggedInUser);

  const hasPermission = usePermission([
    PERMISSIONS.SETTINGS_SYSTEM_USER_CREATE,
  ]);

  // get all the hubs
  const hubFilters = allHubs.map((hub) => ({
    id: hub?.id,
    value: hub?.name,
  }));

  // get all the roles (count more than 0 and exclude the driver role)
  const roleFilters = systemRoles
    .filter((item) => item?.count > 0 && item?.name?.toLowerCase() !== "driver")
    .map((role) => ({
      id: role?.id,
      value: role?.name,
    }));

  const [focus, setFocus] = useState(false);

  const onSelect = (key, value) => {
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  return (
    <div
      className="w-full bg-background-white px-6 py-2.5 flex rounded-t-md border-[#f0f0f0] justify-between items-center"
      style={{
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
      }}
    >
      <div className="flex items-center gap-x-2">
        <CustomInput
          intent="search"
          prefix={
            <img src="/assets/icons/search.svg" alt="" className="mr-2" />
          }
          value={search}
          focus={focus}
          border={focus > 0 ? "success" : "none"}
          placeholder={focus ? null : "Search Users"}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          suffix={
            search.length > 0 ? (
              <CloseIcon
                className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                onClick={() => {
                  setSearch("");
                  setFocus(false);
                }}
              />
            ) : null
          }
        />

        <FilterItem
          label="Hub Station"
          identifier="hub"
          contentData={hubFilters}
          onSelect={onSelect}
          selectedValues={filters.hub}
          className="max-h-[200px] overflow-y-scroll"
          enableSearch
        />

        <FilterItem
          label="Role"
          identifier="role"
          contentData={roleFilters}
          onSelect={onSelect}
          selectedValues={filters.role}
          className="max-h-[200px] overflow-y-scroll"
        />
      </div>

      {hasPermission && (
        <Button
          width="dynamic"
          padding="filter"
          onClick={() => navigate("/settings/system-users/create-user")}
        >
          <div className="flex items-center gap-x-2">
            <PlusIcon className="w-4 h-4 stroke-white" />
            <p>Create user</p>
          </div>
        </Button>
      )}
    </div>
  );
};

export default UserTableHeader;
