import React from "react";
import getPaymentStatus from "../getPaymentStatus";
import getUserType from "../getUserType";
import PaymentStatus from "./PaymentStatus";
import TripsDue, { sortByTripsDue } from "./TripsDue";
import TotalAmountDue, { sortByTotalAmountDue } from "./TotalAmountDue";

export const paymentColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Name
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black capitalize">
        {text} {record?.lastName}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Main Hub
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a.hubName.localeCompare(b.hubName),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        User Type
      </div>
    ),
    dataIndex: "userType",
    sorter: (a, b) => a.userType - b.userType,
    render: (text) => <div className="max-w-max">{getUserType(text)}</div>,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        No. of Trips Due
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => sortByTripsDue(a, b),
    render: (text, record) => <TripsDue payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Amount Due
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => sortByTotalAmountDue(a, b),
    render: (text, record) => <TotalAmountDue payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Payment Status
      </div>
    ),
    dataIndex: "paymentStatus",
    render: (text, record) => <PaymentStatus payment={record} />,
  },
];

export const PaymentKPIData = (kpidata) => {
  return [
    {
      title: "Total Amount Paid",
      count: kpidata?.total,
      redirect: false,
    },
    {
      title: "Amount Due To Drivers",
      count: kpidata?.drivers,
      redirect: false,
    },
    {
      title: "Amount Due to Partners",
      count: kpidata?.farmers,
      redirect: false,
    },
  ];
};

export const userTypeFilterOptions = [
  {
    id: 1,
    value: "Driver",
    className:
      "py-1 px-2 bg-[#F5F3FF] text-[#775DFA] font-poppins text-xs font-medium rounded-md",
  },
  {
    id: 2,
    value: "Farmer",
    className:
      "py-1 px-2 bg-[#FEFAED] text-[#EAB726] font-poppins text-xs font-medium rounded-md",
  },
];

export const paymentStatusFilterOptions = [
  {
    id: 1,
    value: getPaymentStatus(1),
  },
  {
    id: 2,
    value: getPaymentStatus(2),
  },
  {
    id: 3,
    value: getPaymentStatus(3),
  },
];
