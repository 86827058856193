/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import {
  BATTERY_REQUEST_TRANSFER_TYPE,
  BATTERY_REQUEST_TYPE,
  BATTERY_STATUS,
  batteryRequestTypeOptions,
  batteryTransferForTripOptions,
} from "../Data";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { updateBatteries } from "../../../Redux/Slices/batteries.slice";
import { client } from "../../../Utils/axiosClient";
import { TRIP_STATUS } from "../../Trips/Data/TripConstants";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import AlertIcon from "../../../Common/Svgs/AlertIcon";

const SelectSwapBatteries = ({
  onSelect,
  hasError,
  tripBatteryIds,
  selectedBatteryIds,
}) => {
  const [open, setOpen] = useState(false);

  const hasValues = selectedBatteryIds.length > 0;

  const getValueContainerClassName = () => {
    if (hasError) return "border-alert-red";
    if (open) return "border-primary";
    return "border-light-grey";
  };

  const getLabelText = () => {
    if (!selectedBatteryIds.length) return "Select batteries for swap";

    if (selectedBatteryIds.length === 1) return "1 battery selected";

    return `${selectedBatteryIds.length} batteries selected`;
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div>
          {tripBatteryIds.length ? (
            <div
              className="bg-background-white py-1 px-3 rounded-md
              max-h-[500px] overflow-y-scroll"
              style={{ width: "367px" }}
            >
              {tripBatteryIds.map((item) => {
                const selected = selectedBatteryIds.includes(item.id);
                return (
                  <button
                    key={item.id}
                    type="button"
                    className="py-2 flex items-center gap-x-3 w-full"
                    onClick={() => onSelect(item.id)}
                  >
                    <Checkbox checked={selected} />

                    <p
                      className={`font-aileron font-semibold text-xs capitalize ${
                        selected ? "text-black" : "text-dark-gray"
                      }`}
                    >
                      {item.value}
                    </p>
                  </button>
                );
              })}
            </div>
          ) : null}
        </div>
      }
    >
      <button
        type="button"
        className={`px-3.5 py-2 w-[367px] bg-white flex items-center justify-between gap-x-3 rounded-md border ${getValueContainerClassName()}`}
      >
        <div>
          <p
            className={`font-aileron font-normal text-xs ${
              hasValues ? "text-black" : "text-dark-grey-text"
            }`}
          >
            {getLabelText()}
          </p>
        </div>

        {hasError ? (
          <AlertIcon className="text-alert-red" />
        ) : (
          <ChevronIcon
            className={`w-5 h-5 stroke-dark-gray
           ${open ? "-rotate-90" : "rotate-90"}`}
            strokeWidth="1"
          />
        )}
      </button>
    </Popover>
  );
};

const RequestBatteries = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allHubs, allLocations } = useSelector((state) => state.locations);

  const { allBatteries } = useSelector((state) => state.batteries);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const recevierHubStations = allLocations
    .filter((item) => userDetails.hubs.includes(+item.id))
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    clearErrors,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      receiverHubId: null,
      receiverHubName: "",
      supplierHubId: null,
      supplierHubName: "",
      requestTypeId: null,
      requestType: "",
      tripTransferId: null,
      tripTransfer: "",
      tripId: null,
      batteryCount: null,
    },
  });

  const [inputFocus, setInputFocus] = useState(false);
  const [tripIds, setTripIds] = useState([]);
  const [tripBatteryIds, setTripBatteryIds] = useState([]);
  const [selectedBatteryIds, setSelectedBatteryIds] = useState([]);
  const [selectedBatteryError, setSelectedBatteryError] = useState("");

  const receiverHubNameError = formState?.errors?.receiverHubName?.message;
  const supplierHubNameError = formState?.errors?.supplierHubName?.message;
  const requestTypeError = formState?.errors?.requestType?.message;
  const tripTransferError = formState?.errors?.tripTransfer?.message;
  const tripIdError = formState?.errors?.tripId?.message;

  // watch the trip id
  const localTripId = watch("tripId");

  const rules = {
    required: "Required.",
  };

  const supplierHubStations = useCallback(() => {
    const hubId = watch("receiverHubId");

    if (hubId) {
      return allHubs
        .filter((item) => hubId !== +item.hubId)
        .map((location) => ({
          id: location.id,
          value: location.name,
        }));
    }
    return allHubs.map((location) => ({
      id: location.id,
      value: location.name,
    }));
  }, [allHubs, watch]);

  const getTripIds = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/trip/get-trip-ids`,
        {
          params: {
            statusId: [TRIP_STATUS.ONGOING],
          },
        },
      );

      const trips = response?.data?.trips.map((item) => ({
        id: item,
        value: `${item}`,
      }));

      setTripIds([...trips]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getTripBatteries = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/trip/get-trip-batteries`,
        {
          params: {
            tripId: localTripId,
          },
        },
      );

      const tripBatteries = response?.data?.result.map((item) => ({
        id: item.id,
        value: item.registrationNo,
      }));

      setTripBatteryIds([...tripBatteries]);
    } catch (error) {
      console.log(error);
    }
  }, [localTripId]);

  const getBatteryCount = () => {
    let batteryCount = 0;
    const hubId = watch("supplierHubId");

    if (hubId) {
      batteryCount = allBatteries.filter(
        (item) =>
          +item.currentHubId === hubId &&
          item.status === BATTERY_STATUS.HUB_STATION,
      ).length;
    }

    return batteryCount;
  };

  const onTripBatterySelect = (batteryId) => {
    setSelectedBatteryError("");

    setSelectedBatteryIds((prev) => {
      if (prev.includes(batteryId)) {
        return prev.filter((item) => item !== batteryId);
      }
      return [...prev, batteryId];
    });
  };

  const onCancel = () => {
    navigate("/batteries");
  };

  const onSubmit = async (e) => {
    // error if no batteries are selected for the swap
    if (
      e.tripTransferId === BATTERY_REQUEST_TRANSFER_TYPE.SWAP &&
      selectedBatteryIds.length === 0
    ) {
      setSelectedBatteryError("Please select the batteries for swap.");
      return;
    }

    // error if requested count is greater than the batteries available at the supplier hub station
    if (e.batteryCount > getBatteryCount()) {
      setError("batteryCount", {
        type: "server",
        message: "Not enough batteries available",
      });

      return;
    }

    const payload = e;

    if (e.tripTransferId === BATTERY_REQUEST_TRANSFER_TYPE.SWAP) {
      payload.swapBatteryIds = selectedBatteryIds;
    }

    try {
      dispatch(updateBatteries({ key: "loading", value: true }));

      await client.post(
        `${process.env.REACT_APP_API_URL}/battery/request-batteries`,
        payload,
      );

      dispatch(
        updateBatteries({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "Request Sent!",
              description: `Your request of ${e.batteryCount} batteries was sent successfully.`,
            },
          },
        }),
      );

      navigate("/batteries");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateBatteries({ key: "loading", value: false }));
    }
  };

  // get trip ids
  useEffect(() => {
    getTripIds();
  }, [getTripIds]);

  // get trip batteries only when trip id is selected
  useEffect(() => {
    if (watch("tripId")) getTripBatteries();
  }, [watch, getTripBatteries]);

  return (
    <CustomLayout pageName="Batteries" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            Request Batteries
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              size="filter"
              width="generateUser"
              colors="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Send Request
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-[calc(100vh-72px-89px)] p-6 bg-background-white overflow-y-scroll">
        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Receiver Hub Station
          </p>

          <Controller
            name="receiverHubName"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={recevierHubStations}
                onSelect={(data) => {
                  setValue("receiverHubName", data.value);
                  setValue("receiverHubId", data.id);
                  setValue("supplierHubName", "");
                  setValue("supplierHubId", null);
                  clearErrors("receiverHubName");
                }}
              />
            )}
          />

          {receiverHubNameError && (
            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                receiverHubNameError
                  ? "text-alert-red visible mt-2"
                  : "invisible"
              }`}
            >
              {receiverHubNameError}
            </p>
          )}
        </div>

        <div className="w-[367px] h-[1px] my-6 bg-light-grey" />

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Supplier Hub Station
          </p>

          <Controller
            name="supplierHubName"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={supplierHubStations()}
                onSelect={(data) => {
                  setValue("supplierHubName", data.value);
                  setValue("supplierHubId", data.id);
                  clearErrors("supplierHubName");
                }}
              />
            )}
          />

          {supplierHubNameError ? (
            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                supplierHubNameError
                  ? "text-alert-red visible mt-2"
                  : "invisible"
              }`}
            >
              {supplierHubNameError}
            </p>
          ) : (
            <div className="mt-3 flex items-center justify-between w-[367px] pr-1 font-poppins text-xs font-medium">
              <p>Available Batteries</p>
              <p>{getBatteryCount()}</p>
            </div>
          )}
        </div>

        <div className="mt-6">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Request Type
          </p>

          <Controller
            name="requestType"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                placeholder="Select request type"
                formState={formState}
                options={batteryRequestTypeOptions}
                onSelect={(data) => {
                  setValue("requestType", data.value);
                  setValue("requestTypeId", data.id);

                  if (data.id === BATTERY_REQUEST_TYPE.HUB) {
                    setValue(
                      "tripTransferId",
                      BATTERY_REQUEST_TRANSFER_TYPE.TRANSFER,
                    );
                  }
                  clearErrors("requestType");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              requestTypeError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {requestTypeError}
          </p>
        </div>

        {watch("requestTypeId") === BATTERY_REQUEST_TYPE.TRIP && (
          <div>
            <div className="mt-6">
              <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
                Transfer Type
              </p>

              <Controller
                name="tripTransfer"
                control={control}
                rules={rules}
                render={({ field }) => (
                  <PopoverDropdown
                    {...field}
                    placeholder="Select transfer type"
                    formState={formState}
                    options={batteryTransferForTripOptions}
                    onSelect={(data) => {
                      setValue("tripTransfer", data.value);
                      setValue("tripTransferId", data.id);
                      clearErrors("tripTransfer");
                    }}
                  />
                )}
              />

              <p
                className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                  tripTransferError
                    ? "text-alert-red visible mt-2"
                    : "invisible"
                }`}
              >
                {tripTransferError}
              </p>
            </div>

            <div className="mt-6">
              <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
                Trip ID
              </p>

              <Controller
                name="tripId"
                control={control}
                rules={rules}
                render={({ field }) => (
                  <PopoverDropdown
                    {...field}
                    enableSearch
                    className="max-h-[200px] overflow-y-scroll"
                    placeholder="Select Trip ID"
                    searchPlaceholder="Search Trip ID"
                    formState={formState}
                    options={tripIds}
                    onSelect={(data) => {
                      setValue("tripId", data.id);
                      clearErrors("tripId");
                    }}
                  />
                )}
              />

              <p
                className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                  tripIdError ? "text-alert-red visible mt-2" : "invisible"
                }`}
              >
                {tripIdError}
              </p>
            </div>
          </div>
        )}

        {watch("tripTransferId") === BATTERY_REQUEST_TRANSFER_TYPE.SWAP && (
          <div className="mt-6">
            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
              Batteries to swap
            </p>

            <SelectSwapBatteries
              onSelect={onTripBatterySelect}
              hasError={selectedBatteryError}
              tripBatteryIds={tripBatteryIds}
              selectedBatteryIds={selectedBatteryIds}
            />

            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                selectedBatteryError
                  ? "text-alert-red visible mt-2"
                  : "invisible"
              }`}
            >
              {selectedBatteryError}
            </p>
          </div>
        )}

        <div className="mt-6">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            How many batteries do you require?
          </p>

          <CustomInputWithController
            intent="createUser"
            control={control}
            formState={formState}
            focus={inputFocus}
            setFocus={setInputFocus}
            name="batteryCount"
            placeholder="Enter Number"
            rules={{
              ...rules,
              validate: {
                isNumber: (value) => {
                  return value > 0 || "Please enter valid number";
                },
              },
            }}
          />
        </div>
      </div>
    </CustomLayout>
  );
};

export default RequestBatteries;
